import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  createRef,
  useContext,
  useRef,
} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import "../stylesheets/FreelancerNT.css";
import toast, { Toaster } from "react-hot-toast";
import { light, dark } from "../ui/Toggletheme";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import Notification from "../components/Notification";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Checkbox from "@mui/material/Checkbox";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Header from "../components/header";
import Compressor from "compressorjs";

var w = window.innerWidth;
var h = window.innerHeight;
let progressInterval = null;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 50px",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF88",
    padding: 8,
    borderRadius: 8,
  },
  inputForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  imagePicker: {
    display: "flex",
    flexDirection: "column",
    height: "50%",
  },
  pdfPicker: {
    marginTop: 10,
    zIndex: 10,
  },
  selection: {
    width: "100%",
  },
  select: {
    color: "#000000",
  },
  submit: {
    padding: "10px 50px",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: "1.7rem",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  addColor: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    alignItems: "center",
    transition: "all .2s ease-in-out",
    color: "#000000AA",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.005)",
      color: "#000000",
    },
  },
  colorAddModal: {
    position: "absolute",
    width: "72vw",
    height: "70vh",
    top: "15vh",
    right: "15vw",
    backgroundColor: "red",
    zIndex: 1000,
    borderRadius: 8,
    padding: 18,
    backgroundColor: "#75B55188",
    backdropFilter: "blur(5px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  colorRow: {
    margin: 4,
    display: "flex",
    padding: 4,
    flexDirection: "row",
    borderRadius: 4,
    transition: "all .2s ease-in-out",
    backgroundColor: "#FFFFFFAA",
    color: "#75B551",
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.04)",
    },
  },
  progressBar: {
    paddingRight: "10px",
    textAlign: "right",
  },
  progress: {
    background: "#ffffff88",
  },
}));

const OutlinedInputL = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#00000088 !important",
  },
}))(MuiOutlinedInput);
const OutlinedInputD = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#49b675AA !important",
  },
}))(MuiOutlinedInput);

const CustomTextFieldL = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000088",
      },
      "&:hover fieldset": {
        borderColor: "#00000088",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000088",
      },
    },
  },
})(TextField);

const CustomTextFieldXD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#FFFFFF",
    },
    "& label": {
      color: "#FFFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FFFFFF",
      },
      "&:hover fieldset": {
        borderColor: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFFFFF",
      },
    },
  },
})(TextField);

const CustomTextFieldD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#49b675AA",
    },
    "& label": {
      color: "#49b675AA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#49b675AA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#49b675AA",
      },
      "&:hover fieldset": {
        borderColor: "#49b675AA",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#49b675AA",
      },
    },
  },
})(TextField);

const WhiteCheckbox = withStyles({
  root: {
    color: "#FFFFFFAA",
    width: 10,
    height: 10,
    "&$checked": {
      color: "#FFFFFF",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const ThemeContext = createContext(null);
export default function AddDesignFLNT(props) {
  // const { inView } = useContext(IntersectionContext);

  // const percentsOffset = (percents - 100) * (300 / 100);
  const classes = useStyles();
  const history = useHistory();

  const {
    loginStatus,
    setLoginStatus,
    userID,
    userRole,
    userName,
    theme,
    setTheme,
    headerData,
    setHeaderData,
    isHeader,
    setIsHeader,
    cookies,
    removeCookie,
  } = props;
  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };
  const [thumbnail, setThumbnail] = useState([]);
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [type, setType] = React.useState("");
  const [types, setTypes] = React.useState(["A", "B", "C", "D"]);
  const [age, setAge] = React.useState("");
  const [ages, setAges] = React.useState(["10", "20", "30", "40"]);
  const [productType, setProductType] = React.useState("");
  const [productTypes, setProductTypes] = React.useState([
    "InnerWear",
    "T-Shirt",
    "Jeans",
    "Shirt",
  ]);
  const [gender, setGender] = React.useState("");
  const [genders, setGenders] = React.useState([
    "Male",
    "Female",
    "Boys",
    "Girls",
  ]);
  const [category, setCategory] = React.useState("");
  const [categories, setCategories] = React.useState([
    "InnerWear",
    "T-Shirt",
    "Jeans",
    "Shirt",
  ]);
  const [fit, setFit] = React.useState("");
  const [fits, setFits] = React.useState(["Slim", "Medium", "Skinny"]);
  const [fabrics, setFabrics] = React.useState([]);
  const [fabric, setFabric] = React.useState("");

  const [colors, setColors] = React.useState([]);
  const [colorsFilt, setColorsFilt] = React.useState([]);
  const [selectedColors, setSelectedColors] = React.useState([]);
  const [scroll, setScroll] = React.useState(false);
  const [showColorModal, setShowColorModal] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [designName, setDesignName] = React.useState("");
  const [genericName, setGenericName] = React.useState("");
  const [designCode, setDesignCode] = React.useState("");
  const [designDescription, setDesignDescription] = React.useState("");
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const bottomRefColors = useRef(null);

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const months = [
    "Jan",
    "Febr",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      // console.log(`${loaded}kb of ${total} kb | ${percent}%`);
      if (percent < 100) {
        setUploadPercentage(percent);
      }
    },
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      new Compressor(acceptedFiles[0], {
        quality: 500000 / acceptedFiles[0].size,
        convertSize: 1000,
        success(result) {
          let fileName = `${result.name}`;
          let newfile = new File([result], fileName, { type: "image/jpg" });
          Object.assign(newfile, {
            preview: URL.createObjectURL(newfile),
          });
          setThumbnail([newfile]);
        },
        error(err) {
          console.log(err.message);
        },
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const dropzoneRef = createRef();

  const notify = (type, message) => {
    if (type === "submit") {
      toast("Design has been Sent for Review");
    } else if (type == "error") {
      toast.error(message);
    } else if (type == "er") {
      toast.error(message);
    } else if (type == "success") {
      toast.success(message, {
        iconTheme: {
          primary: "#0047AB",
          secondary: "#FFFAEE",
        },
      });
    } else if (type == "custom") {
      toast((t) => (
        <span>
          <b>Thanks for submitting your awesome designs.</b>
          <br />
          <br />
          Our team is on the job of reviewing your designs and will share
          feedback soon. Check back here for updates. You will also receive a
          notification once the review is completed.
        </span>
      ));
    }
  };

  const getDropdownData = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getDesignDropdown", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setGenders(result.gender);
        setFits(result.fitType);
        setAges(result.age);
        setCategories(result.category);
        setProductTypes(result.productType);
        setTypes(result.materialType);
        setFabrics(result.fabricType);
        setColors(result.colorType);
        setColorsFilt(result.colorType);
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const submit = () => {


    var status;
    var formdata1 = new FormData();
    var submitObj = {
      userID: userID,
      designForm: {
        designCode: designCode,
        designName: designName,
        genericName: genericName,
        designDescription: designDescription,
        typeID: type,
        genderID: gender,
        ageID: age,
        categoryID: category,
        fitID: fit,
        prodID: productType,
        fabricID: fabric,
        designID: 1,
      },
    };
    formdata1.append("file_data", files3[0]);
    formdata1.append("comment_detail", JSON.stringify({"BASICPARAMS":{"USER_ID":1076,"CLIENT_CODE":"demo","APP_VERSION":"A_5.11","COMPANY_ID":4,"LOCATION_ID":6407},"COMMENT_DETAILS":{"SO_DT_ID":"22099","COMMENT":"","COMMENT_DATE":"12-OCT-2023, 14:34:42","COMMENTS_BY_TYPE":"C","FILE_URL":"file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540public.expo%252FBKDash/ImagePicker/b8ddf6df-1137-4f8e-b57e-2bca88127bcc.jpeg","FILE_NAME":"b8ddf6df-1137-4f8e-b57e-2bca88127bcc.jpeg","COMMENTS_TYPE":"I"},"USER_DETAILS":[{"SEND_TO_USER_ID":2154,"SEND_TO_USER_NAME":"ABHISHEK"}]}));
    selectedColors.forEach((item, index) => {
      let string = "colorID/" + item.id + "/";
      if (item.isPrimary) {
        string = string + 1;
      } else {
        string = string + 0;
      }

      if (selectedColors[index].image != null) {
        formdata1.append(string, selectedColors[index].image);
      } else {
        formdata1.append(string, "[object Object]");
      }
    });

    const fetchData = async () => {
      const response = await axios
        .post(
          "http://bkmfgapi.bluekaktus.com/Service1.svc//UploadOrderFiles",
          formdata1
        )
        .then((response) => {
          status = response.status;
          console.log(status, "status");
          console.log(response, "res");

          if (status == 200) {
            notify("success", "Design Sent For Review");
            notify("sucess", "Data Saved");
            notify("custom", "submit");
            setDesignDescription("");
            setDesignName("");
            setAge("");
            setCategory("");
            setFit("");
            setType("");
            setGender("");
            setProductType("");
            setDesignCode("");
            setGenericName("");
            setFiles([]);
            setFiles2([]);
            setFiles3([]);
            setFabric("");
            setIsButtonLoading(false);
            setUploadPercentage(0);
            const myTimeout = setTimeout(() => {
              history.push("/home");
            }, 3000);
            // toastFunction(true)
          }
        })

        .catch((err) => {
          setIsButtonLoading(false);
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.Message
          ) {
            notify("error", err.response.data.Message);
          } else {
            notify("error", "Invalid Details");
          }
          return Promise.reject(err);
        });
    };
    const callFunction = fetchData();
    toast.promise(callFunction, {
      position: "top-center",
      loading: "Saving...",
      error: "Data not saved",
      success: "Data Saved",
    });
  };

  useEffect(()=>{
    if(scroll){
      const lastChildElement = bottomRefColors.current?.lastElementChild;
      lastChildElement?.scrollIntoView({ behavior: 'smooth' });
      setScroll(false);
    }
  },[scroll]);

  useEffect(() => {
    if (loginStatus == 1) {
      getDropdownData();
    }
  }, []);

  const baseStyle2 = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderRadius: 2,
    color: "#FFFFFF",
    height: "100%",
    fontSize: "10rem",
    transition: "border .24s ease-in-out",
  };

  const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme == "light" ? "#00000088" : "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: theme == "light" ? "#FFFFFFAA" : "#9BDFE2",
    color: theme == "light" ? "#75B551" : "#00000088",
    height: "100%",
    fontSize: "1.2rem",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: theme == "light" ? "#00000088" : "49b675AA",
  };

  const acceptStyle = {
    borderColor: theme == "light" ? "#00000088" : "49b675AA",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isFocused, isDragAccept]
  );

  const style2 = useMemo(
    () => ({
      ...baseStyle2,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isFocused, isDragAccept]
  );

  const filterColors = (filter) => {
    filter = filter.toLowerCase();

    if (!filter || filter == "") {
      setColorsFilt(colors);
      setSearchTerm("");
    } else {
      const newData = colors.filter((item) => {
        let itemString = item["name"];
        return itemString.toLowerCase().includes(filter);
      });
      setColorsFilt(newData);
    }
  };

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={theme == "light" ? "bodyNT" : "body"}>
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
          <Toaster
            position="bottom-center"
            toastOptions={{
              // Define default options
              className: "",

              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
              success: {
                duration: 4000,
                style: {
                  color: " #FFFFFF",
                  background: "#75B551DD",
                },
              },
              submit: {
                duration: 4000,
                style: {
                  color: " #FFFFFF",
                  background: "#75B551DD",
                },
              },
              custom: {
                duration: 4000,
                style: {
                  color: " #FFFFFF",
                  background: "#75B551DD",
                },
              },
              error: {
                duration: 2000,
                style: {
                  color: " #FFFFFF",
                  background: "#75B551DD",
                },
              },
            }}
          />
          {isHeader && (
            <Header headerData={headerData} theme={theme} setTheme={setTheme} />
          )}
          <div className={classes.wrapper}>
            <div
              className={classes.inputWrapper}
              style={{
                boxShadow:
                  theme == "light"
                    ? "#75B551 0px 1px 4px"
                    : "#FFFFFFAA 0px 1px 4px",
                zIndex: 10,
              }}
            >
              <div className={classes.inputForm}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                    padding: 8,
                    maxHeight: "75vh",
                  }}
                >
                  <div className={classes.imagePicker}>
                    {files.length == 0 ? (
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>DRAG N DROP IMAGES*</p>
                      </div>
                    ) : (
                      <div
                        {...getRootProps({ style })}
                        className={classes.image}
                      >
                        <img
                          src={files[0].preview}
                          style={{
                            height: "100%",
                            maxWidth: "100%",
                            objectFit: "contain",
                          }}
                          // Revoke data uri after image is loaded
                          onLoad={() => {
                            URL.revokeObjectURL(files[0].preview);
                          }}
                        />
                        <input {...getInputProps()} />
                      </div>
                    )}
                  </div>
                  <div className={classes.pdfPicker}>
                    <Dropzone
                      maxFiles={1}
                      ref={dropzoneRef}
                      onDrop={(file) => {
                        if (
                          file[0].type != "application/pdf" ||
                          file.length > 1
                        ) {
                          notify("error", "Please Upload only 1 PDF file");
                        } else {
                          setFiles2(file);
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps, acceptedFiles }) => {
                        if (files2.length == 0) {
                          return (
                            <div>
                              <div {...getRootProps({ style })}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' Drop Tech Pack</p>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            // <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:13,fontSize:".8rem",textAlign:"center"}}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                  color: "#000000AA",
                                }}
                              >
                                Tech Pack
                              </div>
                              <div
                                {...getRootProps({ style })}
                                style={{
                                  padding: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <input {...getInputProps()} />

                                <PictureAsPdfIcon
                                  fontSize="large"
                                  style={{
                                    color: "#e71837DD",
                                    marginBottom: 5,
                                  }}
                                />
                                <div
                                  style={{
                                    textOverflow: "ellipsis",
                                    fontSize: ".8rem",
                                    textAlign: "center",
                                  }}
                                >
                                  {files2[0].name}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }}
                    </Dropzone>
                  </div>
                  <div className={classes.pdfPicker}>
                    <Dropzone
                      ref={dropzoneRef}
                      style={{ display: "flex", height: "100%" }}
                      onDrop={(files) => {
                        // setFiles3(file)
                        var addedFiles = files3;
                        files.map((file) => {
                          Object.assign(file, {
                            preview: URL.createObjectURL(file),
                          });
                          addedFiles.push(file);
                        });
                        setFiles3(addedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps, acceptedFiles }) => {
                        if (files3.length == 0) {
                          return (
                            <div>
                              <div {...getRootProps({ style })}>
                                <input {...getInputProps()} />
                                <p>Attach Files</p>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            // <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:13,fontSize:".8rem",textAlign:"center"}}>
                            <div
                              {...getRootProps({ style })}
                              style={{
                                padding: 10,
                                paddingTop: 0,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                  color: "#000000AA",
                                  marginBottom: 5,
                                }}
                              >
                                Attachments
                              </div>
                              <input {...getInputProps()} />

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                {files3.map((item, index) => {
                                  var file = files3[index];
                                  console.log(file);
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "18%",
                                        margin: 8,
                                        backgroundColor: "#88888888",
                                        borderRadius: 5,
                                        padding: 3,
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          right: 0,
                                          padding: 5,
                                        }}
                                        onClick={(e) => {
                                          if (!e) var e = window.event;
                                          e.cancelBubble = true;
                                          if (e.stopPropagation)
                                            e.stopPropagation();
                                          var addedFiles = files3;
                                          addedFiles.splice(index, 1);
                                          var temp = [];
                                          addedFiles.forEach((item) => {
                                            var x = new File(
                                              [item],
                                              item.name,
                                              { type: item.type }
                                            );
                                            Object.assign(x, {
                                              preview: URL.createObjectURL(x),
                                            });
                                            temp.push(x);
                                          });
                                          setFiles3(temp);
                                        }}
                                      >
                                        <CancelIcon
                                          fontSize="small"
                                          style={{ color: "#ff681fDD" }}
                                        />
                                      </div>

                                      {file &&
                                      file["name"] &&
                                      file["name"].slice(-3) === "pdf" ? (
                                        <PictureAsPdfIcon
                                          fontSize="large"
                                          style={{
                                            color: "#e71837DD",
                                            marginBottom: 5,
                                          }}
                                        />
                                      ) : (file &&
                                          file["name"] &&
                                          file["name"].slice(-3) === "xls") ||
                                        (file &&
                                          file["name"] &&
                                          file["name"].slice(-4) === "xlsx") ? (
                                        <svg
                                          width="32px"
                                          height="30px"
                                          viewBox="0 0 24 24"
                                          fill="#1D7044"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            marginBottom: 5,
                                          }}
                                        >
                                          <g>
                                            <path
                                              fill="none"
                                              d="M0 0h24v24H0z"
                                            />
                                            <path d="M2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zm-6.8 9L13 8h-2.4L9 10.286 7.4 8H5l2.8 4L5 16h2.4L9 13.714 10.6 16H13l-2.8-4z" />
                                          </g>
                                        </svg>
                                      ) : file &&
                                        file["name"] &&
                                        file["name"].slice(-4) === "pptx" ? (
                                        <svg
                                          width="32px"
                                          height="30px"
                                          fill="#C64120"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            marginBottom: 5,
                                          }}
                                        >
                                          <g>
                                            <path
                                              fill="none"
                                              d="M0 0h24v24H0z"
                                            />
                                            <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zM2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM5 8v8h2v-2h6V8H5zm2 2h4v2H7v-2z" />
                                          </g>
                                        </svg>
                                      ) : (
                                        <img
                                          src={item.preview}
                                          style={{
                                            height: "50%",
                                            maxWidth: "100%",
                                            objectFit: "contain",
                                          }}
                                          // Revoke data uri after image is loaded
                                          onLoad={() => {
                                            URL.revokeObjectURL(item.preview);
                                          }}
                                        />
                                      )}
                                      <div
                                        style={{
                                          textOverflow: "ellipsis",
                                          fontSize: ".8rem",
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                      >
                                        {item.name}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        }
                      }}
                    </Dropzone>
                  </div>
                </div>
                <div
                  style={{
                    padding: 8,
                    display: "flex",
                    flexDirection: "column",
                    width: "66%",
                  }}
                >
                  <div style={{ width: "49%", marginRight: "1%" }}>
                    {theme == "light" ? (
                      <CustomTextFieldL
                        style={{ flex: 1 }}
                        fullWidth
                        label="Design Code"
                        variant="outlined"
                        value={designCode}
                        onChange={(e) => {
                          setDesignCode(e.target.value);
                        }}
                      />
                    ) : (
                      <CustomTextFieldD
                        style={{ flex: 1 }}
                        fullWidth
                        label="Design Code"
                        variant="outlined"
                        value={designCode}
                        onChange={(e) => {
                          setDesignCode(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{ width: "49%", marginRight: "1%", marginTop: 10 }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginBottom: 10 }}
                      >
                        <InputLabel
                          id="label"
                          style={{
                            color: theme == "light" ? "#00000088" : "#49b675AA",
                          }}
                        >
                          Type
                        </InputLabel>
                        <Select
                          style={{
                            color: "red",
                          }}
                          classes={{
                            select: classes.select,
                            // icon: classes.icon,
                          }}
                          label="Type"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          input={
                            theme == "light" ? (
                              <OutlinedInputL label="Type" />
                            ) : (
                              <OutlinedInputD label="Type" />
                            )
                          }
                        >
                          {types.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginBottom: 10 }}
                      >
                        <InputLabel
                          id="label"
                          style={{
                            color: theme == "light" ? "#00000088" : "#49b675AA",
                          }}
                        >
                          Age
                        </InputLabel>
                        <Select
                          classes={{
                            select: classes.select,
                            icon: classes.icon,
                          }}
                          label="Age"
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                          input={
                            theme == "light" ? (
                              <OutlinedInputL label="Age" />
                            ) : (
                              <OutlinedInputD label="Age" />
                            )
                          }
                        >
                          {ages.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginBottom: 10 }}
                      >
                        <InputLabel
                          id="label"
                          style={{
                            color: theme == "light" ? "#00000088" : "#49b675AA",
                          }}
                        >
                          Product Type
                        </InputLabel>
                        <Select
                          classes={{
                            select: classes.select,
                            icon: classes.icon,
                          }}
                          label="Product Type"
                          value={productType}
                          onChange={(e) => setProductType(e.target.value)}
                          input={
                            theme == "light" ? (
                              <OutlinedInputL label="Product Type" />
                            ) : (
                              <OutlinedInputD label="Product Type" />
                            )
                          }
                        >
                          {productTypes.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginBottom: 10 }}
                      >
                        <InputLabel
                          id="label"
                          style={{
                            color: theme == "light" ? "#00000088" : "#49b675AA",
                          }}
                        >
                          Fabric
                        </InputLabel>
                        <Select
                          classes={{
                            select: classes.select,
                            icon: classes.icon,
                          }}
                          label="Fabric"
                          value={fabric}
                          onChange={(e) => setFabric(e.target.value)}
                          input={
                            theme == "light" ? (
                              <OutlinedInputL label="Fabric" />
                            ) : (
                              <OutlinedInputD label="Fabric" />
                            )
                          }
                        >
                          {fabrics.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      style={{ width: "49%", marginRight: "1%", marginTop: 10 }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginBottom: 10 }}
                      >
                        <InputLabel
                          id="label"
                          style={{
                            color: theme == "light" ? "#00000088" : "#49b675AA",
                          }}
                        >
                          Gender
                        </InputLabel>
                        <Select
                          classes={{
                            select: classes.select,
                            icon: classes.icon,
                          }}
                          label="Gender"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          input={
                            theme == "light" ? (
                              <OutlinedInputL label="Gender" />
                            ) : (
                              <OutlinedInputD label="Gender" />
                            )
                          }
                        >
                          {genders.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginBottom: 10 }}
                      >
                        <InputLabel
                          id="label"
                          style={{
                            color: theme == "light" ? "#00000088" : "#49b675AA",
                          }}
                        >
                          Category
                        </InputLabel>
                        <Select
                          classes={{
                            select: classes.select,
                            icon: classes.icon,
                          }}
                          label="Category"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          input={
                            theme == "light" ? (
                              <OutlinedInputL label="Category" />
                            ) : (
                              <OutlinedInputD label="Category" />
                            )
                          }
                        >
                          {categories.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginBottom: 10 }}
                      >
                        <InputLabel
                          id="label"
                          style={{
                            color: theme == "light" ? "#00000088" : "#49b675AA",
                          }}
                        >
                          Fit
                        </InputLabel>
                        <Select
                          classes={{
                            select: classes.select,
                            icon: classes.icon,
                          }}
                          label="Fit"
                          value={fit}
                          onChange={(e) => setFit(e.target.value)}
                          input={
                            theme == "light" ? (
                              <OutlinedInputL label="Fit" />
                            ) : (
                              <OutlinedInputD label="Fit" />
                            )
                          }
                        >
                          {fits.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    className={classes.addColor}
                    onClick={() => {
                      setShowColorModal(true);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: 22,
                        width: 22,
                        borderRadius: 50,
                        backgroundColor: "#75B551",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        padding: 10,
                      }}
                    >
                      <div style={{ height: 20, width: 20 }}>+</div>
                    </div>
                    <div style={{ marginLeft: 10, marginRight: 15 }}>
                      Add Color
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {selectedColors.map((item, index) => {
                        return (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div>{item.name}</div>
                            <div>
                              {index + 1 != selectedColors.length ? "," : ""}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        width: "49%",
                        marginRight: "1%",
                        marginBottom: 10,
                      }}
                    >
                      {theme == "light" ? (
                        <CustomTextFieldL
                          style={{ flex: 1 }}
                          fullWidth
                          label="Design Name"
                          variant="outlined"
                          value={designName}
                          onChange={(e) => {
                            setDesignName(e.target.value);
                          }}
                        />
                      ) : (
                        <CustomTextFieldD
                          style={{ flex: 1 }}
                          fullWidth
                          label="Design Name"
                          variant="outlined"
                          value={designName}
                          onChange={(e) => {
                            setDesignName(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        width: "49%",
                        marginRight: "1%",
                        marginBottom: 10,
                      }}
                    >
                      {theme == "light" ? (
                        <CustomTextFieldL
                          style={{ flex: 1 }}
                          fullWidth
                          label="Generic Name"
                          variant="outlined"
                          value={genericName}
                          onChange={(e) => {
                            setGenericName(e.target.value);
                          }}
                        />
                      ) : (
                        <CustomTextFieldD
                          style={{ flex: 1 }}
                          fullWidth
                          label="Generic Name"
                          variant="outlined"
                          value={genericName}
                          onChange={(e) => {
                            setGenericName(e.target.value);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "99%",
                      marginRight: "1%",
                      marginBottom: 15,
                    }}
                  >
                    {theme == "light" ? (
                      <CustomTextFieldL
                        style={{ flex: 1 }}
                        fullWidth
                        label="Design Description"
                        variant="outlined"
                        value={designDescription}
                        onChange={(e) => {
                          setDesignDescription(e.target.value);
                        }}
                      />
                    ) : (
                      <CustomTextFieldD
                        style={{ flex: 1 }}
                        fullWidth
                        label="Design Description"
                        variant="outlined"
                        value={designDescription}
                        onChange={(e) => {
                          setDesignDescription(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      width: "99%",
                    }}
                  >
                    <div
                      className={classes.submit}
                      style={{
                        backgroundColor:
                          theme == "light" ? "#75B551" : "#49b675DD",
                      }}
                      onClick={() => {
                        submit();
                      }}
                    >
                      {isButtonLoading ? "Loading..." : "Submit"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showColorModal ? (
              <div className={classes.colorAddModal}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      fontSize: "1.8rem",
                      color: "#FFFFFF",
                      marginLeft: 10,
                    }}
                  >
                    Add Color
                  </div>
                  <div
                    style={{
                      fontSize: "2.2rem",
                      color: "#FFFFFF",
                      top: 10,
                      right: 20,
                    }}
                    onClick={() => {
                      setShowColorModal(false);
                    }}
                  >
                    -
                  </div>
                </div>
                <div style={{ marginTop: 8, width: "60%" }}>
                  <CustomTextFieldXD
                    style={{ flex: 1 }}
                    fullWidth
                    size="small"
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => {
                      filterColors(e.target.value);
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    height: "100%",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "sticky",
                      top: 0,
                      overflow: "scroll",
                      marginRight: 10,
                      width: "20%",
                      padding: 8,
                    }}
                  >
                    {colorsFilt.map((item, index) => {
                      return (
                        <div
                          className={classes.colorRow}
                          onClick={() => {
                            let temp = JSON.parse(
                              JSON.stringify(selectedColors)
                            );
                            var flag = 0;
                            temp.forEach((itemx) => {
                              if (itemx.name == item.name) {
                                flag = 1;
                              }
                            });
                            if (flag == 0) {
                              temp.push({
                                name: item.name,
                                id: item.id,
                                isPrimary: temp.length == 0 ? true : false,
                                image: null,
                              });
                              setScroll(true);
                            }
                            setSelectedColors(temp);
                          }}
                        >
                          <div>{item.name}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                  ref={bottomRefColors}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                      flexWrap: "wrap",
                      width: "80%",
                    }}
                  >
                    {selectedColors.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                            textAlign: "center",
                            border: "2px solid #FFFFFF",
                            borderRadius: "7px",
                            height: "28vh",
                            padding: 10,
                            width: "15vw",
                            marginRight: 8,
                            marginBottom: 8,
                            alignItems: "",
                            color: "#FFFFFF",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>{item.name}</div>
                            <div
                              style={{ fontSize: 20 }}
                              onClick={() => {
                                let temp = JSON.parse(
                                  JSON.stringify(selectedColors)
                                );
                                temp.splice(index, 1);
                                if (temp.length > 0) {
                                  let flag = false;
                                  temp.map((item2, index2) => {
                                    if (item2.isPrimary) {
                                      flag = true;
                                    }
                                  });
                                  if (!flag) {
                                    temp[0].isPrimary = true;
                                  }
                                }
                                setSelectedColors(temp);
                              }}
                            >
                              x
                            </div>
                          </div>

                          <Dropzone
                            maxFiles={1}
                            ref={dropzoneRef}
                            onDrop={(file) => {
                              if (
                                file[0].type.slice(0, 5) != "image" ||
                                file.length > 1
                              ) {
                                notify("error", "Please Upload only 1 Image ");
                              } else {
                                console.log(file);
                                Object.assign(file[0], {
                                  preview: URL.createObjectURL(file[0]),
                                });
                                selectedColors[index].image = file[0];
                              }
                            }}
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              acceptedFiles,
                            }) => {
                              if (item.image == null) {
                                return (
                                  <div {...getRootProps({ style2 })}>
                                    <input {...getInputProps()} />
                                    <p
                                      style={{
                                        fontSize: 40,
                                        padding: "20px 80px 20px 80px",
                                      }}
                                    >
                                      +
                                    </p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    {...getRootProps({ style2 })}
                                    className={classes.image}
                                  >
                                    <img
                                      src={item.image.preview}
                                      style={{
                                        width: "100%",
                                        maxHeight: "20vh",
                                        objectFit: "contain",
                                      }}
                                    />
                                    <input {...getInputProps()} />
                                  </div>
                                );
                              }
                            }}
                          </Dropzone>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              if (selectedColors.length > 1) {
                                let temp = JSON.parse(
                                  JSON.stringify(selectedColors)
                                );
                                temp.forEach((item) => {
                                  item.isPrimary = false;
                                });
                                temp[index].isPrimary = true;
                                setSelectedColors(temp);
                              }
                            }}
                          >
                            <WhiteCheckbox checked={item.isPrimary} />
                            <div style={{ marginLeft: 10 }}> Primary Color</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </ThemeContext.Provider>
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          style={{ position: "absolute" }}
        />
      </div>
    );
  }
}
