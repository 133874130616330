import React, { useState, useEffect } from "react";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Header from "../components/header";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Pagination } from "@material-ui/lab";
import usePagination from "../hooks/Pagination";
import Notification from "../components/Notification";
import { TextField } from "@material-ui/core";
import { TableSortLabel } from "@mui/material";

var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 50px",
  },
  tableI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    border: "1.5px solid #00000033",
    maxHeight: "68vh",
    marginTop: 5,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    padding: 10,
    borderBottom: "1.5px solid #00000055",
    position: "sticky",
    top: 0,
  },
  headerRowItem: {
    color: "#75B551",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginRight: 15,
  },
  tableRowEven: {
    display: "flex",
    flexDirection: "row",
    padding: 8,
    backgroundColor: "#BBBBBB66",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  tableRowOdd: {
    display: "flex",
    flexDirection: "row",
    padding: 8,
    backgroundColor: "#EEEEEEAA",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF88",
    },
  },
  tableCell: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.8vmin",
    color: "#000000AA",
    marginRight: 15,
  },
  approveBtn: {
    padding: "6px 30px",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: "1rem",
    marginLeft: 10,
    background: "linear-gradient(-45deg,#75B551,#86c662,#96d374,#a2d982)",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  tableWrapperRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "2.5px 5px",
    width: "100%",
    justifyContent: "center",
    marginTop: 10,
    // border:"2px solid blue",
  },
}));

const CustomTextFieldL = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000088",
      },
      "&:hover fieldset": {
        borderColor: "#00000088",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000088",
      },
    },
  },
})(TextField);
const StyledTableSortLabel = withStyles((theme) =>
  createStyles({
    active: {},
    icon: {
      color: "#75B551 !important",
    },
  })
)(TableSortLabel);
const CustomTextFieldD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#49b675AA",
    },
    "& label": {
      color: "#49b675AA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#49b675AA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#49b675AA",
      },
      "&:hover fieldset": {
        borderColor: "#49b675AA",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#49b675AA",
      },
    },
  },
})(TextField);

export default function NewUsers(props) {
  const {
    setSelectedUser,
    loginStatus,
    setLoginStatus,
    userID,
    headerData,
    isHeader,
    theme,
    setTheme,
    cookies,
    removeCookie,
    setCartItems,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueByOrder, setValueByOrder] = useState("");
  let [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const PER_PAGE = 15;
  const _DATA = usePagination(filteredData, PER_PAGE);

  const handleSearchData = (text) => {
    // alert(text);
    if (text) {
      const filterData1 = users.filter(
        (item, index) =>
          item.userName.toLowerCase().includes(text.toLowerCase()) ||
          item.roleName.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredData(filterData1);
      setCount(Math.ceil(filterData1.length / PER_PAGE));
      setPage(1);
      _DATA.jump(1);
    } else {
      getNewUsers();
    }
  };
  const handleRequestSort = (event, property) => {
    const isAscending = valueByOrder === property && orderDirection === "asc";
    setValueByOrder(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  console.log(filteredData, "filtered DATA");
  function descendingComparator(a, b, orderBy) {
    // console.log(a.designName, b, "w");
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };

  const getNewUsers = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getUserList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setUsers(result.userList);
        setFilteredData(result.userList);
        setCount(Math.ceil(result.userList.length / PER_PAGE));
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    if (loginStatus == 1) {
      getNewUsers();
      setCartItems([]);
    }
  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="bodyNT">
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          style={{ position: "absolute" }}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div className={classes.wrapper} style={{ zIndex: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "2px 0px",
            }}
          >
            <div style={{ marginRight: 0 }}>
              {theme == "light" ? (
                <CustomTextFieldL
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              ) : (
                <CustomTextFieldD
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <div className={classes.tableI}>
            <div className={classes.headerRow}>
              <div
                className={classes.headerRowItem}
                style={{ width: "30%", marginLeft: 15 }}
              >
                User Name
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "30%", marginLeft: 15 }}
              >
                Role
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "20%", textAlign: "right" }}
              >
                Date
              </div>
              <StyledTableSortLabel
                active={valueByOrder === "approvalStatus"}
                direction={
                  valueByOrder === "approvalStatus" ? orderDirection : "asc"
                }
                onClick={createSortHandler("approvalStatus")}
                style={{ width: "20%", justifyContent: "flex-end" }}
              >
                <div className={classes.headerRowItem} key={"approvalStatus"}>
                  Current Status
                </div>
              </StyledTableSortLabel>
            </div>
            {sortedRowInformation(
              filteredData,
              getComparator(orderDirection, valueByOrder)
            )
              .slice((page - 1) * PER_PAGE, (page - 1) * PER_PAGE + PER_PAGE)
              .map((item, index) => {
                return (
                  <div
                    className={
                      index % 2 == 0
                        ? classes.tableRowEven
                        : classes.tableRowOdd
                    }
                    onClick={() => {
                      setSelectedUser(item.userID);
                      history.push("/approveUsers");
                    }}
                  >
                    <div
                      className={classes.tableCell}
                      style={{ width: "30%", marginLeft: 15 }}
                    >
                      {item.userName}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{ width: "30%", marginLeft: 15 }}
                    >
                      {item.roleName}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{
                        width: "20%",
                        justifyContent: "flex-end",
                        textAlign: "right",
                      }}
                    >
                      {item.formattedSavedDate}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{
                        width: "20%",
                        justifyContent: "flex-end",
                        textAlign: "right",
                        paddingRight: 20,
                      }}
                    >
                      {item.approvalStatus == 2 ? (
                        <div
                          className={classes.approveBtn}
                          style={{
                            background:
                              "linear-gradient(-45deg,#ED213A,#d9002d)",
                          }}
                        >
                          Rejected{" "}
                        </div>
                      ) : item.approvalStatus == 0 ? (
                        <div className={classes.approveBtn}>Approve </div>
                      ) : item.approvalStatus == 1 && item.activeStatus ? (
                        <div
                          className={classes.approveBtn}
                          style={{
                            background:
                              "linear-gradient(-45deg,#2193b0,#6dd5ed)",
                          }}
                        >
                          Active{" "}
                        </div>
                      ) : (
                        <div
                          className={classes.approveBtn}
                          style={{
                            background:
                              "linear-gradient(-45deg,#f46b45,#eea849)",
                          }}
                        >
                          Blocked{" "}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={classes.tableWrapperRow}>
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
