import React, { useState, useEffect, useMemo, memo, useRef } from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Button,
  Checkbox,
  Slider,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Link, useHistory, Redirect } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "../stylesheets/FreelancerNT.css";
import Header from "../components/header";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import colors from "../config/colors";
import { Pagination } from "@material-ui/lab";
import usePagination from "../hooks/Pagination";
import Logo from "../img/BK.png";
var w = window.innerWidth;
var h = window.innerHeight;

const AirbnbSlider = withStyles({
  root: {
    color: "#3e7cc2AA",
    height: 3,
    width: "80%",
    padding: "13px 0",
  },
  thumb: {
    height: 25,
    minWidth: 25,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },
})(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span style={{ fontSize: ".6rem" }}>{props["aria-valuenow"]}</span>
    </span>
  );
}

const RedCheckbox = withStyles({
  root: {
    color: "#75B551",
    width: 10,
    height: 10,
    "&$checked": {
      color: "#49b675",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .1s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 40px",
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "15px",
  },
  leftFilters: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
  },
  filterContainer: {
    maxWidth: "18vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    padding: ".2rem",
  },
  container: {
    display: "flex",
    flex: 1,
    margin: "0 !important",
    justifyContent: "flex-start!important",
  },
  filterItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "right",
    marginTop: "1rem",
    fontSize: "1.2rem",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.01)",
    },
  },
  filterCheckrow: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "flex-start",
    marginLeft: 20,
    fontSize: "1.1rem",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.01)",
    },
  },
  rightCatalog: {
    width: "78%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "auto",
    flexWrap: "wrap",
    overflowX: "auto",
    margin: 10,
    overflowY: "hidden",
  },
  tableWrapperRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "2.5px 5px",
    width: "100%",
    justifyContent: "center",
    marginTop: 10,
    // border:"2px solid blue",
  },
  itemDesctiption: {
    fontWeight: "bold",
    fontSize: "1rem",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    bottom: 0,
    left: 0,
    right: 0,
    webkitBackdropFilter: "blur(8px)" /* Safari 9+ */,
    backdropFilter: "blur(8px)" /* Chrome and Opera */,
    boxShadow: "inset 0 0 0 200px rgba(255,255,255,0.08)",
    zIndex: 100,
    display: "none",
    padding: 12,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  productDescriptor: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    webkitBackdropFilter: "blur(18px)" /* Safari 9+ */,
    backdropFilter: "blur(18px)" /* Chrome and Opera */,
    boxShadow: "inset 0 0 0 200px rgba(255,255,255,0.08)",
    zIndex: 100,
    display: "none",
    padding: 12,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  cardGD: {
    maxHeight: "20vh",
    width: "10vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    margin: ".7rem",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  images: {
    // width: "15vw",
    objectFit: "contain",
    alignSelf: "center",
    marginBottom: 0,
  },
  bottomdump: {
    display: "flex",
    flexDirection: "row",
  },
  cardGDColor: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    margin: "2px",
    borderRadius: 4,
    transition: "all .2s ease-in-out",
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: colors.background,
      transform: "scale(1.02)",
    },
  },
  bottomdumpColor: {
    margin: "5px",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  submit: {
    padding: "10px 35px",
    color: "#FFFFFF",
    backgroundColor: "#75B551",
    borderRadius: 5,
    fontSize: "0.9rem",
    marginLeft: 10,
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  filterContainer: {
    maxWidth: "14vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    borderRadius: "1rem",
    boxShadow: "0.2rem 0.2rem .4rem #c8d0e7, -0.3rem -0.3rem .5rem #FFFFFF",
    padding: "1rem",
    margin: "1rem",
  },
  filterItemWrapper: {
    display: "flex",
    width: "12vw",
    flexDirection: "column",
    justifyContent: "flex-start",
    transition: "all .2s ease-in-out",
    borderBottom: "1.5px inset #1C6EA433",
    padding: ".2rem",
  },
  filterItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "all .2s ease-in-out",
    borderRadius: 8,
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      color: colors.redHK,
      transform: "scale(1.03)",
    },
  },
  filterCheckrow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    transition: "all .2s ease-in-out",
    color: "#3e7cc2",
    alignItems: "center",
    fontSize: ".9rem",
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      fontWeight: "bold",
    },
  },
}));

const notify = (type, message) => {
  if (type === "submit") {
    toast("Design has been Sent for Review");
  } else if (type == "error") {
    toast.error(message);
  } else if (type == "success") {
    toast.success(message, {
      iconTheme: {
        primary: "#0047AB",
        secondary: "#FFFAEE",
      },
    });
  } else if (type == "custom") {
  }
};

const CustomTextFieldL = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000088",
      },
      "&:hover fieldset": {
        borderColor: "#00000088",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000088",
      },
    },
  },
})(TextField);

const CustomTextFieldD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#49b675AA",
    },
    "& label": {
      color: "#49b675AA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#49b675AA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#49b675AA",
      },
      "&:hover fieldset": {
        borderColor: "#49b675AA",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#49b675AA",
      },
    },
  },
})(TextField);

export default function Catalogue(props) {
  const {
    selectedDesign,
    setSelectedDesign,
    theme,
    setTheme,
    headerData,
    setHeaderData,
    isHeader,
    userID,
    setIsHeader,
    loginStatus,
    designID,
    setDesignID,
    setCartItems,
    cartItems,
  } = props;

  const classes = useStyles();
  const [filters, setFilters] = useState([]);
  const [product, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedProdindex, setSelectedProdIndex] = useState([]);
  let [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const PER_PAGE = 52;
  const _DATA = usePagination(filteredData, PER_PAGE);
  const topRef = useRef(null);

  const history = useHistory();
  const bottomDescriptor = {
    open: { display: "block", opacity: 1, y: 0, height: "auto" },
    closed: { display: "none", opacity: 0, y: 0, height: "0" },
  };

  const getBuyerEnquiryDetails = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getCatalogue", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        enquiryID: selectedDesign,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        // setCartItems([]);
        const temp = JSON.parse(JSON.stringify(result.result.Products));
        const temp1 = JSON.parse(JSON.stringify(cartItems));
        temp.map((v) => {
          if (v.selectedInEnquiry === true) {
            if (!temp1.some((value) => value.designID === v.designID)) {
              temp1.push(v);
            }
          }

          if (v.selectedInEnquiry === false) {
            if (temp1.some((value) => value.designID === v.designID)) {
              v.selectedInEnquiry = true;
            }
          }

          v.colorDetails.length > 0 &&
            v.colorDetails.map((val, idx) => {
              val.isSelected = false;
            });
        });

        setProducts(temp);
        setFilteredData(temp);
        setCount(Math.ceil(temp.length / PER_PAGE));
        setCartItems(temp1);

        var filtersVal = result.result.Filter.map((v) => ({
          ...v,
          expand: false,
        }));
        filtersVal.forEach((item) => {
          item.filterValue.forEach((item) => {
            item.checked = false;
          });
        });
        setFilters(filtersVal);
        // setBrand(result.enquiryDetails[0].buyer)
        // setMessage(result.enquiryDetails[0].buyer)
      }).catch((error) => {
        console.error("An error occurred:", error);
        // toast.error(error);
      });
  };
  useEffect(() => {
    if (loginStatus == 1) {
      getBuyerEnquiryDetails();
    }
  }, []);

  const handleSearchData = (text) => {
    // alert(text);
    if (text) {
      const filterData1 = product.filter((item, index) =>
        item.designName.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredData(filterData1);
      setCount(Math.ceil(filterData1.length / PER_PAGE));
    } else {
      setFilteredData(product);
      setCount(Math.ceil(product.length / PER_PAGE));
    }
  };

  const updateData = (primaryFiltersx) => {
    // setFilteredData([]);
    var primaryFilters = [];
    primaryFiltersx.forEach((item, index) => {
      var temp = [];
      item.filterValue.forEach((item2, value2) => {
        if (item2.checked) {
          temp.push(item2);
        }
      });
      item.filterValue = temp;
      primaryFilters.push(item);
    });
    // var secFilters = [];
    // secondaryFiltersx.forEach((item, index) => {
    //   var temp = [];
    //   item.FilterValue.forEach((item2, value2) => {
    //     if (item2.checked) {
    //       temp.push(item2);
    //     }
    //   });
    //   item.FilterValue = temp;
    //   secFilters.push(item);
    // });

    fetch("https://studioeapi.bluekaktus.com/api/design/getCatalogue", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        enquiryID: selectedDesign,
        filters: primaryFilters,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        if (result.Message !== "Catalogue Fetched") {
          setNoData(true);
        } else {
          setFilteredData(result.result.Products);
          setCount(Math.ceil(result.result.Products.length / PER_PAGE));
          setNoData(false);
        }
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    setTimeout(() => {
      topRef.current?.scrollIntoView({ top: 0, left: 0, behavior: "smooth" });
    }, 1000);
  };

  useEffect(() => {
    setPage(1);
    _DATA.jump(1);
    setTimeout(() => {
      topRef.current?.scrollIntoView({ top: 0, left: 0, behavior: "smooth" });
    }, 1000);
  }, [count]);

  const saveEnquiryDetails = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/insertEnquiryDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        buyerEnquiryID: selectedDesign,
        designs: cartItems,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((responseJson) => {
        if (responseJson.Message === "Enquiry Details Updated") {
          //setCartItems([]);
          notify("success", responseJson.Message);
          //history.push("/buyerEnquiries");
        }
      })
      .catch((error) => {
        console.log(error);
        notify("error","Something went wrong")
      });
  };

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={theme == "light" ? "bodyNT" : "body"}>
        <div ref={topRef} />
        <Toaster
          position="bottom-center"
          toastOptions={{
            // Define default options
            className: "",
            duration: 4000,
            style: {
              color: " #FFFFFF",
              background: "#75B551DD",
            },
          }}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: 15,
            }}
          >
            <div style={{ marginRight: 10 }}>
              {theme == "light" ? (
                <CustomTextFieldL
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              ) : (
                <CustomTextFieldD
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              )}
            </div>
            <div
              className={classes.submit}
              onClick={() => {
                saveEnquiryDetails();
              }}
            >
              Save Products
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.filterContainer}>
              <div
                style={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  marginTop: ".2rem",
                  color: "#75B551",
                  fontWeight: "bold",
                  marginBottom: ".2rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFilteredData(product);
                  setCount(Math.ceil(product.length / PER_PAGE));
                  var tempFilt = JSON.parse(JSON.stringify(filters));
                  tempFilt.forEach((filter) => {
                    filter.filterValue.forEach((subfilter) => {
                      subfilter.checked = false;
                    });
                  });
                  // var tempFilt2 = JSON.parse(JSON.stringify(secFilters));
                  // tempFilt2.forEach((filter) => {
                  //   filter.FilterValue.forEach((subfilter) => {
                  //     subfilter.checked = false;
                  //   });
                  // });
                  setFilters(tempFilt);
                  // setSecFilters(tempFilt2);
                  // setSearchText("");
                  // setRiurl("");
                }}
              >
                Filters
              </div>
              {filters.map((item, index) => (
                <div className={classes.filterItemWrapper}>
                  <div
                    className={classes.filterItem}
                    onClick={() => {
                      let temp = filters;
                      temp[index].expand = !temp[index].expand;
                      setFilters(JSON.parse(JSON.stringify(temp)));
                    }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        marginRight: "1rem",
                        marginTop: ".5rem",
                        color: "#75B551",
                        fontSize: "1.1rem",
                      }}
                    >
                      {item.filterName}
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        marginTop: ".5rem",
                        color: "#75B551",
                        fontSize: "1.1rem",
                        float: "right",
                      }}
                    >
                      {item.expand ? "-" : "+"}
                    </div>
                  </div>
                  {item.expand && (
                    <div style={{ maxHeight: "40vh", overflow: "auto" }}>
                      {item.filterValue.map((item, index2) => (
                        <div
                          className={classes.filterCheckrow}
                          onClick={() => {
                            var tempx = JSON.parse(JSON.stringify(filters));

                            tempx[index].filterValue[index2].checked =
                              !tempx[index].filterValue[index2].checked;

                            setFilters(JSON.parse(JSON.stringify(tempx)));
                            updateData(JSON.parse(JSON.stringify(tempx)));
                          }}
                        >
                          <RedCheckbox checked={item.checked} />
                          <div
                            style={{
                              marginLeft: 10,
                              marginTop: 5,
                              color: "#75B551",
                            }}
                          >
                            {item.value}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className={classes.rightCatalog}
            >
              {filteredData.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    {_DATA.currentData().map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          width: "22.3%",
                          flexDirection: "column",
                          margin: "5px 15px",
                        }}
                        key={index}
                      >
                        <motion.div
                          style={{
                            width: "100%",
                            height: "45vh",
                            borderRadius: ".5rem",
                            boxShadow:
                              "0.2rem 0.2rem .4rem #c8d0e7, 0.2rem 0.2rem .4rem #c8d0e7",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            objectFit: "contain",
                            padding: 5,
                            backgroundColor: "#ffffff",
                          }}
                          onHoverStart={() => {
                            var temp = filteredData;
                            temp.filter((item1, index1) => {
                              if (item1.designID == item.designID) {
                                item1.showDetails = true;
                              }
                            });
                            setFilteredData(JSON.parse(JSON.stringify(temp)));
                          }}
                          onHoverEnd={() => {
                            var temp = filteredData;
                            temp.filter((item1, index1) => {
                              if (item1.designID == item.designID) {
                                item1.showDetails = false;
                              }
                            });
                            setFilteredData(JSON.parse(JSON.stringify(temp)));
                          }}
                        >
                          {item.thumbNail3D != "NO IMAGE" ? (
                            <img
                              src={item.thumbNail3D}
                              style={{
                                width: "100%",
                                objectFit: "contain",
                                maxHeight: "100%",
                              }}
                            />
                          ) : (
                            <>
                              {/* <img
                            src={Logo}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              maxHeight: "100%",
                            }}
                          /> */}
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                No 3D image uploaded.
                              </p>
                            </>
                          )}

                          <motion.div
                            className={classes.productDescriptor}
                            animate={item.showDetails ? "open" : "closed"}
                            variants={bottomDescriptor}
                            onClick={() => {
                              //setSelectedProdIndex(index)
                              setDesignID(item.designID);
                              history.push({
                                pathname: "/designDetails",
                                state: { cartItems: cartItems, product: item },
                              });
                            }}
                          >
                            <div>{item.designName}</div>
                            <div>{item.productCode}</div>

                            <div
                              style={{ textAlign: "right", fontSize: "1.6rem" }}
                            >
                              {"Know More"}
                            </div>
                          </motion.div>
                          <div
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 10,
                              height: 50,
                              width: 50,
                              borderRadius: 40,
                              backgroundColor: "#FFFFFFAA",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={(e) => {
                              let temp = JSON.parse(
                                JSON.stringify(filteredData)
                              );
                              let temp2 = JSON.parse(JSON.stringify(cartItems));
                              temp.map((item1, index1) => {
                                if (item1.designID == item.designID) {
                                  if (!item1.isSelected) {
                                    item1.selectedInEnquiry =
                                      !item1.selectedInEnquiry;
                                    setFilteredData(temp);
                                    if (
                                      temp2.filter(
                                        (e) => e.designID === item.designID
                                      ).length > 0
                                    ) {
                                      temp2.splice(
                                        temp2.findIndex(
                                          (e) => e.designID === item.designID
                                        ),
                                        1
                                      );
                                    } else {
                                      temp2.push(item1);
                                    }
                                  } else if (
                                    item1.isSelected &&
                                    item1.selectedInEnquiry
                                  ) {
                                    item1.selectedInEnquiry =
                                      !item1.selectedInEnquiry;
                                    item1.isSelected = !item1.isSelected;
                                    let temp2 = JSON.parse(
                                      JSON.stringify(cartItems)
                                    );
                                    if (
                                      temp2.filter(
                                        (e) => e.designID === item.designID
                                      ).length > 0
                                    ) {
                                      temp2.splice(
                                        temp2.findIndex(
                                          (e) => e.designID === item.designID
                                        ),
                                        1
                                      );
                                    } else {
                                      temp2.push(item1);
                                    }
                                  } else {
                                    notify(
                                      "error",
                                      "This product is already selected for other enquiry."
                                    );
                                  }
                                }
                              });
                              setFilteredData(temp);
                              setCartItems(temp2);
                            }}
                          >
                            <Checkbox
                              icon={
                                <FavoriteBorder style={{ color: "#49b675" }} />
                              }
                              checkedIcon={
                                <Favorite style={{ color: "#49b675" }} />
                              }
                              checked={item.selectedInEnquiry}
                            />
                          </div>
                        </motion.div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            flexWrap: "wrap",
                            marginTop: 2,
                          }}
                        >
                          {item.colorDetails
                            .slice(0, 6)
                            .map((coloritem, colorindex) => {
                              return (
                                <div
                                  key={colorindex}
                                  style={{
                                    width: "14%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    padding: 2,
                                    margin: 2,
                                    backgroundColor: coloritem.isSelected
                                      ? "rgb(73, 182, 117)"
                                      : "#ffffff",
                                  }}
                                  onClick={() => {
                                    const temp = JSON.parse(
                                      JSON.stringify(filteredData)
                                    );

                                    temp.map((val, idx) => {
                                      if (val.designID === item.designID) {
                                        let flag = 0;
                                        val.thumbNail3D = coloritem.imagePath;
                                        val.thumbNailBack3D =
                                          coloritem.backImageThumbnail;
                                        val.thumbNailMisc3D =
                                          coloritem.misc2ImgeThumbnail;
                                        val.thumbNailSide3D =
                                          coloritem.sideImageThumbnail;
                                        val.colorDetails.map((val1, idx1) => {
                                          if (idx1 === colorindex) {
                                            val1.isSelected = !val1.isSelected;
                                          } else {
                                            val1.isSelected = false;
                                          }
                                        });

                                        val.colorDetails.map((val2, idx2) => {
                                          if (val2.isSelected === true) {
                                            flag = 1;
                                          }
                                        });

                                        if (flag == 0) {
                                          val.thumbNail3D =
                                            product[idx].thumbNail3D;
                                          val.thumbNailBack3D =
                                            product[idx].thumbNailBack3D;
                                          val.thumbNailMisc3D =
                                            product[idx].thumbNailMisc3D;
                                          val.thumbNailSide3D =
                                            product[idx].thumbNailSide3D;
                                        }
                                      }
                                    });
                                    setFilteredData(temp);
                                  }}
                                >
                                  <img
                                    src={coloritem.imagePath}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                      maxHeight: "100%",
                                      backgroundColor: "#ffffff",
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={classes.tableWrapperRow}>
                    <Pagination
                      count={count}
                      size="large"
                      page={page}
                      variant="outlined"
                      shape="rounded"
                      onChange={handleChange}
                    />
                  </div>
                </>
              ) : (
                <div
                  style={{
                    fontSize: "2rem",
                    color: "rgb(117, 181, 81)",
                    textAlign: "center",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  No products for this filter combination
                </div>
              )}
            </motion.div>
          </div>
        </div>
      </div>
    );
  }
}
