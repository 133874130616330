import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../stylesheets/FreelancerNT.css";
import ChatIcon from "@mui/icons-material/Chat";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Header from "../components/header";
import pptxgen from "pptxgenjs";
import toast, { Toaster } from "react-hot-toast";
import { TenMpTwoTone } from "@mui/icons-material";
import { Redirect } from "react-router-dom";
import Notification from "../components/Notification";

var w = window.innerWidth;
var h = window.innerHeight;
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0vw 2.5vw",
    width: "95vw",
  },
  tableI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    border: "1.5px solid #00000033",
    // maxHeight:"80vh",
    marginTop: 10,
    overflow: "auto",
    overflowX: "hidden",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  tableSubI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    maxHeight: "40vh",
    backgroundColor: "#DDDDDDDD",
    overflowY: "overlay",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    padding: 8,
    borderBottom: "1.5px solid #00000055",
    width: "auto",
  },
  headerRowItem: {
    color: "#75B551",
    fontSize: "1rem",
    fontWeight: "bold",
    marginRight: 15,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    padding: 6,
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  tableRowsub: {
    display: "flex",
    flexDirection: "row",
    padding: "6px 8px",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
    },
  },

  tableCell: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.2rem",
    color: "#000000AA",
    marginRight: 15,
  },
  tableCellSub: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1rem",
    color: "#000000AA",
    marginRight: 15,
  },
  button: {
    padding: 4,
    background: "#FF0000",
    color: "white",
    // fontSize: ".7rem",
    borderRadius: 5,
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
}));

export default function MarginApprovals(props) {
  const {
    selectedDesign,
    setSelectedDesign,
    theme,
    setTheme,
    headerData,
    setHeaderData,
    isHeader,
    userID,
    setIsHeader,
    loginStatus,
    setLoginStatus,
    cookies,
    removeCookie,
  } = props;
  const classes = useStyles();
  const [enquiry, setEnquiry] = useState([]);
  const [enquiryDesign, setenquiryDesign] = useState([]);
  const [globalMargin, setGlobalMargin] = useState(0);

  const notify = (type, message) => {
    if (type === "submit") {
      toast("Design has been Sent for Review");
    } else if (type == "error") {
      toast.error(message);
    } else if (type == "success") {
      toast.success(message, {
        iconTheme: {
          primary: "#0047AB",
          secondary: "#FFFAEE",
        },
      });
    } else if (type == "custom") {
      toast((t) => (
        <span>
          <b>Thanks Shilpa Polavarapu for submitting your awesome designs.</b>
          <br />
          <br />
          Our team is on the job of reviewing your designs and will share
          feedback soon. Check back here for updates. You will also receive a
          notification once the review is completed.
        </span>
      ));
    }
  };

  const getData = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getAdminDasboard", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result, "RESPONSE DATA");
        setGlobalMargin(result.globalMargin[0].marginValue);
        setEnquiry(result.result);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const getExcess = (data) => {
    var flag = 0;
    data.forEach((item, index) => {
      if (item.margin < globalMargin && item.margin > 0) {
        flag = flag + 1;
      }
    });
    return flag;
  };

  const action = (action, enquiryID, designID, index1, index2) => {
    fetch("https://studioeapi.bluekaktus.com/api/design/approveMarginStatus", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        enquiryID: enquiryID,
        designID: designID,
        margin: action,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        if (result.Message == "Margin status updated") {
          var temp = JSON.parse(JSON.stringify(enquiry));
          temp[index1].designs[index2].userApproval = action;
          console.log();
          setEnquiry(temp);
          if (action) {
            notify("success", "Margin Approved");
          } else {
            notify("error", "Margin Rejected");
          }
        } else {
          notify("error", "An Error has occured");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // getEnquiryData();
  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="bodyNT">
        <Toaster
          position="bottom-right"
          toastOptions={{
            // Define default options
            className: "",

            style: {
              color: " #FFFFFF",
              background: "#75B551DD",
            },
            success: {
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
            submit: {
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
            custom: {
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
            error: {
              duration: 2000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
          }}
        />
        <Notification
          theme={theme}
          setTheme={setTheme}
          setLoginStatus={setLoginStatus}
          style={{ position: "absolute" }}
          cookies={cookies}
          removeCookie={removeCookie}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div className={classes.wrapper}>
          <div className={classes.tableI}>
            <div className={classes.headerRow}>
              <div
                className={classes.headerRowItem}
                style={{ width: "20%", textAlign: "left" }}
              >
                Merchant
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "20%", textAlign: "left" }}
              >
                Brand
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "8%", textAlign: "center" }}
              >
                Enquiry
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "30%", textAlign: "left" }}
              >
                Created On
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "11%", textAlign: "right" }}
              >
                Products
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "11%", textAlign: "right" }}
              >
                Excess Margin
              </div>
            </div>
            {enquiry.map((item, index) => {
              let itemObj = { item, index };
              return (
                <>
                  <div
                    className={classes.tableRow}
                    style={{
                      backgroundColor: item.open ? "#FFFFFF" : "#FFFFFF00",
                    }}
                    onClick={() => {
                      var temp = JSON.parse(JSON.stringify(enquiry));
                      temp[index].open = !temp[index].open;
                      setEnquiry(temp);
                    }}
                  >
                    <div
                      className={classes.tableCell}
                      style={{ width: "20%", justifyContent: "left" }}
                    >
                      {/* {item.merchantName} */}
                      {"Merchant"}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{ width: "20%", justifyContent: "left" }}
                    >
                      {item.buyer}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{ width: "8%", justifyContent: "center" }}
                    >
                      {index}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{ width: "30%", justifyContent: "left" }}
                    >
                      {"-"}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{ width: "11%", justifyContent: "right" }}
                    >
                      {item.designs.length}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{
                        width: "11%",
                        justifyContent: "right",
                        color: getExcess(item.designs) > 0 ? "red" : "#000000",
                      }}
                    >
                      {getExcess(item.designs)}
                    </div>
                  </div>

                  <div className={classes.tableSubI}>
                    <Collapse in={item.open}>
                      {item && item.designs && item.designs.length > 0 && (
                        <div
                          className={classes.headerRow}
                          style={{ position: "sticky", top: 0 }}
                          onClick={() => {
                            var temp = JSON.parse(JSON.stringify(enquiry));
                            temp[index].open = !temp[index].open;
                            setEnquiry(temp);
                          }}
                        >
                          <div
                            className={classes.headerRowItem}
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            Action
                          </div>
                          <div
                            className={classes.headerRowItem}
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            Product
                          </div>
                          <div
                            className={classes.headerRowItem}
                            style={{ width: "15%", textAlign: "right" }}
                          >
                            Cost Price
                          </div>
                          <div
                            className={classes.headerRowItem}
                            style={{ width: "15%", textAlign: "right" }}
                          >
                            Sell Price
                          </div>
                          <div
                            className={classes.headerRowItem}
                            style={{ width: "20%", textAlign: "right" }}
                          >
                            Margin
                          </div>
                        </div>
                      )}

                      {/* {item.row.map((item,index)=>{
                              return( */}
                      {item && item.designs && item.designs.length > 0 ? (
                        item.designs.map((val, ind, arr) => {
                          return (
                            <div className={classes.tableRowsub}>
                              <div
                                className={classes.tableCellSub}
                                style={{ width: "25%", justifyContent: "left" }}
                              >
                                {val.userApproval || val.margin == 0 ? (
                                  <div
                                    style={{
                                      backgroundColor: "#AAAAAAAA",
                                      padding: 4,
                                      borderRadius: 4,
                                    }}
                                  >
                                    Approved
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <div
                                      onClick={() => {
                                        if (!e) var e = window.event;
                                        e.cancelBubble = true;
                                        if (e.stopPropagation)
                                          e.stopPropagation();
                                        action(
                                          true,
                                          enquiry[index].buyerEnquiryID,
                                          val.designID,
                                          index,
                                          ind
                                        );
                                      }}
                                      style={{
                                        backgroundColor: "#75B551",
                                        padding: 4,
                                        borderRadius: 4,
                                        marginRight: 10,
                                        color: "#FFFFFF",
                                      }}
                                    >
                                      Approve
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: "red",
                                        padding: 4,
                                        borderRadius: 4,
                                        color: "#FFFFFF",
                                      }}
                                      onClick={() => {
                                        if (!e) var e = window.event;
                                        e.cancelBubble = true;
                                        if (e.stopPropagation)
                                          e.stopPropagation();
                                        action(
                                          false,
                                          enquiry[index].buyerEnquiryID,
                                          val.designID,
                                          index,
                                          ind
                                        );
                                      }}
                                    >
                                      Reject
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={classes.tableCellSub}
                                style={{ width: "25%", justifyContent: "left" }}
                              >
                                {val.designCode}
                              </div>
                              <div
                                className={classes.tableCellSub}
                                style={{
                                  width: "15%",
                                  justifyContent: "right",
                                }}
                              >
                                {"-"}
                              </div>
                              <div
                                className={classes.tableCellSub}
                                style={{
                                  width: "15%",
                                  justifyContent: "right",
                                }}
                              >
                                {"-"}
                              </div>
                              <div
                                className={classes.tableCellSub}
                                style={{
                                  color:
                                    val.margin > 15
                                      ? "#49b675"
                                      : val.margin > 13 && val.margin <= 15
                                      ? "#75B551"
                                      : val.margin > 10 && val.margin <= 13
                                      ? "#fc9303"
                                      : "#ba0c2f",
                                  width: "20%",
                                  justifyContent: "right",
                                }}
                              >
                                {val.margin}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div align="center">No Designs Available</div>
                      )}

                      {/* )
                                  })}    */}
                      <div></div>
                    </Collapse>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
