export default {
    headerL: "#000000",
    primarytextL: "#ffffff",
    primaryL: "#000000",
    secondaryL: "#000000AA",
    highliterL: "#00853e",
    backgroundl: "#f5f5f5",
    headerbackgroundl: "#75B551",
    headerD: "#ffffff",
    primarytextD: "#1e4b30DD",
    primaryD: "#000000",
    secondaryD: "#000000AA",
    highliterD: "#006a32",
    headerbackgroundD: "#FFFFFF88",
    backgroundD: "linear-gradient(-45deg, #8ee1aa, #88ddd8, #a9e1e9, #78e0c9)",
  };