import * as React from "react";
import { useRef, createContext, useEffect } from "react";
import {
  AnimatePresence,
  motion,
  useCycle,
} from "framer-motion/dist/framer-motion";
import { useDimensions } from "./use-dimension";
import { MenuToggle } from "./MenuToggle";
import "../stylesheets/App.css";
import { MenuItem } from "./MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { light, dark } from "../ui/Toggletheme";

const useStyles = makeStyles((theme) => ({
  nav: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: "300px",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "300px",
  },
  ul: {
    margin: "0",
    padding: "25px",
    position: "absolute",
    top: "100px",
    width: "230px",
  },
}));

export const ThemeContext = createContext(null);
export default function Notification(props) {
  const { theme, setTheme, setLoginStatus, cookies, removeCookie } = props;

  const variants = {
    open: {
      transition: { staggerChildren: 0.05, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 20px 40px)`,
      // zIndex:15,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: "circle(30px at 245px 55px)",
      // zIndex:0,
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const [isOpen, toggleOpen] = useCycle(false, true);
  const [isOpenDel, setIsOpenDel] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const classes = useStyles();
  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      onAnimationStart={() => {
        if (isOpen) {
          setIsOpenDel(true);
        }
      }}
      onAnimationComplete={() => {
        if (!isOpen) {
          setIsOpenDel(false);
        }
      }}
      ref={containerRef}
      className={classes.nav}
      style={{ zIndex: isOpenDel ? 100 : 0 }}
    >
      <motion.div
        className={classes.background}
        variants={sidebar}
        style={{ backgroundColor: theme == "light" ? "#CADFBE" : "#FFFFFFAA" }}
      />
      <motion.ul className={classes.ul} variants={variants}>
        <MenuItem
          theme={theme}
          setTheme={setTheme}
          isOpen={isOpen}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </motion.ul>
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
}
