import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Header from "../components/header";
import toast, { Toaster } from "react-hot-toast";

var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 20px",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF88",
    padding: 10,
    borderRadius: 8,
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent:"flex-end",
  },
  action: {
    display: "flex",
    fontSize:"1.2rem",
    color:"#FFFFFF",
    padding: 8,
    borderRadius:8,
    marginLeft:10,
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform:"scale(1.02)"
    },
  },
  mainDiv: {
    display: "flex",
    flexDirection:"row",
    justifyContent:"space-between",
    padding:8,
  },
  subDiv: {
    display: "flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    padding:8,
  },
  subDivx: {
    display: "flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"flex-start",
    padding:8,
  },
  rowWrapper:{
    display: "flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    padding:6,
    width:"100%",
    fontSize:"1.3rem"
  },
  hyperlink: {
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform:"scale(1.02)"
    },
  },
}));

export default function ApproveUsers(props) {
  const {
    selectedUser,
    loginStatus,
    userID,
    headerData,
    isHeader,
    theme,
    setTheme,
    setCartItems
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});


  const notify = (type, message) => {
    if (type === "submit") {
      toast("Design has been Sent for Review");
    } else if (type == "error") {
      toast.error(message);
    } else if (type == "success") {
      toast.success(message, {
        iconTheme: {
          primary: "#0047AB",
          secondary: "#FFFAEE",
        },
      });
    } else if (type == "custom") {
      toast((t) => (
        <span>
          <b>Thanks Shilpa Polavarapu for submitting your awesome designs.</b>
          <br />
          <br />
          Our team is on the job of reviewing your designs and will share
          feedback soon. Check back here for updates. You will also receive a
          notification once the review is completed.
        </span>
      ));
    }
  };

  const getUserDetails = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getSelectedUserDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: selectedUser,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setUserInfo(result.userDetails[0])
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    if(loginStatus==1){
      getUserDetails();
      setCartItems([]);
    }
  }, []);

  const approve = () =>{
    fetch("https://studioeapi.bluekaktus.com/api/design/approveUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID:userID,
        selectedUserID: selectedUser,
        isActive:1
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result)
        notify("success","User Approved, a confirmation mail has been sent to the user")
        getUserDetails()
        // const myTimeout = setTimeout(()=>{history.goBack()}, 5000);
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  }

  const blockUser = (status) =>{
    var stat = 0
    fetch("https://studioeapi.bluekaktus.com/api/design/blockUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID:userID,
        selectedUserID: selectedUser,
        isApprove:status 
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          stat=true
          return response.json();
        } else {  
          stat=false
          return response.json();
        }
      })
      .then((result) => {
        console.log(result)
        if(stat&&status){
          notify("success","User has been blocked")
          getUserDetails()
        }else if(stat&&!status){
          notify("success","User has been unblocked")
          getUserDetails()
        }else{
          notify("error",result.message)
        }

      });
  }

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="bodyNT">
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <Toaster
            position="bottom-center"
            toastOptions={{
              // Define default options
              className: "",
             
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
              success: {
                duration: 4000,
                style: {
                  color: " #FFFFFF",
                  background: "#75B551DD",
                },
              },
              submit: {
                duration: 4000,
                style: {
                  color: " #FFFFFF",
                  background: "#75B551DD",
                },
              },
              custom: {
                duration: 4000,
                style: {
                  color: " #FFFFFF",
                  background: "#75B551DD",
                },
              },
              error: {
                duration: 2000,
                style: {
                  color: " #FFFFFF",
                  background: "#75B551DD",
                },
              },


            }}
          />
        <div className={classes.wrapper}>
            <div className={classes.wrapper}>
                <div
                className={classes.inputWrapper}
                style={{
                    boxShadow:
                    theme == "light"
                        ? "#75B551 0px 1px 4px"
                        : "#FFFFFFAA 0px 1px 4px",
                    zIndex: 10,
                }}
                >
                {!userInfo.approvalStatus?
                <div className={classes.actions}>
                    <div className={classes.action} style={{ backgroundColor: "#75B551DD" }} onClick={()=>{approve()}}>Approve</div>
                    <div className={classes.action} style={{ backgroundColor:  "#e71837DD" }}>Reject</div>
                </div>:null}
                <div className={classes.mainDiv}>
                    <div className={classes.subDiv} style={{width:"33%"}}>
                        <img src={userInfo.profilePicURL} style={{width:300,height:300,objectFit:"contain",borderRadius:30,boxShadow: "#75B551 0px 1px 4px",}}/>
                    </div>
                    <div className={classes.subDivx} style={{width:"66%"}}>
                        <div className={classes.rowWrapper}>
                          <div style={{width:"20%",textAlign:"left"}}>First Name</div>
                          <div>:</div>
                          <div style={{width:"35%",textAlign:"left",marginLeft:10}}>{userInfo.firstName}</div>
                        </div>
                        <div className={classes.rowWrapper}>
                          <div style={{width:"20%",textAlign:"left"}}>Last Name</div>
                          <div>:</div>
                          <div style={{width:"35%",textAlign:"left",marginLeft:10}}>{userInfo.lastName}</div>
                        </div>
                        <div className={classes.rowWrapper}>
                          <div style={{width:"20%",textAlign:"left"}}>Email</div>
                          <div>:</div>
                          <div style={{width:"35%",textAlign:"left",marginLeft:10}}>{userInfo.email}</div>
                        </div>
                        <div className={classes.rowWrapper}>
                          <div style={{width:"20%",textAlign:"left"}}>User Role</div>
                          <div>:</div>
                          <div style={{width:"35%",textAlign:"left",marginLeft:10}}>{userInfo.userRole}</div>
                        </div>
                        <div className={classes.rowWrapper}>
                          <div style={{width:"20%",textAlign:"left"}}>Portfolio</div>
                          <div>:</div>
                          <div onClick={()=>{window.open(userInfo.portfolioLink)}} className={classes.hyperlink} style={{width:"35%",textAlign:"left",marginLeft:10}}>{userInfo.portfolioLink}</div>
                        </div>
                        {userInfo.approvalStatus==1&&userInfo.activeStatus?
                          <div className={classes.rowWrapper}>
                            <div onClick={()=>{blockUser(false)}}
                              className={classes.hyperlink} 
                              style={{textAlign:"center",background:"linear-gradient(-45deg,#ED213A,#d9002d)",padding:"4px 20px",borderRadius:8,color:"#FFFFFF"}}
                              >
                                Block user
                            </div>
                        </div>:userInfo.approvalStatus==1&&!userInfo.activeStatus?
                         <div className={classes.rowWrapper}>
                         <div onClick={()=>{blockUser(true)}}
                           className={classes.hyperlink} 
                           style={{textAlign:"center",background:"linear-gradient(-45deg,#75B551,#86c662,#96d374,#a2d982)",padding:"4px 20px",borderRadius:8,color:"#FFFFFF"}}
                           >
                             Unblock user
                         </div>
                     </div>:null}
                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}
