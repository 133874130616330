import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Header from "../components/header";
import { Pagination } from "@material-ui/lab";
import usePagination from "../hooks/Pagination";
import Notification from "../components/Notification";

var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 50px",
  },
  tableI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    border: "1.5px solid #00000033",
    maxHeight: "72vh",
    marginTop: 10,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    padding: 10,
    borderBottom: "1.5px solid #00000055",
    position: "sticky",
    top: 0,
  },
  headerRowItem: {
    color: "#75B551",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginRight: 15,
  },
  tableRowEven: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    height: "12vh",
    backgroundColor: "#BBBBBB66",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  tableRowOdd: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    height: "12vh",
    backgroundColor: "#EEEEEEAA",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF88",
    },
  },
  tableCell: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.8vmin",
    color: "#000000AA",
    marginRight: 15,
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    maxHeight: "80vh",
    flexWrap: "wrap",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "40vh",
    width: "25%",
    backgroundColor: "#FFFFFF",
    margin: 8,
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "3px solid #75B551AA",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#75B551AA",
    },
  },
  tableWrapperRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "2.5px 5px",
    width: "100%",
    justifyContent: "center",
    marginTop: 10,
  },
}));

export default function BuyerEnquiries(props) {
  const {
    setSelectedDesign,
    loginStatus,
    setLoginStatus,
    userID,
    headerData,
    isHeader,
    theme,
    setTheme,
    cookies,
    removeCookie,
    setCartItems,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [buyerEnquiries, setBuyerEnquiries] = useState([]);

  let [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const PER_PAGE = 10;
  const _DATA = usePagination(buyerEnquiries, PER_PAGE);

  const getBuyerEnquiries = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getBuyerEnquiry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result, "Hello");
        setBuyerEnquiries(result.buyerEnquiries);
        setCount(Math.ceil(result.buyerEnquiries.length / PER_PAGE));
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    if (loginStatus == 1) {
      getBuyerEnquiries();
      setCartItems([]);
    }
  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="bodyNT">
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          style={{ position: "absolute" }}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div className={classes.wrapper} style={{ zIndex: 10 }}>
          <div className={classes.tableI}>
            <div className={classes.headerRow}>
              <div
                className={classes.headerRowItem}
                style={{ width: "20%", marginLeft: 15 }}
              >
                Brand
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "20%", marginLeft: 15 }}
              >
                Merchant
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "30%", textAlign: "right" }}
              >
                Last Update
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "30%", textAlign: "right" }}
              >
                Products
              </div>
            </div>
            {_DATA.currentData().map((item, index) => {
              return (
                <div
                  className={
                    index % 2 == 0 ? classes.tableRowEven : classes.tableRowOdd
                  }
                  onClick={() => {
                    setSelectedDesign(item.buyerEnquiryID);
                    history.push("/viewEnquiry");
                  }}
                >
                  <div
                    className={classes.tableCell}
                    style={{ width: "20%", marginLeft: 15 }}
                  >
                    {item.buyer}
                  </div>
                  <div
                    className={classes.tableCell}
                    style={{ width: "20%", marginLeft: 15 }}
                  >
                    {item.merchantName}
                  </div>
                  <div
                    className={classes.tableCell}
                    style={{
                      width: "30%",
                      justifyContent: "flex-end",
                      textAlign: "right",
                    }}
                  >
                    {item.createdOn}
                  </div>
                  <div
                    className={classes.tableCell}
                    style={{
                      width: "30%",
                      justifyContent: "flex-end",
                      textAlign: "right",
                    }}
                  >
                    {item.designs.length}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={classes.tableWrapperRow}>
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
