import React, { useState, useEffect } from "react";
import { makeStyles ,withStyles,TextField} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "../stylesheets/login.css";

var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({

    wrapper: {
      alignItems:"center",
      width:"100%",
      display: "flex",
      flexDirection: "column",
      margin:"7vh 0vh",
      height:"100vh",
      justifyContent:"center"
    },
    inputWrapper: {
      width:"70%",
      height:"500px",
      display: "flex",
      flexDirection: "row",
      top:10,
      padding: 8,
      borderRadius: 8,
      background:"#FFFFFF",
      boxShadow: "#75B551 0px 1px 4px",
    },
    inputForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems:"center",
      width:"55%",
    //  paddingTop:50,
      // marginTop:50,
      // marginBottom:50,
      // marginRight:50,
      margin:20,
      backgroundColor: "rgba(158, 225, 226, .40)", 
      backdropFilter: "blur(5px)",
      borderRadius:10
    },
    logoForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems:"center",
      borderRadius:"5%",
      width:"50%",
      marginRight:"10px",
    },
    submit: {
      padding:10,
      background:"#75B551",
      color: "white",
      borderRadius: 8,
      fontSize: "1.5rem",
      transition: "all .2s ease-in-out",
      textAlign:"center",
      marginBottom:30,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "none",
        transform: "scale(1.03)",
      },
    
    },
    signIn: {
      fontSize: "2.5rem",
      transition: "all .2s ease-in-out",
      textAlign:"center",
      fontWeight:"bold",
      opacity:0.7,
    },
    signup:{
      fontSize: ".9rem",
      transition: "all .2s ease-in-out",
      textAlign:"center",
      fontWeight:"bold",
      opacity:0.6,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "none",
        transform: "scale(1.03)",
      },
    }
  
  }));

const CustomTextField = withStyles({
    root: {
      padding: 0,
      "& label.Mui-focused": {
        color: "#00000088",
      },
      "& label": {
        color: "#00000088",
      },
     
      "& .MuiInput-underline:after": {
        borderBottomColor: '#75B551'
      },
      
      "& .MuiInput-underline:before": {
        borderBottomColor: ' #75B551'
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderBottomColor: "#75B551",
        },
        "&:hover fieldset": {
          borderColor: "#75B551",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#75B551",
        },
      },
    },
    
  })(TextField);


export default function PassReset(props) {

  const {
    setUserID,
    setLoginStatus,
    setUserRole,
    setUserNameX,
    headerData,
    setHeaderData,
    isHeader,
    setIsHeader
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [username , setUsername] = useState("");
  const [password , setPassword] = useState("");
  const [tempPassword , setTempPassword] = useState("");
  const [confirmPassword , setConfirmPassword] = useState("");
  const [incorrect , setIncorrect] = useState(false);

  const notify = (type,message) => {
    if (type === "success") {
      toast.success(message, {
        iconTheme: {
          primary: "#0047AB",
          secondary: "#FFFAEE",
        },
      });
      //toast(message);
    }
    if (type === "submit") {
      toast("Changes sent for Review to Curator");
    }
    if (type === "error") {
        toast.error(message);
      }
  };

  // const getHeadersData=(USERID)=>{
  //   fetch("https://studioeapi.bluekaktus.com/api/design/getDesignHeaders", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       userID: USERID,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // console.log(result)
  //       setHeaderData(result.headers);
  //       setIsHeader(true);
  //     });
  // };


  useEffect(() => {
    setLoginStatus(-1)
  }, []);

  const reset = () =>{

    const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (username == "") {
      notify("error", "Please Enter Username");
      return;
    }
    else if(!username.match(validRegex)){
      notify("error", "Please Enter Valid Username");
      return;
    }
    else if (tempPassword == ""){
      notify("error", "Please Enter Temporary Password");
      return;
    }
    else if (password == ""){
      notify("error", "Please Enter New Password");
      return;
    }
    else if (confirmPassword == "") {
      notify("error", "Please Enter Confirm Password");
      return;
    }else if(password !== confirmPassword){
      notify("error","Password do not matches.")
    }else{
    
      fetch("https://studioeapi.bluekaktus.com/api/design/changeSEPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
            {
                userEmail:username,
                userTempPassword:tempPassword,
                userPassword:password
            }
        ),
      })
        .then((response) => {
          if (response.status == 200) {
            setIncorrect(false)
            console.log("Sucess");
            return response.json();
          } else {  
            setLoginStatus(-1);
            setIncorrect(true)
            notify("error","Incorrect Details")
            setPassword("")
            return response.json();
          }
        })
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.Message == "Password Changed") { 
            setUsername("");
            setTempPassword("");
            setPassword("");
            setConfirmPassword("");
            notify("success",responseJson.Message);
            setTimeout(() => {
              history.push("/")
            }, 5000);
            // setUserID(responseJson.loginDetails[0].userID);
            // setUserRole(responseJson.loginDetails[0].roleName);
            // setUserNameX(responseJson.loginDetails[0].userName);
            // getHeadersData(responseJson.loginDetails[0].userID);
            // setLoginStatus(1)
            // history.push("/home")
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
      }
  }

  return (
    <div className="bodyLogin">
    <Toaster
        position="bottom-right"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            color: "#FFFFFF",
            background: "#49b675AA",
          },
        }}
      />
    <div className={classes.wrapper}>
      <div className={classes.inputWrapper}>
      <div className={classes.logoForm}>
      <img src="https://i.imgur.com/xPwZiRc.png"/>
     
      </div>
        <div className={classes.inputForm}>
          
        <div style={{marginTop:"5%",marginBottom:"5%",alignItems:"center"}}>
            <div className={classes.signIn}>New Password</div>
           
          </div>
                <div style={{width:"50%",marginBottom:"5%"}}>
        
        <CustomTextField  variant="standard" 
            style={{ flex: 1 }}
            fullWidth
            id="input-with-icon-textfield"
            label="Username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          </div>
          <div style={{width:"50%",marginBottom:"5%"}}>
          <CustomTextField
            style={{ flex: 1 }}
            fullWidth
            label="Temp Password"
            variant="standard"
            value={tempPassword}
            type="password"
            onChange={(e) => {
              setTempPassword(e.target.value);
            }}
          />
          </div>
          <div style={{width:"50%",marginBottom:"5%"}}>
          <CustomTextField
            style={{ flex: 1 }}
            fullWidth
            label="New Password"
            variant="standard"
            value={password}
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          </div>
          <div style={{width:"50%",marginBottom:"6%"}}>
          <CustomTextField
            style={{ flex: 1 }}
            fullWidth
            label="Confirm Password"
            variant="standard"
            value={confirmPassword}
            type="password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
          </div>
          <div style={{width:"40%",alignItems:"center",fontSize:"1.3rem",marginBottom:10}} onClick={()=>reset()} >
            <div className={classes.submit}>SUBMIT</div>
          </div>
          {/* <div className={classes.signup} onClick={()=>{history.push("/SignUp")}}>
            <div  >Dont have an Account? </div>
            <div style={{color:"#00853e"}} >Sign Up</div> 
          </div>
          {incorrect?
          <div className={classes.signup} style={{marginBottom:15,marginTop:15}} onClick={()=>{history.push("/passReq")}} >
            <div>Forgot Password?</div>
          </div>:null} */}
      </div>
    </div>
    </div>
    </div>
    
          
  );
}
