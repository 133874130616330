import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import "../stylesheets/FreelancerNT.css";
import SendIcon from "@mui/icons-material/Send";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Notification from "../components/Notification";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { light, dark } from "../ui/Toggletheme";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Polling } from "../utils/polling";
import Header from "../components/header";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PersonIcon from "@mui/icons-material/Person";
import toast, { Toaster } from "react-hot-toast";

var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .1s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 40px",
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "15px",
  },
  colWrapper: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "70vh",
    overflow: "auto",
    zIndex: 80,
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#FFFFFF00",
    },
  },
  greeting: {
    fontSize: "2.2rem",
    color: "#00000088",
  },
  name: {
    fontSize: "2.5rem",
    marginLeft: 10,
  },
  feed: {
    fontSize: "1.3rem",
    color: "#00000088",
  },
  notifRow: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 8,
    width: "60vw",
    margin: "5px 15px",
    padding: 5,
  },
  notifMessage: {
    fontSize: "1.3rem",
    paddingTop: 8,
    color: "#000000DD",
    marginLeft: "10px",
  },
  notifTime: {
    fontSize: ".9rem",
    color: "#000000AA",
    marginTop: 5,
    textAlign: "right",
  },
}));
const headvariantslight = {
  initials: {
    color: "black",
  },
  whileHover: {
    color: light.textColor,
    backgroundColor: "#75B551",
  },
  animate: {
    color: "black",
    duration: 0.01,
  },
};
const headvariantsdark = {
  initals: {
    color: dark.textColor,
  },
  whileHover: {
    color: dark.textColor,
    backgroundColor: "#FFFFFF88",
  },
  animate: {
    color: dark.textColor,
    duration: 0.01,
  },
};
const notifRowlight = {
  initials: { backgroundColor: "#75B55111" },
  whileHover: {
    scale: 1.03,
    backgroundColor: "#75B55155",
    cursor: "pointer",
    duration: 0.01,
  },
  animate: { backgroundColor: "#75B55111" },
};
const notifRowdark = {
  initials: { backgroundColor: "#FFFFFF" },
  whileHover: {
    scale: 1.03,
    backgroundColor: dark.headerBackground,
    cursor: "pointer",
    duration: 0.01,
  },
  animate: { backgroundColor: "#FFFFFF" },
};

export default function HomeNT(props) {
  const {
    loginStatus,
    userID,
    userRole,
    userName,
    setSelectedDesign,
    setSelectedUser,
    setLoginStatus,
    theme,
    setTheme,
    headerData,
    setHeaderData,
    isHeader,
    setIsHeader,
    cookies,
    removeCookie,
    setCartItems,
  } = props;

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  const classes = useStyles();
  const history = useHistory();
  const [helloMsgs, setHelloMsgs] = useState([
    "Hello",
    "Greetings",
    "Hola",
    "Bonjour",
    "Guten Tag",
    "Namaste",
  ]);
  const [nameArr, setNameArr] = useState([]);
  const [welcomemessage, setWelcomeMessage] = useState("");
  const [notifications, setNotifications] = useState([]);

  Polling(() => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getNotifications", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: 0,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        setNotifications(result.notifications);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        // toast.error(error);
      });
  }, 3000);

  const getNotifs = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getNotifications", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: 0,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        setNotifications(result.notifications);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        // toast.error(error);
      });
  };

  useEffect(() => {
    if (loginStatus == 1) {
      getNotifs();
      setWelcomeMessage(
        helloMsgs[Math.floor(Math.random() * helloMsgs.length)] + ","
      );
      setCartItems([]);
    }
  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={theme == "light" ? "bodyNT" : "body"}>
        <Notification
          theme={theme}
          setTheme={setTheme}
          setLoginStatus={setLoginStatus}
          style={{ position: "absolute" }}
          cookies={cookies}
          removeCookie={removeCookie}
        />
        <Toaster
          position="bottom-center"
          toastOptions={{
            // Define default options
            className: "",
            duration: 4000,
            style: {
              color: " #FFFFFF",
              background: "#75B551DD",
            },
          }}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div className={classes.wrapper}>
          <div className={classes.rowWrapper}>
            <div className={classes.greeting}>{welcomemessage}</div>
            <div
              className={classes.name}
              style={{
                color: theme == "light" ? light.highliter : dark.highliter,
              }}
            >
              {userName + "."}
            </div>
          </div>
          <div className={classes.rowWrapper}>
            <div className={classes.feed}>Your Feed</div>
          </div>
          <div className={classes.colWrapper}>
            {notifications.map((item, index) => {
              return (
                <motion.div
                  variants={theme == "light" ? notifRowlight : notifRowdark}
                  initial="initials"
                  whileHover="whileHover"
                  animate="animate"
                  className={classes.notifRow}
                  onClick={() => {
                    if (item.status == "USER APPROVAL") {
                      setSelectedUser(item.addedBy);
                      history.push(item.screenCode);
                    } else {
                      setSelectedDesign(item.designID);
                      history.push(item.screenCode);
                    }
                  }}
                >
                  <div
                    style={{
                      width: "1.5px",
                      backgroundColor:
                        item.status == "APPROVAL" ||
                        item.status == "COSTING" ||
                        item.status == "PRODUCTS ADDED"
                          ? "#75B551DD"
                          : item.status == "REJECTED" || item.status == "ALERT"
                          ? "#e71837DD"
                          : item.status == "SEND" ||
                            item.status == "USER APPROVAL"
                          ? "#3e7cc2DD"
                          : "#ffaa00DD",
                      margin: 3,
                      borderRadius: 3,
                    }}
                  ></div>
                  <div style={{ padding: 8, alignSelf: "center" }}>
                    {item.status == "APPROVAL" ? (
                      <DoneAllIcon
                        fontSize="large"
                        style={{ color: "#75B551DD" }}
                      />
                    ) : item.status == "SEND FOR 3D" ? (
                      <ViewInArIcon
                        fontSize="large"
                        style={{ color: "#75B551DD" }}
                      />
                    ) : item.status == "PRODUCTS ADDED" ? (
                      <AddBoxIcon
                        fontSize="large"
                        style={{ color: "#75B551DD" }}
                      />
                    ) : item.status == "RECEIVED COSTING" ? (
                      <PriceCheckIcon
                        fontSize="large"
                        style={{ color: "#75B551DD" }}
                      />
                    ) : item.status == "SEND" ? (
                      <SendIcon
                        fontSize="large"
                        style={{ color: "#3e7cc2DD" }}
                      />
                    ) : item.status == "ALERT" ? (
                      <PriorityHighIcon
                        fontSize="large"
                        style={{ color: "#ffaa00AA" }}
                      />
                    ) : item.status == "REJECTED" ? (
                      <NewReleasesIcon
                        fontSize="large"
                        style={{ color: "#e71837DD" }}
                      />
                    ) : item.status == "USER APPROVAL" ? (
                      <PersonIcon
                        fontSize="large"
                        style={{ color: "#3e7cc2DD" }}
                      />
                    ) : item.status == "COSTING" ? (
                      <MonetizationOnIcon
                        fontSize="large"
                        style={{ color: "#75B551DD" }}
                      />
                    ) : item.status == "REPLY" ? (
                      <SmsFailedIcon
                        fontSize="large"
                        style={{ color: "#ffaa00AA" }}
                      />
                    ) : (
                      <div style={{ visibility: "hidden" }}>
                        <SmsFailedIcon
                          fontSize="large"
                          style={{ color: "#ffaa00AA" }}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ width: "70%" }}>
                    <div className={classes.notifMessage}>{item.message}</div>
                    <div className={classes.notifTime}>{item.addedOn}</div>
                  </div>
                  <div
                    style={{
                      alignSelf: "center",
                      display: "flex",
                      justifyContent: "flex-end",
                      flex: 1,
                      paddingRight: 25,
                    }}
                  >
                    <KeyboardDoubleArrowRightIcon
                      fontSize="large"
                      style={{ color: "#75B551DD" }}
                    />
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
