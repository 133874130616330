import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  createRef,
  useRef,
} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import "../stylesheets/FreelancerNT.css";
import toast, { Toaster } from "react-hot-toast";
import Notification from "../components/Notification";
import CancelIcon from "@mui/icons-material/Cancel";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Header from "../components/header";
import { Polling } from "../utils/polling";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 50px",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF88",
    padding: 10,
    borderRadius: 8,
  },
  inputForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  submit: {
    padding: "10px 50px",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: "1.7rem",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  totalProducts: {
    width: 35,
    height: 35,
    backgroundColor: "#49b675",
    borderRadius: 25,
    color: "#FFFFFF",
    textAlign: "center",
    zIndex: 20,
    fontSize:"1.6rem",
    marginBottom: -25,
    marginLeft: 36,
  },
  addBtn: {
    width: 50,
    height: 50,
    backgroundColor: "#75B551",
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    fontSize: 40,
    color: "#FFFFFF",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
}));

export const ThemeContext = createContext(null);

const CustomTextFieldL = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000088",
      },
      "&:hover fieldset": {
        borderColor: "#00000088",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000088",
      },
    },
  },
  height: "100px",
})(TextField);

const CustomTextFieldD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#49b675AA",
    },
    "& label": {
      color: "#49b675AA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#49b675AA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#49b675AA",
      },
      "&:hover fieldset": {
        borderColor: "#49b675AA",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#49b675AA",
      },
    },
  },
})(TextField);

export default function ViewEnquiry(props) {
  const classes = useStyles();
  const history = useHistory();
  const [brand, setBrand] = useState("");
  const [message, setMessage] = useState("");
  const [totalProducts, setTotalProducts] = useState(0);
  const [files3, setFiles3] = useState([]);

  const {
    selectedDesign,
    setSelectedDesign,
    setLoginStatus,
    theme,
    setTheme,
    headerData,
    setHeaderData,
    isHeader,
    userID,
    setIsHeader,
    loginStatus,
    cookies,
    removeCookie,
    setCartItems
  } = props;

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  const getBuyerEnquiryDetails = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getEnquiryDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        enquiryID: selectedDesign,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setTotalProducts(result.enquiryDetails[0].designs.length);
        setBrand(result.enquiryDetails[0].buyer);
        setMessage(result.enquiryDetails[0].email);
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    if(loginStatus == 1){        
    getBuyerEnquiryDetails();
    setCartItems([]);
    }

  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={theme == "light" ? "bodyNT" : "body"}>
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
          <Toaster
            position="bottom-center"
            toastOptions={{
              // Define default options
              className: "",
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            }}
          />
          {isHeader && (
            <Header
              headerData={headerData}
              theme={theme}
              setTheme={setTheme}
              style={{ zIndex: 10 }}
            />
          )}
          <div className={classes.wrapper}>
            <div
              className={classes.inputWrapper}
              style={{
                boxShadow:
                  theme == "light"
                    ? "#75B551 0px 1px 4px"
                    : "#FFFFFFAA 0px 1px 4px",
                zIndex: 10,
              }}
            >
              <div className={classes.inputForm}>
                <div style={{ marginTop: 4 }}>
                  {theme == "light" ? (
                    <CustomTextFieldL
                      style={{ flex: 1 }}
                      fullWidth
                      disabled
                      label="Enter Brand"
                      variant="outlined"
                      onChange={(e) => {
                        setBrand(e.target.value);
                      }}
                      value={brand}
                    />
                  ) : (
                    <CustomTextFieldD
                      style={{ flex: 1 }}
                      fullWidth
                      disabled
                      label="Enter Brand"
                      variant="outlined"
                      onChange={(e) => {
                        setBrand(e.target.value);
                      }}
                      value={brand}
                    />
                  )}
                </div>
                <div style={{ marginTop: 10 }}>
                  {theme == "light" ? (
                    <CustomTextFieldL
                      style={{ flex: 1 }}
                      fullWidth
                      disabled
                      multiline
                      maxRows={5}
                      label="Enter Enquiry Description"
                      variant="outlined"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      value={message}
                    />
                  ) : (
                    <CustomTextFieldD
                      style={{ flex: 1 }}
                      fullWidth
                      disabled
                      multiline
                      maxRows={5}
                      label="Enter Enquiry Description"
                      variant="outlined"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      value={message}
                    />
                  )}
                </div>
                <div style={{ width: "50%", marginTop: 10 }}>
                  <div className={classes.pdfPicker}>
                    <div
                      style={{
                        padding: 10,
                        paddingTop: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          color: "#000000AA",
                          marginBottom: 5,
                        }}
                      >
                        Attachments
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        {files3.map((item, index) => {
                          var file = files3[index];
                          console.log(file);
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "18%",
                                margin: 8,
                                backgroundColor: "#88888888",
                                borderRadius: 5,
                                padding: 3,
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  padding: 5,
                                }}
                                onClick={(e) => {
                                  if (!e) var e = window.event;
                                  e.cancelBubble = true;
                                  if (e.stopPropagation) e.stopPropagation();
                                  var addedFiles = files3;
                                  addedFiles.splice(index, 1);
                                  var temp = [];
                                  addedFiles.forEach((item) => {
                                    var x = new File([item], item.name, {
                                      type: item.type,
                                    });
                                    Object.assign(x, {
                                      preview: URL.createObjectURL(x),
                                    });
                                    temp.push(x);
                                  });
                                  setFiles3(temp);
                                }}
                              >
                                <CancelIcon
                                  fontSize="small"
                                  style={{ color: "#ff681fDD" }}
                                />
                              </div>

                              {file &&
                              file["name"] &&
                              file["name"].slice(-3) === "pdf" ? (
                                <PictureAsPdfIcon
                                  fontSize="large"
                                  style={{
                                    color: "#e71837DD",
                                    marginBottom: 5,
                                  }}
                                />
                              ) : (file &&
                                  file["name"] &&
                                  file["name"].slice(-3) === "xls") ||
                                (file &&
                                  file["name"] &&
                                  file["name"].slice(-4) === "xlsx") ? (
                                <svg
                                  width="32px"
                                  height="30px"
                                  viewBox="0 0 24 24"
                                  fill="#1D7044"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    marginBottom: 5,
                                  }}
                                >
                                  <g>
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zm-6.8 9L13 8h-2.4L9 10.286 7.4 8H5l2.8 4L5 16h2.4L9 13.714 10.6 16H13l-2.8-4z" />
                                  </g>
                                </svg>
                              ) : file &&
                                file["name"] &&
                                file["name"].slice(-4) === "pptx" ? (
                                <svg
                                  width="32px"
                                  height="30px"
                                  fill="#C64120"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    marginBottom: 5,
                                  }}
                                >
                                  <g>
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zM2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM5 8v8h2v-2h6V8H5zm2 2h4v2H7v-2z" />
                                  </g>
                                </svg>
                              ) : (
                                <img
                                  src={item.preview}
                                  style={{
                                    height: "50%",
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                  }}
                                  // Revoke data uri after image is loaded
                                  onLoad={() => {
                                    URL.revokeObjectURL(item.preview);
                                  }}
                                />
                              )}
                              <div
                                style={{
                                  textOverflow: "ellipsis",
                                  fontSize: ".8rem",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                {item.name}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: 10,
                    }}
                    onClick={() => {
                      history.push("/cat");
                    }}
                  >
                    <div className={classes.totalProducts}>{totalProducts}</div>
                    <div className={classes.addBtn}>+</div>
                    <div style={{ marginTop: 10 }}>Curate Range</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ThemeContext.Provider>
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          style={{ position: "absolute" }}
        />
      </div>
    );
  }
}
