import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { light, dark } from "../ui/Toggletheme";

const useStyles = makeStyles((theme) => ({
  header: {
    maxHeight:"7vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 8,
    margin: 8,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "1.8rem",
    padding: "8px 8px",
    margin: "2px 22px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .1s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
}));

const headvariantslight = {
  initials: {
    color: "black",
  },
  whileHover: {
    color: light.textColor,
    backgroundColor: "#75B551",
  },
  animate: {
    color: "black",
    duration: 0.01,
  },
};

const headvariantsdark = {
  initals: {
    color: dark.textColor,
  },
  whileHover: {
    color: dark.textColor,
    backgroundColor: "#FFFFFF88",
  },
  animate: {
    color: dark.textColor,
    duration: 0.01,
  },
};

export default function Header(props) {
  const { headerData, theme, setTheme } = props;
  var [date,setDate] = useState(new Date());
    
  useEffect(() => {
      var timer = setInterval(()=>setDate(new Date()), 1000 )
      return function cleanup() {
          clearInterval(timer)
      }
  });

  const classes = useStyles();
  const history = useHistory();

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

//   console.log(headerData);
  return (
    <div className={classes.header}>
      {headerData.map((item, index) => {
        return (
          <motion.div
            className={classes.headerItem}
            variants={theme == "light" ? headvariantslight : headvariantsdark}
            initial="initials"
            animate="animate"
            whileHover="whileHover"
            onClick={() => {
              history.push(item.screenCode);
            }}
          >
            {item.screenName}
          </motion.div>
        );
      })}
      {/* <motion.div
        className={classes.headerItem}
        variants={theme == "light" ? headvariantslight : headvariantsdark}
        initial="initials"
        animate="animate"
        whileHover="whileHover"
        onClick={() => {
          history.push("/home");
        }}
      >
        Home
      </motion.div>
      <motion.div
        className={classes.headerItem}
        variants={theme == "light" ? headvariantslight : headvariantsdark}
        initial="initials"
        animate="animate"
        whileHover="whileHover"
        onClick={() => {
          history.push("/designsNT");
        }}
      >
        My Designs
      </motion.div>
      <motion.div
        className={classes.headerItem}
        variants={theme == "light" ? headvariantslight : headvariantsdark}
        initial="initials"
        animate="animate"
        whileHover="whileHover"
        onClick={() => {
          history.push("/addDesign");
        }}
      >
        Add Designs
      </motion.div> */}
      {/* <div
            style={{  color: "#000000AA",
    borderRadius: 8,
    fontSize: "1.1rem",
    padding: "8px 8px",
    marginLeft:"auto",
    marginRight:70,
    letterSpacing: "-1px",
    transition: "all .1s ease-in-out",}}
          >
            <p>{date.toLocaleDateString()}, {date.toLocaleTimeString()}</p>
          </div> */}
    </div>
  );
}
