import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  createRef,
  useRef,
} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import "../stylesheets/FreelancerNT.css";
import toast, { Toaster } from "react-hot-toast";
import { light, dark } from "../ui/Toggletheme";
import CancelIcon from "@mui/icons-material/Cancel";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import Notification from "../components/Notification";
import ChatIcon from "@mui/icons-material/Chat";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Header from "../components/header";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 50px",
  },
  text: {
    textAlign: "center",
    marginTop: 10,
    fontSize: "1.2rem",
    color: "#75B551",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF88",
    padding: 8,
    borderRadius: 8,
  },
  inputForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  pdfPicker: {
    marginTop: 10,
    zIndex: 10,
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.01)",
    },
  },
  selection: {
    width: "100%",
  },
  select: {
    color: "#000000",
  },
  icon: {
    color: "#0000088",
  },
  submit: {
    padding: "10px 50px",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: "1.7rem",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  chatBtn: {
    position: "absolute",
    bottom: 30,
    right: 30,
    // width:"8vh",
    // height:"8vh",
    zIndex: 100,
    padding: 20,
    borderRadius: 50,
    backgroundColor: "#74B451AA",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#74B451",
      transform: "scale(1.1)",
    },
  },
  filter: {
    position: "absolute",
    height: "65vh",
    width: "30%",
    backgroundColor: "#FFFFFF",
    padding: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    borderRadius: "8px",
    zIndex: 120,
  },
  chats: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxHeight: "80%",
    overflow: "auto",
  },
  SendIcon: {
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.5)",
    },
  },
  image: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#00000088",
    borderStyle: "dashed",
    backgroundColor: "#FFFFFFAA",
    color: "#75B551",
    height: "55vh",
    fontSize: "1.2rem",
    transition: "border .24s ease-in-out",
  },
}));

const Chatfeild = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#75B551",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#00000088",
    },
  },
})(TextField);

export const ThemeContext = createContext(null);

const headvariantslight = {
  initials: {
    color: "black",
  },
  whileHover: {
    cursor: "pointer",
    textDecoration: "none",
    color: light.textColor,
    backgroundColor: light.headerBackground,
    borderRadius: 8,
  },
  animate: {
    color: "black",
    duration: 0.01,
  },
};
const headvariantsdark = {
  initals: {
    color: dark.textColor,
  },
  whileHover: {
    cursor: "pointer",
    textDecoration: "none",
    color: dark.textColor,
    backgroundColor: dark.headerBackground,
    borderRadius: 8,
  },
  animate: {
    color: dark.textColor,
    duration: 0.01,
  },
};

export default function View3D(props) {
  const classes = useStyles();
  const history = useHistory();

  const {
    selectedDesign,
    setSelectedDesign,
    setLoginStatus,
    theme,
    setTheme,
    loginStatus,
    userID,
    userRole,
    userName,
    headerData,
    setHeaderData,
    isHeader,
    setIsHeader,
    cookies,
    removeCookie,
    setCartItems,
  } = props;

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  const [thumbnail, setThumbnail] = useState([]);
  const [colors, setColors] = useState([]);
  const [color, setColor] = useState([]);
  const [designData, setDesignData] = useState({});
  const [thumbnail2, setThumbnail2] = useState([]);
  const [thumbnail3, setThumbnail3] = useState([]);
  const [thumbnail4, setThumbnail4] = useState([]);
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [files4, setFiles4] = useState([]);
  const [files5, setFiles5] = useState([]);
  const [files6, setFiles6] = useState([]);
  const [files7, setFiles7] = useState([]);
  const [name, setName] = useState("");
  const [techPack, setTechPack] = useState("");
  const [chatFiles4, setChatFiles4] = useState([]);
  const [showChat, setShowChat] = React.useState(false);
  const [chat, setChat] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const bottomRef = useRef(null);
  const dropzoneRef = createRef();

  const OutlinedInputL = withStyles((theme) => ({
    notchedOutline: {
      borderColor: "#00000088 !important",
    },
  }))(MuiOutlinedInput);
  const OutlinedInputD = withStyles((theme) => ({
    notchedOutline: {
      borderColor: "#49b675AA !important",
    },
  }))(MuiOutlinedInput);

  const notify = (type, message) => {
    if (type === "submit") {
      toast("Design has been Sent for Review");
    } else if (type == "error") {
      toast.error(message);
    } else if (type == "success") {
      toast.success(message, {
        iconTheme: {
          primary: "#0047AB",
          secondary: "#FFFAEE",
        },
      });
    } else if (type == "custom") {
      toast((t) => (
        <span>
          <b>Thanks Shilpa Polavarapu for submitting your awesome designs.</b>
          <br />
          <br />
          Our team is on the job of reviewing your designs and will share
          feedback soon. Check back here for updates. You will also receive a
          notification once the review is completed.
        </span>
      ));
    }
  };

  const sendChat = () => {
    if (chatMessage == "" && chatFiles4.length == 0) {
      notify("error", "Please enter a message");
    } else {
      var status = 0;
      var formdata1 = new FormData();
      var submitObj = {
        userID: userID,
        designID: selectedDesign,
        message: chatMessage,
        attachmentType: chatFiles4.length > 0 ? "FILE" : "TXT",
      };
      formdata1.append("json", JSON.stringify(submitObj));

      formdata1.append("attachment", chatFiles4[0]);

      fetch("https://studioeapi.bluekaktus.com/api/design/insert3DChat", {
        method: "POST",
        body: formdata1,
      })
        .then((response) => {
          status = response.status;
          return response.json();
        })
        .then((result) => {
          if (status == 200) {
            fetch(
              "https://studioeapi.bluekaktus.com/api/design/get3DPartnerView",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userID: userID,
                  designID: selectedDesign,
                }),
              }
            )
              .then((response) => {
                if (response.status === 500) {
                  throw new Error(
                    "Oops, something went wrong on our end. Please try again later"
                  );
                }
                if (response.status === 400) {
                  throw new Error("Invalid request");
                }
                if (!response.ok) {
                  throw new Error(
                    "Oops, something went wrong. Please try again later."
                  );
                }
                return response.json();
              })
              .then((result) => {
                console.log(result);

                setChat(result.renderView[0].renderChat.reverse());
                setChatMessage("");
                setChatFiles4("");
                setTimeout(() => {
                  bottomRef.current?.scrollIntoView({ behavior: "smooth" });
                }, 150);
              }).catch((error) => {
                console.error("An error occurred:", error);
              });
          } else {
            notify("error", result.result);
          }
        });
    }
  };

  const cancel = () => {
    setChatFiles4([]);
  };

  const submit = () => {
    var status;
    fetch(
      "https://studioeapi.bluekaktus.com/api/design/saveTransactionDetails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userID: userID,
          designID: selectedDesign,
          renderPartner: 850,
          statusType: "APPROVAL",
        }),
      }
    )
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((result) => {
        if (status == 200) {
          notify("success", "The 3D renders have been Approved");
          const myTimeout = setTimeout(() => {
            history.goBack();
          }, 5000);
        } else {
          notify("error", "An error occured");
        }
      });
  };

  const getDesignData = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getDesignInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: selectedDesign,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setName(result.designDetails[0].productCode);
        setTechPack(result.designDetails[0].techPackPDF);
      }).catch((error) => {
        console.error("An error occurred:", error);
      });

    fetch("https://studioeapi.bluekaktus.com/api/design/get3DPartnerView", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: selectedDesign,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        setChat(result.renderView[0].renderChat.reverse());
        setDesignData(result.renderView[0]);
        setColors(result.renderView[0].colorDetails);
        result.renderView[0].colorDetails.length > 0 &&
          setColor(result.renderView[0].colorDetails[0].colorID);

        if (result.renderView[0].colorDetails.length > 0) {
          if (
            result.renderView[0].colorDetails[0].frontImagePath3D != "NO IMAGE"
          ) {
            setFiles([
              {
                preview: result.renderView[0].colorDetails[0].frontImagePath3D,
              },
            ]);
            setThumbnail([
              {
                preview: result.renderView[0].colorDetails[0].frontThumbnail3D,
              },
            ]);
          }
          if (
            result.renderView[0].colorDetails[0].backImagePath3D != "NO IMAGE"
          ) {
            setFiles2([
              { preview: result.renderView[0].colorDetails[0].backImagePath3D },
            ]);
            setThumbnail2([
              { preview: result.renderView[0].colorDetails[0].backThumbnail3D },
            ]);
          }
          if (
            result.renderView[0].colorDetails[0].sideImagePath3D != "NO IMAGE"
          ) {
            setFiles3([
              { preview: result.renderView[0].colorDetails[0].sideImagePath3D },
            ]);
            setThumbnail3([
              { preview: result.renderView[0].colorDetails[0].sideThumbnail3D },
            ]);
          }
          if (
            result.renderView[0].colorDetails[0].misc2ImagePath3D != "NO IMAGE"
          ) {
            setFiles4([
              {
                preview: result.renderView[0].colorDetails[0].misc2ImagePath3D,
              },
            ]);
            setThumbnail4([
              {
                preview: result.renderView[0].colorDetails[0].misc2Thumbnail3D,
              },
            ]);
          }
          // if (result.renderView[0].misc1 != "NO IMAGE") {
          //   setFiles5([{ preview: result.renderView[0].misc2 }]);
          // }
          // if (result.renderView[0].misc3 != "NO IMAGE") {
          //   setFiles6([{ preview: result.renderView[0].misc3 }]);
          // }
        } else {
          if (result.renderView[0].frontView != "NO IMAGE") {
            setFiles([{ preview: result.renderView[0].frontView }]);
            setThumbnail([{ preview: result.renderView[0].thumbNail_3D }]);
          }
          if (result.renderView[0].backView != "NO IMAGE") {
            setFiles2([{ preview: result.renderView[0].backView }]);
            setThumbnail2([{ preview: result.renderView[0].thumbNailBack_3D }]);
          }
          if (result.renderView[0].sideView != "NO IMAGE") {
            setFiles3([{ preview: result.renderView[0].sideView }]);
            setThumbnail3([{ preview: result.renderView[0].thumbNailSide_3D }]);
          }
          if (result.renderView[0].misc1 != "NO IMAGE") {
            setFiles4([{ preview: result.renderView[0].misc1 }]);
            setThumbnail4([
              { preview: result.renderView[0].thumbNailMisc1_3D },
            ]);
          }
          if (result.renderView[0].misc2 != "NO IMAGE") {
            setFiles5([{ preview: result.renderView[0].misc2 }]);
          }
          if (result.renderView[0].misc3 != "NO IMAGE") {
            setFiles6([{ preview: result.renderView[0].misc3 }]);
          }
        }
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    if (loginStatus == 1) {
      getDesignData();
      setCartItems([]);
    }
  }, []);
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    accept: { "/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setChatFiles4(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme == "light" ? "#00000088" : "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: theme == "light" ? "#FFFFFFAA" : "#9BDFE2",
    color: theme == "light" ? "#75B551" : "#00000088",
    height: "55vh",
    fontSize: "1.2rem",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: theme == "light" ? "#00000088" : "49b675AA",
  };

  const acceptStyle = {
    borderColor: theme == "light" ? "#00000088" : "49b675AA",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={theme == "light" ? "bodyNT" : "body"}>
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
          <Toaster
            position="bottom-center"
            toastOptions={{
              // Define default options
              className: "",
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            }}
          />
          <motion.div
            // variants={container}
            initial={{ x: "-50vw", y: "30vh", opacity: 0, scale: 0 }}
            animate={
              showChat
                ? { x: "1vw", y: "30vh", opacity: 1, scale: 1 }
                : { x: "-50vw", y: "30vh", opacity: 0, scale: 0 }
            }
            transition={{ duration: 0.3, origin: 0 }}
            className={classes.filter}
            style={{
              boxShadow:
                theme == "light"
                  ? "#75B551 0px 1px 4px"
                  : "#FFFFFFAA 0px 1px 4px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div onClick={() => setShowChat(false)}>
                <CloseIcon />
              </div>
            </div>
            <div className={classes.chats}>
              {chat.map((item, index) => {
                if (item.attachmentType == "TXT") {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          item.roleName == "SE Curator"
                            ? "#75B55155"
                            : "#00000022",
                        padding: "5px 10px",
                        marginTop: 5,
                        borderRadius: 10,
                        maxWidth: "75%",
                        display: "flex",
                        flexDirection: "column",
                        alignSelf:
                          item.roleName == "SE Curator"
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontSize: ".7rem",
                          fontWeight: "bold",
                          color: "#000000AA",
                          textAlign:
                            item.roleName != "SE Curator" ? "right" : "left",
                        }}
                      >
                        {item.roleName}
                      </div>
                      <div
                        style={{
                          textAlign:
                            item.roleName != "SE Curator" ? "right" : "left",
                        }}
                      >
                        {item.message}
                      </div>
                      <div
                        style={{
                          fontSize: ".7rem",
                          textAlign:
                            item.roleName == "3D PATNER" ? "right" : "left",
                          color: "#000000AA",
                        }}
                      >
                        {item.addedOn}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          item.roleName == "SE Curator"
                            ? "#75B55155"
                            : "#00000022",
                        padding: "5px 10px",
                        marginTop: 5,
                        borderRadius: 10,
                        maxWidth: "75%",
                        display: "flex",
                        flexDirection: "column",
                        alignSelf:
                          item.roleName == "SE Curator"
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontSize: ".7rem",
                          fontWeight: "bold",
                          color: "#000000AA",
                          textAlign:
                            item.roleName == "SE Curator" ? "right" : "left",
                        }}
                      >
                        {item.roleName}
                      </div>
                      <div
                        style={{
                          textAlign:
                            item.roleName == "SE Curator" ? "right" : "left",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        onClick={() => {
                          window.open(item.attachment);
                        }}
                      >
                        {item.attachment.slice(
                          item.attachment.length - 3,
                          item.attachment.length
                        ) == "png" ||
                        item.attachment.slice(
                          item.attachment.length - 3,
                          item.attachment.length
                        ) == "jpg" ||
                        item.attachment.slice(
                          item.attachment.length - 3,
                          item.attachment.length
                        ) == "peg" ? (
                          <img
                            src={item.attachment}
                            style={{
                              height: "100%",
                              maxWidth: "100%",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <div>FILE</div>
                        )}
                        <div>{item.message}</div>
                      </div>
                      <div
                        style={{
                          fontSize: ".7rem",
                          textAlign:
                            item.roleName == "SE Curator" ? "right" : "left",
                          color: "#000000AA",
                        }}
                      >
                        {item.addedOn}
                      </div>
                    </div>
                  );
                }
              })}
              <div ref={bottomRef} />
            </div>

            {chatFiles4.length == 0 ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20%",
                  maxWidth: "25%",
                  margin: 3,
                  backgroundColor: "#88888888",
                  // borderRadius: 5,
                  padding: 3,
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    padding: 5,
                  }}
                >
                  <CancelIcon
                    fontSize="small"
                    style={{ color: "#ff681fDD" }}
                    onClick={() => cancel()}
                  />
                </div>

                {chatFiles4[0] &&
                chatFiles4[0]["name"] &&
                chatFiles4[0]["name"].slice(-3) === "pdf" ? (
                  <PictureAsPdfIcon
                    fontSize="large"
                    style={{
                      color: "#e71837DD",
                      marginBottom: 5,
                    }}
                  />
                ) : (chatFiles4[0] &&
                    chatFiles4[0]["name"] &&
                    chatFiles4[0]["name"].slice(-3) === "xls") ||
                  (chatFiles4[0] &&
                    chatFiles4[0]["name"] &&
                    chatFiles4[0]["name"].slice(-4) === "xlsx") ? (
                  <svg
                    width="32px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="#1D7044"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    <g>
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zm-6.8 9L13 8h-2.4L9 10.286 7.4 8H5l2.8 4L5 16h2.4L9 13.714 10.6 16H13l-2.8-4z" />
                    </g>
                  </svg>
                ) : chatFiles4[0] &&
                  chatFiles4[0]["name"] &&
                  chatFiles4[0]["name"].slice(-4) === "pptx" ? (
                  <svg
                    width="32px"
                    height="30px"
                    fill="#C64120"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    <g>
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zM2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM5 8v8h2v-2h6V8H5zm2 2h4v2H7v-2z" />
                    </g>
                  </svg>
                ) : (
                  <img
                    src={chatFiles4[0].preview}
                    style={{
                      height: "50%",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                      URL.revokeObjectURL(chatFiles4[0].preview);
                    }}
                  />
                )}
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                <Chatfeild
                  style={{ flex: 1 }}
                  fullWidth
                  // id="input-with-icon-textfield"
                  label="Enter message"
                  value={chatMessage}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      sendChat();
                    }
                  }}
                  onChange={(e) => {
                    setChatMessage(e.target.value);
                  }}
                />
              </div>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                // onChange={(file) => {
                //   setChatFiles4([file.target.files[0]]);
                // }}
                {...getInputProps()}
                accept="image/*,.pdf,.docx,.xls,.xlsx"
              />
              <label htmlFor={"upload-button"}>
                <div style={{ marginLeft: 10, padding: 3 }}>
                  <AttachFileIcon style={{ color: "#75B551" }} />
                </div>
              </label>
              <div
                className={classes.SendIcon}
                style={{ marginLeft: 15, marginRight: 10 }}
                onClick={() => {
                  sendChat();
                }}
              >
                <SendIcon style={{ color: "#75B551" }} />
              </div>
            </div>
          </motion.div>
          <div
            className={classes.chatBtn}
            onClick={() => {
              setShowChat(!showChat);
              setTimeout(() => {
                bottomRef.current?.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            <ChatIcon fontSize="large" style={{ color: "#FFFFFF" }} />
          </div>
          {isHeader && (
            <Header headerData={headerData} theme={theme} setTheme={setTheme} />
          )}
          <div className={classes.wrapper}>
            <div
              className={classes.inputWrapper}
              style={{
                boxShadow:
                  theme == "light"
                    ? "#75B551 0px 1px 4px"
                    : "#FFFFFFAA 0px 1px 4px",
                zIndex: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: 1,
                  marginBottom: -10,
                  marginRight: 10,
                  marginLeft: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "40%",
                  }}
                >
                  <div
                    className={classes.submit}
                    style={{
                      backgroundColor:
                        theme == "light" ? "#75B551AA" : "#49b675DD",
                    }}
                  >
                    {name}
                  </div>
                  <div
                    className={classes.submit}
                    style={{
                      backgroundColor:
                        theme == "light" ? "#75B551AA" : "#49b675DD",
                    }}
                    onClick={() => {
                      window.open(techPack);
                    }}
                  >
                    Tech pack
                  </div>
                  <div
                    className={classes.submit}
                    style={{
                      backgroundColor:
                        theme == "light" ? "#75B551AA" : "#49b675DD",
                    }}
                    onClick={() => {
                      try {
                        window.open(files[0].preview);
                        window.open(files2[0].preview);
                        window.open(files3[0].preview);
                        window.open(files4[0].preview);
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    Hi Res
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "60%",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    style={{ width: "20vw", marginRight: 10 }}
                  >
                    <InputLabel
                      id="label"
                      style={{
                        color: theme == "light" ? "#00000088" : "#49b675AA",
                      }}
                    >
                      Colors
                    </InputLabel>
                    <Select
                      classes={{
                        select: classes.select,
                        icon: classes.icon,
                      }}
                      label="Colors"
                      value={color}
                      onChange={(e) => {
                        const filterData = designData.colorDetails.filter(
                          (item, index) => item.colorID == e.target.value
                        );
                        if (filterData[0].frontImagePath3D != "NO IMAGE") {
                          setFiles([
                            { preview: filterData[0].frontImagePath3D },
                          ]);
                          setThumbnail([
                            { preview: filterData[0].frontThumbnail3D },
                          ]);
                        } else if (
                          filterData[0].frontImagePath3D == "NO IMAGE"
                        ) {
                          setFiles([]);
                          setThumbnail([]);
                        }
                        if (filterData[0].backImagePath3D != "NO IMAGE") {
                          setFiles2([
                            { preview: filterData[0].backImagePath3D },
                          ]);
                          setThumbnail2([
                            { preview: filterData[0].backThumbnail3D },
                          ]);
                        } else if (
                          filterData[0].backImagePath3D == "NO IMAGE"
                        ) {
                          setFiles2([]);
                          setThumbnail2([]);
                        }
                        if (filterData[0].sideImagePath3D != "NO IMAGE") {
                          setFiles3([
                            { preview: filterData[0].sideImagePath3D },
                          ]);
                          setThumbnail3([
                            { preview: filterData[0].sideThumbnail3D },
                          ]);
                        } else if (
                          filterData[0].sideImagePath3D == "NO IMAGE"
                        ) {
                          setFiles3([]);
                          setThumbnail3([]);
                        }
                        if (filterData[0].misc2ImagePath3D != "NO IMAGE") {
                          setFiles4([
                            { preview: filterData[0].misc2ImagePath3D },
                          ]);
                          setThumbnail4([
                            { preview: filterData[0].misc2Thumbnail3D },
                          ]);
                        } else if (
                          filterData[0].misc2ImagePath3D == "NO IMAGE"
                        ) {
                          setFiles4([]);
                          setThumbnail4([]);
                        }
                        setColor(e.target.value);
                      }}
                      input={
                        theme == "light" ? (
                          <OutlinedInputL label="Colors" />
                        ) : (
                          <OutlinedInputD label="Colors" />
                        )
                      }
                    >
                      {colors.length > 0 ? (
                        colors.map((item, index) => {
                          return (
                            <MenuItem value={item.colorID}>
                              {item.color}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem disabled="true">No Colors</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <div
                    className={classes.submit}
                    style={{
                      backgroundColor:
                        theme == "light" ? "#75B551AA" : "#49b675DD",
                    }}
                    onClick={() => submit()}
                  >
                    Approve
                  </div>
                </div>
              </div>
              <div className={classes.inputForm}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "25%",
                    paddingRight: 8,
                    paddingTop: 8,
                    paddingLeft: 5,
                  }}
                >
                  <div className={classes.text}>Front View</div>
                  <div className={classes.pdfPicker}>
                    <Dropzone maxFiles={1} ref={dropzoneRef}>
                      {({ getRootProps, getInputProps, acceptedFiles }) => {
                        if (thumbnail.length == 0) {
                          return (
                            <div className={classes.image}>
                              <div className={classes.text}>NO IMAGE</div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              // {...getRootProps({ style })}
                              className={classes.image}
                            >
                              <img
                                src={thumbnail[0].preview}
                                style={{
                                  height: "100%",
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                }}
                                // Revoke data uri after image is loaded
                                onLoad={() => {
                                  URL.revokeObjectURL(files[0].preview);
                                }}
                              />
                              {/* <input {...getInputProps()} /> */}
                            </div>
                          );
                        }
                      }}
                    </Dropzone>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "25%",
                    paddingRight: 8,
                    paddingTop: 8,
                  }}
                >
                  <div className={classes.text}>Back Image</div>
                  <div className={classes.pdfPicker}>
                    <Dropzone
                      maxFiles={1}
                      ref={dropzoneRef}
                      // onDrop={(file) => {
                      //   if (
                      //     (file[0].type != "image/jpeg" &&
                      //       file[0].type != "image/png") ||
                      //     file.length > 1
                      //   ) {
                      //     notify("error", "Please Upload only 1 Image");
                      //   } else {
                      //     console.log(file);
                      //     file.map((file) => {
                      //       Object.assign(file, {
                      //         preview: URL.createObjectURL(file),
                      //       });
                      //     });
                      //     console.log(file);
                      //     setFiles2(file);
                      //   }
                      // }}
                    >
                      {({ getRootProps, getInputProps, acceptedFiles }) => {
                        if (thumbnail2.length == 0) {
                          return (
                            <div className={classes.image}>
                              <div className={classes.text}>NO IMAGE</div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              // {...getRootProps({ style })}
                              className={classes.image}
                            >
                              <img
                                src={thumbnail2[0].preview}
                                style={{
                                  height: "100%",
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                }}
                                // Revoke data uri after image is loaded
                                onLoad={() => {
                                  URL.revokeObjectURL(files2[0].preview);
                                }}
                              />
                              {/* <input {...getInputProps()} /> */}
                            </div>
                          );
                        }
                      }}
                    </Dropzone>
                  </div>
                  <div className={classes.text}> 3D File</div>
                  <div className={classes.pdfPicker}>
                    <Dropzone maxFiles={1} ref={dropzoneRef}>
                      {({ getRootProps, getInputProps, acceptedFiles }) => {
                        if (files5.length == 0) {
                          return (
                            <div className={classes.image}>
                              <div className={classes.text}>NO 3D FIles</div>
                            </div>
                          );
                        } else {
                          var file = files5[0];
                          console.log(files5);
                          return (
                            <div
                              {...getRootProps({ style })}
                              className={classes.image}
                              onClick={() => {
                                window.open(files5[0].preview);
                              }}
                            >
                              {file &&
                              file.preview &&
                              file.preview.slice(-3) === "pdf" ? (
                                <PictureAsPdfIcon
                                  fontSize="large"
                                  style={{
                                    color: "#e71837DD",
                                    marginBottom: 5,
                                  }}
                                  onLoad={() => {
                                    URL.revokeObjectURL(file.preview);
                                  }}
                                />
                              ) : (file &&
                                  file.preview &&
                                  file.preview.slice(-3) === "xls") ||
                                (file &&
                                  file.preview &&
                                  file.preview.slice(-4) === "xlsx") ? (
                                <svg
                                  width="32px"
                                  height="30px"
                                  viewBox="0 0 24 24"
                                  fill="#1D7044"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    marginBottom: 5,
                                  }}
                                >
                                  <g>
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zm-6.8 9L13 8h-2.4L9 10.286 7.4 8H5l2.8 4L5 16h2.4L9 13.714 10.6 16H13l-2.8-4z" />
                                  </g>
                                </svg>
                              ) : file &&
                                file.preview &&
                                file.preview.slice(-4) === "pptx" ? (
                                <svg
                                  width="32px"
                                  height="30px"
                                  fill="#C64120"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    marginBottom: 5,
                                  }}
                                >
                                  <g>
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zM2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM5 8v8h2v-2h6V8H5zm2 2h4v2H7v-2z" />
                                  </g>
                                </svg>
                              ) : (
                                <img
                                  src={files5[0].preview}
                                  style={{
                                    height: "100%",
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                  }}
                                  // Revoke data uri after image is loaded
                                  onLoad={() => {
                                    URL.revokeObjectURL(files5[0].preview);
                                  }}
                                />
                              )}
                              <div
                                style={{
                                  textOverflow: "ellipsis",
                                  fontSize: ".7rem",
                                  textAlign: "center",
                                  width: "90%",
                                }}
                                onLoad={() => {
                                  URL.revokeObjectURL(files5[0].preview);
                                }}
                              >
                                {files5[0].preview}
                              </div>
                              {/* <input {...getInputProps()} /> */}
                            </div>
                          );
                        }
                      }}
                    </Dropzone>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "25%",
                    paddingRight: 8,
                    paddingTop: 8,
                  }}
                >
                  <div className={classes.text}>Side Image</div>
                  <div className={classes.pdfPicker}>
                    <Dropzone maxFiles={1} ref={dropzoneRef}>
                      {({ getRootProps, getInputProps, acceptedFiles }) => {
                        if (thumbnail3.length == 0) {
                          return (
                            <div className={classes.image}>
                              <div className={classes.text}>NO IMAGE</div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              // {...getRootProps({ style })}
                              className={classes.image}
                            >
                              <img
                                src={files3[0].preview}
                                style={{
                                  height: "100%",
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                }}
                                // Revoke data uri after image is loaded
                                onLoad={() => {
                                  URL.revokeObjectURL(files3[0].preview);
                                }}
                              />
                              {/* <input {...getInputProps()} /> */}
                            </div>
                          );
                        }
                      }}
                    </Dropzone>
                  </div>

                  <div className={classes.text}> Turn Table Video</div>
                  <div className={classes.pdfPicker}>
                    <Dropzone
                      maxFiles={1}
                      ref={dropzoneRef}
                      // onDrop={(file) => {
                      //   if (
                      //     (file[0].type != "image/jpeg" &&
                      //       file[0].type != "image/png") ||
                      //     file.length > 1
                      //   ) {
                      //     notify("error", "Please Upload only 1 Image");
                      //   } else {
                      //     console.log(file);
                      //     file.map((file) => {
                      //       Object.assign(file, {
                      //         preview: URL.createObjectURL(file),
                      //       });
                      //     });
                      //     console.log(file);
                      //     setFiles2(file);
                      //   }
                      // }}
                    >
                      {({ getRootProps, getInputProps, acceptedFiles }) => {
                        if (files6.length == 0) {
                          return (
                            // <div>
                            //   <div {...getRootProps({ style })}>
                            //     {/* <input {...getInputProps()} /> */}
                            //     <p> Back Image* </p>
                            //     <p>Drag 'n' Drop or Upload </p>
                            //   </div>
                            // </div>
                            <div className={classes.image}>
                              <div className={classes.text}>NO VIDEO</div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              // {...getRootProps({ style })}
                              className={classes.image}
                            >
                              <video height="100%" width="100%" controls>
                                <source
                                  src={files6[0].preview}
                                  type="video/mp4"
                                />
                              </video>
                              {/* <input {...getInputProps()} /> */}
                            </div>
                          );
                        }
                      }}
                    </Dropzone>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "25%",
                    paddingRight: 8,
                    paddingTop: 8,
                  }}
                >
                  <div className={classes.text}>Misc</div>

                  <div className={classes.pdfPicker}>
                    <Dropzone
                      maxFiles={1}
                      ref={dropzoneRef}
                      // onDrop={(file) => {
                      //   if (
                      //     (file[0].type != "image/jpeg" &&
                      //       file[0].type != "image/png") ||
                      //     file.length > 1
                      //   ) {
                      //     notify("error", "Please Upload only 1 Image");
                      //   } else {
                      //     console.log(file);
                      //     file.map((file) => {
                      //       Object.assign(file, {
                      //         preview: URL.createObjectURL(file),
                      //       });
                      //     });
                      //     console.log(file);
                      //     setFiles2(file);
                      //   }
                      // }}
                    >
                      {({ getRootProps, getInputProps, acceptedFiles }) => {
                        if (files4.length == 0) {
                          return (
                            // <div>
                            //   <div {...getRootProps({ style })}>
                            //     {/* <input {...getInputProps()} /> */}
                            //     <p> Back Image* </p>
                            //     <p>Drag 'n' Drop or Upload </p>
                            //   </div>
                            // </div>
                            <div className={classes.image}>
                              <div className={classes.text}>NO IMAGE</div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              // {...getRootProps({ style })}
                              className={classes.image}
                            >
                              <img
                                src={files4[0].preview}
                                style={{
                                  height: "100%",
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                }}
                                // Revoke data uri after image is loaded
                                onLoad={() => {
                                  URL.revokeObjectURL(files4[0].preview);
                                }}
                              />
                              {/* <input {...getInputProps()} /> */}
                            </div>
                          );
                        }
                      }}
                    </Dropzone>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ThemeContext.Provider>
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          style={{ position: "absolute" }}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </div>
    );
  }
}
