export default {
    primary: "#31639c",
    secondary: "#0090d4",
    background: "#E3EBF5",
    fontColor: "#000",
    white: "#fff",
    black: "#000",
    sec:"#1f3f64",
    redHK:"#f1243f",
    redHKTrans:"#f1243f88"
  };
  