import * as React from "react";
import { createContext } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#75B551",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },

    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#75B551",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles((theme) => ({
  ul: {
    padding: "25px",
    position: "absolute",
    top: "100px",
    width: "230px",
    margin: 0,
  },

  li: {
    listStyle: "none",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 10,
    marginBottom: 15,
    fontSize: "1.3rem",
    color: "#000000AA",
    alignItems: "center",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.02)",
    },
  },
}));
const variants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 0,
    opacity: 0,
  },
};

export const Context = createContext(null);
export const MenuItem = (props) => {
  const { theme, setTheme, isOpen, setLoginStatus, cookies, removeCookie } =
    props;
  const history = useHistory();
  const toggleTheme = () => {
    setTheme((curr) => (curr === "dark" ? "light" : "dark"));
  };
  const classes = useStyles();
  const logout = () => {
    removeCookie("username");
    removeCookie("password");
    window.location.href = "/";
    return false;
  };

  return (
    <>
      {isOpen ? (
        <motion.li variants={variants} className={classes.li}>
          <Context.Provider value={{ theme, toggleTheme }}>
            <FormControlLabel
              className="switch"
              control={
                <IOSSwitch
                  sx={{ m: 2 }}
                  onChange={toggleTheme}
                  checked={theme === "dark"}
                />
              }
              label=""
            />
            <div
              className={classes.listItem}
              onClick={logout}
            >
              <PowerSettingsNewIcon />
              <div style={{ marginLeft: 10 }}>Logout</div>
            </div>
            {/* <div
              className={classes.listItem}
              onClick={() => history.push("/newUsers")}
            >
              <div style={{ marginLeft: 10 }}>New Users</div>
            </div> */}
          </Context.Provider>
        </motion.li>
      ) : null}
    </>
  );
};
