import React, { useState, useEffect } from "react";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import "../stylesheets/FreelancerNT.css";
import ChatIcon from "@mui/icons-material/Chat";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Header from "../components/header";
import pptxgen from "pptxgenjs";
import MuiInput from "@mui/material/Input";
import { Checkbox, TextField } from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import { Redirect } from "react-router-dom";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { PDFDocument } from "pdf-lib";
import { saveAs } from "file-saver";
import Notification from "../components/Notification";

var w = window.innerWidth;
var h = window.innerHeight;
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0vw 2vw",
  },
  tableI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    border: "1.5px solid #00000033",
    // maxHeight:"80vh",
    marginTop: 10,
    overflow: "auto",
    overflowX: "hidden",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  tableSubI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    width: "1auto",
    maxHeight: "40vh",
    overflowY: "overlay",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  headerRow: {
    backgroundColor: "#FFFFFFAA",
    display: "flex",
    flexDirection: "row",
    padding: 10,
    borderBottom: "1.5px solid #00000055",
  },
  headerRowx: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    padding: "8px",
    borderBottom: "1.5px solid #00000055",
  },
  headerRowItem: {
    color: "#75B551",
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  headerRowItemx: {
    color: "#75B551",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    padding: "8px",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  tableRowSub: {
    display: "flex",
    flexDirection: "row",
    padding: "8px",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
  },

  tableCell: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.8vmin",
    color: "#000000AA",
  },

  tableCellx: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1rem",
    color: "#000000AA",
  },
  button: {
    padding: 6,
    background: "#75B551",
    color: "white",
    // fontSize: ".7rem",
    borderRadius: 5,
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  selectedFactory: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1rem",
    width: "30vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#75B551",
    borderRadius: 8,
    color: "#ffffff",
    cursor: "pointer",
  },
  unselectedFactory: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1rem",
    color: "#000000AA",
    width: "30vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));

const styles3PerRow = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    flexWrap: "wrap",
  },
  mainSection: {
    display: "flex",
    width: "33.2%",
    height: "47%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 10,
  },
  logoSection: {
    display: "flex",
    width: "100%",
    height: "5%",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  logo: {
    height: "100%",
    maxwidth: "9%",
    top: 10,
    marginRight: 10,
    position: "sticky",
    zIndex: 100,
  },
  section: {
    width: "100%",
    flexWrap: "wrap",
    maxHeight: "95%",
    objectFit: "contain",
    justifyContent: "center",
  },
  header: {
    fontSize: 10,
    textAlign: "left",
    color: "grey",
    fontFace: "Posterama Bold",
    indentLevel: 1,
    breakLine: true,
  },
  imgsection: {
    flexWrap: "wrap",
    objectFit: "contain",
    justifyContent: "center",
    // marginTop: "10px",
    width: "100%",
    height: "70%",
    padding: 5,
  },
  image: {
    width: "100%",
    maxHeight: "70%",
    // display: "flex",
    // justifyContent: "center",
  },
});

const RedCheckbox = withStyles({
  root: {
    color: "#75B551",
    width: 10,
    height: 10,
    "&$checked": {
      color: "#75B551",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function MasterTracker(props) {
  const {
    selectedDesign,
    setSelectedDesign,
    theme,
    setTheme,
    headerData,
    setHeaderData,
    isHeader,
    userID,
    setIsHeader,
    loginStatus,
    setLoginStatus,
    cookies,
    removeCookie,
  } = props;
  const classes = useStyles();
  const [enquiry, setEnquiry] = useState([]);
  const [enquiryDesign, setenquiryDesign] = useState([]);
  const [savedMargin, setSavedMargin] = useState([]);
  console.log(savedMargin, "SAVED MARGINS");
  console.log(enquiry, "enquiry");

  const notify = (type, message) => {
    if (type === "submit") {
      toast("Design has been Sent for Review");
    } else if (type == "error") {
      toast.error(message);
    } else if (type == "success") {
      toast.success(message, {
        iconTheme: {
          primary: "#0047AB",
          secondary: "#FFFAEE",
        },
      });
    } else if (type == "custom") {
      toast((t) => (
        <span>
          <b>Thanks Shilpa Polavarapu for submitting your awesome designs.</b>
          <br />
          <br />
          Our team is on the job of reviewing your designs and will share
          feedback soon. Check back here for updates. You will also receive a
          notification once the review is completed.
        </span>
      ));
    }
  };

  const getEnquiryData = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getMerchantDashboard", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result, "RESPONSE DATA");
        var temp = JSON.parse(JSON.stringify(result.result));

        temp.forEach((item, index) => {
          item.designs.forEach((item1, index1) => {
            item1.sellingPrice = 5;
            item1.isSelected = false;

            if (item1.costing.length > 0 && item1.costing[0].isSelected) {
              item1.costPrice = item1.costing[0].cost;
              if (
                savedMargin.some(
                  (value) => value.enquiryID === item.buyerEnquiryID
                )
              ) {
                removeByAttr(savedMargin, "enquiryID", item.buyerEnquiryID);
                savedMargin.push({
                  designID: item1.designID,
                  enquiryID: item1.enquiryID,
                  updatedMargin: item1.margin,
                  selectedCostingID: item1.costing[0].costingID,
                });
              } else {
                savedMargin.push({
                  designID: item1.designID,
                  enquiryID: item1.enquiryID,
                  updatedMargin: item1.margin,
                  selectedCostingID: item1.costing[0].costingID,
                });
              }
            }

            if (item1.costing.length > 1 && item1.costing[1].isSelected) {
              item1.costPrice = item1.costing[1].cost;
              if (
                savedMargin.some(
                  (value) => value.enquiryID === item.buyerEnquiryID
                )
              ) {
                removeByAttr(savedMargin, "enquiryID", item.buyerEnquiryID);
                savedMargin.push({
                  designID: item1.designID,
                  enquiryID: item1.enquiryID,
                  updatedMargin: item1.margin,
                  selectedCostingID: item1.costing[1].costingID,
                });
              } else {
                savedMargin.push({
                  designID: item1.designID,
                  enquiryID: item1.enquiryID,
                  updatedMargin: item1.margin,
                  selectedCostingID: item1.costing[1].costingID,
                });
              }
            }

            if (item1.costing.length > 2 && item1.costing[2].isSelected) {
              item1.costPrice = item1.costing[2].cost;
              if (
                savedMargin.some(
                  (value) => value.enquiryID === item.buyerEnquiryID
                )
              ) {
                removeByAttr(savedMargin, "enquiryID", item.buyerEnquiryID);
                savedMargin.push({
                  designID: item1.designID,
                  enquiryID: item1.enquiryID,
                  updatedMargin: item1.margin,
                  selectedCostingID: item1.costing[2].costingID,
                });
              } else {
                savedMargin.push({
                  designID: item1.designID,
                  enquiryID: item1.enquiryID,
                  updatedMargin: item1.margin,
                  selectedCostingID: item1.costing[2].costingID,
                });
              }
            }
          });
        });
        setEnquiry(temp);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const saveProducts = (enquiryID) => {
    const newMargins = savedMargin.filter(
      (item) => item.enquiryID === enquiryID
    );
    console.log(newMargins, "NEW MARGINS");
    fetch("https://studioeapi.bluekaktus.com/api/design/updateMargin", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        marginList: newMargins,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result, "RESPONSE DATA");
        if (result.Message == "Data Inserted") {
          notify("success", "Margins Updated");
        } else {
          notify("error", "An Error occured");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  const MyDoc = (item) => {
    return (
      <Document>
        <Page orientation="landscape" style={styles3PerRow.page}>
          <View style={styles3PerRow.logoSection} fixed>
            <Image
              style={styles3PerRow.logo}
              src="https://i.imgur.com/xPwZiRc.png"
            />
          </View>

          {item.item.designs.map((val) => {
            console.log(val.thumbnail3D);
            return (
              <View style={styles3PerRow.mainSection}>
                <View style={styles3PerRow.imgsection}>
                  <Image style={styles3PerRow.image} src={val.thumbnail3D} />
                </View>
                <View style={styles3PerRow.section}>
                  <Text style={styles3PerRow.header}>{val.designName}</Text>
                  <Text style={styles3PerRow.header}>{val.designCode}</Text>
                  <Text style={styles3PerRow.header}>{val.fabric}</Text>
                  <Text style={styles3PerRow.header}>{val.designDesc}</Text>
                </View>
              </View>
            );
          })}
        </Page>
      </Document>
    );
  };

  async function mergeBetweenPDF(pdfFileList) {
    const doc = await PDFDocument.create();

    for (const pdfFile of pdfFileList) {
      const initialPDF = await PDFDocument.load(pdfFile);
      const initialPDFPages = await doc.copyPages(
        initialPDF,
        initialPDF.getPageIndices()
      );

      for (const page of initialPDFPages) {
        doc.addPage(page);
      }
    }

    const base64 = await doc.saveAsBase64();

    const bufferArray = base64toBlob(base64, "application/pdf");

    const blob = new Blob([bufferArray], { type: "application/pdf" });

    saveAs(blob, `Appendix.pdf`);
  }
  function base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  useEffect(() => {
    if (loginStatus == 1) {
      getEnquiryData();
    }
  }, []);

  const removeByAttr = function (arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };

  const pptGen = (enqID) => {
    const filterEnq = enquiry.filter((item) => item.buyerEnquiryID === enqID);
    var temp = JSON.parse(JSON.stringify(filterEnq));
    let designIDS = [];
    temp.forEach((item, index) => {
      item.designs.forEach((item1, index1) => {
        if (item1.isSelected) {
          designIDS.push({ designID: item1.designID });
        }
      });
    });

    if (designIDS.length > 0) {
      //console.log(designIDS);
      fetch(
        "https://studioeapi.bluekaktus.com/api/design/multimediaGeneration",
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userID: userID,
            designList: designIDS,
            output: "PPT",
          }),
        }
      )
        .then((response) => {
          // const buffer = Buffer.from(response);

          // console.log(buffer);
          //console.log(response.arrayBuffer(), "RESPONSE DATA");
          return response.arrayBuffer();
        })
        .then((result) => {
          console.log(result, "RESULT");
          var blob = new Blob([result], {
            type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          });
          var url = URL.createObjectURL(blob);
          window.open(url);
        });
    } else {
      notify("error", "Please select design.");
    }
  };

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="bodyNT">
        <Toaster
          position="bottom-right"
          toastOptions={{
            // Define default options
            className: "",

            style: {
              color: " #FFFFFF",
              background: "#75B551DD",
            },
            success: {
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
            submit: {
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
            custom: {
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
            error: {
              duration: 2000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
          }}
        />
        <Notification
          theme={theme}
          setTheme={setTheme}
          setLoginStatus={setLoginStatus}
          style={{ position: "absolute" }}
          cookies={cookies}
          removeCookie={removeCookie}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div className={classes.wrapper} style={{ zIndex: 10 }}>
          <div className={classes.tableI}>
            <div className={classes.headerRow} style={{ width: "auto" }}>
              <div
                className={classes.headerRowItem}
                style={{ width: "25%", textAlign: "left" }}
              >
                Brand
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "25%", textAlign: "left" }}
              >
                Created On
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "10%", textAlign: "center" }}
              >
                Messages
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "10%", textAlign: "right" }}
              >
                Products
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "30%", textAlign: "right" }}
              >
                Actions
              </div>
            </div>
            {enquiry.map((item, index) => {
              let itemObj = { item, index };
              return (
                <>
                  <div
                    className={classes.tableRow}
                    onClick={() => {
                      var temp = JSON.parse(JSON.stringify(enquiry));
                      temp[index].open = !temp[index].open;
                      setEnquiry(temp);
                    }}
                  >
                    <div
                      className={classes.tableCell}
                      style={{ width: "25%", justifyContent: "left" }}
                    >
                      {item.buyer}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{ width: "25%", justifyContent: "left" }}
                    >
                      {item.createdOn}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{ width: "10%", justifyContent: "center" }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                          <ChatIcon
                            style={{
                              marginRight: "3vw",
                              marginTop: 8,
                              fontSize: "1.8vmin",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{ width: "10%", justifyContent: "right" }}
                    >
                      {item.designs.length}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{
                        width: "30%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        className={classes.button}
                        style={{
                          backgroundColor: "#ba0c2fDD",
                          marginRight: 10,
                        }}
                        onClick={() => {
                          pptGen(item.buyerEnquiryID);
                          // let appendixBlob = await (
                          //   await ReactPDF.pdf(<MyDoc item={item} />).toBlob()
                          // ).arrayBuffer();
                          // return mergeBetweenPDF([appendixBlob]);
                        }}
                      >
                        Generate PPT
                      </div>
                      <div
                        className={classes.button}
                        onClick={() => {
                          if (!e) var e = window.event;
                          e.cancelBubble = true;
                          if (e.stopPropagation) e.stopPropagation();
                          saveProducts(item.buyerEnquiryID);
                        }}
                      >
                        Save
                      </div>
                    </div>
                  </div>

                  <div className={classes.tableSubI}>
                    <Collapse in={item.open} timeout="auto">
                      {item && item.designs && item.designs.length > 0 && (
                        <div
                          className={classes.headerRowx}
                          style={{
                            position: "sticky",
                            top: 0,
                            flex: 1,
                            width: "193vw",
                            zIndex: 100,
                          }}
                        >
                          <div
                            className={classes.headerRowItemx}
                            style={{
                              textAlign: "center",
                              width: "3vw",
                              position: "sticky",
                              left: 0,
                              backgroundColor: "#FFFFFF",
                            }}
                          ></div>
                          <div
                            className={classes.headerRowItemx}
                            style={{
                              textAlign: "center",
                              width: "10vw",
                              position: "sticky",
                              left: "3vw",
                              backgroundColor: "#FFFFFF",
                            }}
                          >
                            Image
                          </div>
                          <div
                            className={classes.headerRowItemx}
                            style={{
                              textAlign: "center",
                              width: "10vw",
                              position: "sticky",
                              left: "13vw",
                              backgroundColor: "#FFFFFF",
                            }}
                          >
                            Product Code
                          </div>
                          {/* <div
                      className={classes.headerRowItemx}
                      style={{textAlign: "center"}}
                    >
                      Order No.
                    </div>
                    <div
                      className={classes.headerRowItemx}
                      style={{textAlign: "center"}}
                    >
                      Order City
                    </div> */}
                          <div
                            className={classes.headerRowItemx}
                            style={{ textAlign: "left", width: "10vw" }}
                          >
                            Category
                          </div>
                          <div
                            className={classes.headerRowItemx}
                            style={{ textAlign: "left", width: "10vw" }}
                          >
                            Fabric
                          </div>
                          <div
                            className={classes.headerRowItemx}
                            style={{ textAlign: "left", width: "10vw" }}
                          >
                            Delivery
                          </div>
                          <div
                            className={classes.headerRowItemx}
                            style={{ textAlign: "left", width: "10vw" }}
                          >
                            Del by
                          </div>
                          <div
                            className={classes.headerRowItemx}
                            style={{ textAlign: "left", width: "10vw" }}
                          >
                            1st offer
                          </div>
                          <div
                            className={classes.headerRowItemx}
                            style={{ textAlign: "left", width: "10vw" }}
                          >
                            2nd offer
                          </div>
                          <div
                            className={classes.headerRowItemx}
                            style={{ textAlign: "left", width: "10vw" }}
                          >
                            Final Price
                          </div>
                          <div
                            style={{
                              width: "30vw",
                              display: "flex",
                              flexDirection: "column",
                              color: "#75B551",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            <div style={{ textAlign: "center" }}>Factory 1</div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                Cost
                              </div>
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                MOQ
                              </div>
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                Lead Time
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "30vw",
                              display: "flex",
                              flexDirection: "column",
                              color: "#75B551",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            <div style={{ textAlign: "center" }}>Factory 2</div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                Cost
                              </div>
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                MOQ
                              </div>
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                Lead Time
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "30vw",
                              display: "flex",
                              flexDirection: "column",
                              color: "#75B551",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            <div style={{ textAlign: "center" }}>Factory 3</div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                Cost
                              </div>
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                MOQ
                              </div>
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                Lead Time
                              </div>
                            </div>
                          </div>
                          <div
                            className={classes.headerRowItemx}
                            style={{
                              textAlign: "right",
                              width: "5vw",
                              position: "sticky",
                              right: "5vw",
                              backgroundColor: "#FFFFFF",
                              paddingRight: 18,
                            }}
                          >
                            Selling Price
                          </div>
                          <div
                            className={classes.headerRowItemx}
                            style={{
                              textAlign: "right",
                              width: "5vw",
                              position: "sticky",
                              right: 0,
                              backgroundColor: "#FFFFFF",
                              paddingRight: 10,
                            }}
                          >
                            Margins
                          </div>
                        </div>
                      )}
                      {/* {item.row.map((item,index)=>{
                              return( */}
                      {item && item.designs && item.designs.length > 0 ? (
                        item.designs.map((val, ind) => {
                          return (
                            <div
                              onClick={() => {
                                if (!e) var e = window.event;
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                              }}
                              className={classes.tableRowSub}
                              style={{
                                position: "sticky",
                                width: "193vw",
                                backgroundColor: "#DDDDDD",
                              }}
                            >
                              <div
                                className={classes.tableCellx}
                                style={{
                                  textAlign: "center",
                                  width: "3vw",
                                  position: "sticky",
                                  left: 0,
                                  justifyContent: "center",
                                  backgroundColor: "#DDDDDD",
                                }}
                                onClick={() => {
                                  var temp = JSON.parse(
                                    JSON.stringify(enquiry)
                                  );
                                  temp[index].designs[ind].isSelected =
                                    !val.isSelected;
                                  setEnquiry(temp);
                                }}
                              >
                                <RedCheckbox checked={val.isSelected} />
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{
                                  textAlign: "center",
                                  width: "10vw",
                                  position: "sticky",
                                  left: "3vw",
                                  backgroundColor: "#DDDDDD",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={val.thumbnail3D}
                                  width="50"
                                  height="50"
                                />
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{
                                  textAlign: "center",
                                  width: "10vw",
                                  position: "sticky",
                                  left: "13vw",
                                  justifyContent: "center",
                                  backgroundColor: "#DDDDDD",
                                }}
                              >
                                {val.productCode}
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{ textAlign: "left", width: "10vw" }}
                              >
                                {val.category}
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{ textAlign: "left", width: "10vw" }}
                              >
                                {val.fabric}
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{ textAlign: "left", width: "10vw" }}
                              >
                                {/* {val.enquiryID} */}
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{ textAlign: "left", width: "10vw" }}
                              >
                                {/* {val.enquiryID} */}
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{ textAlign: "left", width: "10vw" }}
                              >
                                {/* {val.enquiryID} */}
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{ textAlign: "left", width: "10vw" }}
                              >
                                {/* {val.enquiryID} */}
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{ textAlign: "left", width: "10vw" }}
                              >
                                {/* {val.enquiryID} */}
                              </div>
                              <div
                                className={
                                  val.costing.length > 0 &&
                                  val.costing[0].isSelected
                                    ? classes.selectedFactory
                                    : classes.unselectedFactory
                                }
                                onClick={() => {
                                  if (
                                    val.costing.length > 0 &&
                                    val.costing[0].costingID
                                  ) {
                                    if (
                                      savedMargin.some(
                                        (value) =>
                                          value.enquiryID ===
                                          item.buyerEnquiryID
                                      )
                                    ) {
                                      removeByAttr(
                                        savedMargin,
                                        "enquiryID",
                                        item.buyerEnquiryID
                                      );
                                      var temp = JSON.parse(
                                        JSON.stringify(enquiry)
                                      );
                                      temp[index].designs[
                                        ind
                                      ].selectedCostingID =
                                        val.costing[0].costingID;
                                      temp[index].designs[ind].costPrice =
                                        val.costing[0].cost;
                                      temp[index].designs[
                                        ind
                                      ].costing[0].isSelected = true;
                                      if (
                                        val.costing.length > 1 &&
                                        val.costing[1].costingID
                                      ) {
                                        temp[index].designs[
                                          ind
                                        ].costing[1].isSelected = false;
                                      }
                                      if (
                                        val.costing.length > 2 &&
                                        val.costing[2].costingID
                                      ) {
                                        temp[index].designs[
                                          ind
                                        ].costing[2].isSelected = false;
                                      }
                                      setEnquiry(temp);
                                      savedMargin.push({
                                        designID: val.designID,
                                        enquiryID: val.enquiryID,
                                        updatedMargin: val.margin,
                                        selectedCostingID:
                                          val.costing[0].costingID,
                                      });
                                    } else {
                                      var temp = JSON.parse(
                                        JSON.stringify(enquiry)
                                      );
                                      temp[index].designs[
                                        ind
                                      ].selectedCostingID =
                                        val.costing[0].costingID;
                                      temp[index].designs[ind].costPrice =
                                        val.costing[0].cost;
                                      temp[index].designs[
                                        ind
                                      ].costing[0].isSelected = true;
                                      if (
                                        val.costing.length > 1 &&
                                        val.costing[1].costingID
                                      ) {
                                        temp[index].designs[
                                          ind
                                        ].costing[1].isSelected = false;
                                      }
                                      if (
                                        val.costing.length > 2 &&
                                        val.costing[2].costingID
                                      ) {
                                        temp[index].designs[
                                          ind
                                        ].costing[2].isSelected = false;
                                      }
                                      setEnquiry(temp);
                                      savedMargin.push({
                                        designID: val.designID,
                                        enquiryID: val.enquiryID,
                                        updatedMargin: val.margin,
                                        selectedCostingID:
                                          val.costing[0].costingID,
                                      });
                                    }
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    marginBottom: 10,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {val.costing.length > 0 &&
                                  val.costing[0].factoryName
                                    ? val.costing[0].factoryName
                                    : "-"}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {val.costing.length > 0 &&
                                    val.costing[0].cost
                                      ? val.costing[0].cost
                                      : "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {val.costing.length > 0 &&
                                    val.costing[0].moq
                                      ? val.costing[0].moq
                                      : "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {val.costing.length > 0 &&
                                    val.costing[0].leadTime
                                      ? val.costing[0].leadTime
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                              {/* <div
                              className={classes.tableCellx}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "30vw",
                              }}
                            >
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                {val.costing.length > 0 && val.costing[0].cost
                                  ? val.costing[0].cost
                                  : "-"}
                              </div>
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                {val.costing.length > 0 && val.costing[0].moq
                                  ? val.costing[0].moq
                                  : "-"}
                              </div>
                              <div
                                style={{ width: "33%", textAlign: "center" }}
                              >
                                {val.costing.length > 0 &&
                                val.costing[0].leadTime
                                  ? val.costing[0].leadTime
                                  : "-"}
                              </div>
                            </div> */}
                              <div
                                className={
                                  val.costing.length > 1 &&
                                  val.costing[1].isSelected
                                    ? classes.selectedFactory
                                    : classes.unselectedFactory
                                }
                                onClick={() => {
                                  if (
                                    val.costing.length > 1 &&
                                    val.costing[1].costingID
                                  ) {
                                    if (
                                      savedMargin.some(
                                        (value) =>
                                          value.enquiryID ===
                                          item.buyerEnquiryID
                                      )
                                    ) {
                                      removeByAttr(
                                        savedMargin,
                                        "enquiryID",
                                        item.buyerEnquiryID
                                      );
                                      var temp = JSON.parse(
                                        JSON.stringify(enquiry)
                                      );
                                      temp[index].designs[
                                        ind
                                      ].selectedCostingID =
                                        val.costing[1].costingID;
                                      temp[index].designs[ind].costPrice =
                                        val.costing[1].cost;
                                      temp[index].designs[
                                        ind
                                      ].costing[0].isSelected = false;
                                      temp[index].designs[
                                        ind
                                      ].costing[1].isSelected = true;
                                      if (
                                        val.costing.length > 2 &&
                                        val.costing[2].costingID
                                      ) {
                                        temp[index].designs[
                                          ind
                                        ].costing[2].isSelected = false;
                                      }
                                      setEnquiry(temp);
                                      savedMargin.push({
                                        designID: val.designID,
                                        enquiryID: val.enquiryID,
                                        updatedMargin: val.margin,
                                        selectedCostingID:
                                          val.costing[1].costingID,
                                      });
                                    } else {
                                      var temp = JSON.parse(
                                        JSON.stringify(enquiry)
                                      );
                                      temp[index].designs[
                                        ind
                                      ].selectedCostingID =
                                        val.costing[1].costingID;
                                      temp[index].designs[ind].costPrice =
                                        val.costing[1].cost;
                                      temp[index].designs[
                                        ind
                                      ].costing[0].isSelected = false;
                                      temp[index].designs[
                                        ind
                                      ].costing[1].isSelected = true;
                                      if (
                                        val.costing.length > 2 &&
                                        val.costing[2].costingID
                                      ) {
                                        temp[index].designs[
                                          ind
                                        ].costing[2].isSelected = false;
                                      }
                                      setEnquiry(temp);
                                      savedMargin.push({
                                        designID: val.designID,
                                        enquiryID: val.enquiryID,
                                        updatedMargin: val.margin,
                                        selectedCostingID:
                                          val.costing[1].costingID,
                                      });
                                    }
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    marginBottom: 10,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {val.costing.length > 1 &&
                                  val.costing[1].factoryName
                                    ? val.costing[1].factoryName
                                    : "-"}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {val.costing.length > 1 &&
                                    val.costing[1].cost
                                      ? val.costing[1].cost
                                      : "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {val.costing.length > 1 &&
                                    val.costing[1].moq
                                      ? val.costing[1].moq
                                      : "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {val.costing.length > 1 &&
                                    val.costing[1].leadTime
                                      ? val.costing[1].leadTime
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  val.costing.length > 2 &&
                                  val.costing[2].isSelected
                                    ? classes.selectedFactory
                                    : classes.unselectedFactory
                                }
                                onClick={() => {
                                  if (
                                    val.costing.length > 2 &&
                                    val.costing[2].costingID
                                  ) {
                                    if (
                                      savedMargin.some(
                                        (value) =>
                                          value.enquiryID ===
                                          item.buyerEnquiryID
                                      )
                                    ) {
                                      removeByAttr(
                                        savedMargin,
                                        "enquiryID",
                                        item.buyerEnquiryID
                                      );
                                      var temp = JSON.parse(
                                        JSON.stringify(enquiry)
                                      );
                                      temp[index].designs[
                                        ind
                                      ].selectedCostingID =
                                        val.costing[2].costingID;
                                      temp[index].designs[ind].costPrice =
                                        val.costing[2].cost;
                                      temp[index].designs[
                                        ind
                                      ].costing[0].isSelected = false;
                                      temp[index].designs[
                                        ind
                                      ].costing[1].isSelected = false;
                                      temp[index].designs[
                                        ind
                                      ].costing[2].isSelected = true;
                                      setEnquiry(temp);
                                      savedMargin.push({
                                        designID: val.designID,
                                        enquiryID: val.enquiryID,
                                        updatedMargin: val.margin,
                                        selectedCostingID:
                                          val.costing[2].costingID,
                                      });
                                    } else {
                                      var temp = JSON.parse(
                                        JSON.stringify(enquiry)
                                      );
                                      temp[index].designs[
                                        ind
                                      ].selectedCostingID =
                                        val.costing[2].costingID;
                                      temp[index].designs[ind].costPrice =
                                        val.costing[2].cost;
                                      temp[index].designs[
                                        ind
                                      ].costing[0].isSelected = false;
                                      temp[index].designs[
                                        ind
                                      ].costing[1].isSelected = false;
                                      temp[index].designs[
                                        ind
                                      ].costing[2].isSelected = true;
                                      setEnquiry(temp);
                                      savedMargin.push({
                                        designID: val.designID,
                                        enquiryID: val.enquiryID,
                                        updatedMargin: val.margin,
                                        selectedCostingID:
                                          val.costing[2].costingID,
                                      });
                                    }
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    marginBottom: 10,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {val.costing.length > 2 &&
                                  val.costing[2].factoryName
                                    ? val.costing[2].factoryName
                                    : "-"}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {val.costing.length > 2 &&
                                    val.costing[2].cost
                                      ? val.costing[2].cost
                                      : "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {val.costing.length > 2 &&
                                    val.costing[2].moq
                                      ? val.costing[2].moq
                                      : "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {val.costing.length > 2 &&
                                    val.costing[2].leadTime
                                      ? val.costing[2].leadTime
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "Center",
                                  width: "5vw",
                                  position: "sticky",
                                  right: "5vw",
                                  paddingRight: 18,
                                  backgroundColor: "#DDDDDD",
                                  alignItems: "flex-end",
                                }}
                              >
                                <TextField
                                  margin="dense"
                                  placeholder="Selling Price"
                                  inputProps={{
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      margin: 0,
                                      padding: 0,
                                      width: "5vw",
                                      color:
                                        val.margin > 15
                                          ? "#49b675"
                                          : val.margin > 13 && val.margin <= 15
                                          ? "#75B551"
                                          : val.margin > 10 && val.margin <= 13
                                          ? "#fc9303"
                                          : "#ba0c2f",
                                    },
                                  }}
                                  className={classes.input}
                                  InputProps={{ disableUnderline: true }}
                                  value={val.sellingPrice}
                                  onChange={(e) => {
                                    if (val.selectedCostingID !== 0) {
                                      var rgx = /^[0-9]*\.?[0-9]*$/;
                                      if (e.target.value.match(rgx)) {
                                        var temp = JSON.parse(
                                          JSON.stringify(enquiry)
                                        );
                                        var temp1 = JSON.parse(
                                          JSON.stringify(savedMargin)
                                        );
                                        //console.log(res, parseFloat(res));
                                        if (e.target.value == "") {
                                          temp[index].designs[ind].margin = 0;
                                          temp[index].designs[
                                            ind
                                          ].sellingPrice = "";
                                          temp1.forEach((item1, index1) => {
                                            if (
                                              item1.enquiryID ===
                                              temp[index].designs[ind].enquiryID
                                            ) {
                                              item1.updatedMargin = 0;
                                            }
                                          });
                                          setEnquiry(temp);
                                          setSavedMargin(temp1);
                                        } else {
                                          temp[index].designs[ind].margin =
                                            e.target.value;
                                          temp[index].designs[ind].margin = (
                                            ((Number(e.target.value) -
                                              parseFloat(val.costPrice)) *
                                              100) /
                                            val.costPrice
                                          ).toFixed(2);
                                          temp[index].designs[
                                            ind
                                          ].sellingPrice = e.target.value;
                                          temp1.forEach((item1, index1) => {
                                            if (
                                              item1.enquiryID ===
                                              temp[index].designs[ind].enquiryID
                                            ) {
                                              item1.updatedMargin = (
                                                ((Number(e.target.value) -
                                                  parseFloat(val.costPrice)) *
                                                  100) /
                                                val.costPrice
                                              ).toFixed(2);
                                            }
                                          });
                                          setEnquiry(temp);
                                          setSavedMargin(temp1);
                                        }
                                      }
                                    } else {
                                      notify("error", "Please select factory.");
                                    }
                                  }}
                                />
                                {/* <MuiInput
                                  disableUnderline={true}
                                  value={String(val.sellingPrice)}
                                  size="small"
                                  inputProps={{
                                    style: {
                                      direction: "rtl",
                                      textAlign: "right",
                                      width: "5vw",
                                      color:
                                        val.margin > 15
                                          ? "#49b675"
                                          : val.margin > 13 && val.margin <= 15
                                          ? "#75B551"
                                          : val.margin > 10 && val.margin <= 13
                                          ? "#fc9303"
                                          : "#ba0c2f",
                                    },
                                  }}
                                  // onChange={(e) => {
                                  //   if (val.selectedCostingID !== 0) {
                                  //     var temp = JSON.parse(
                                  //       JSON.stringify(enquiry)
                                  //     );
                                  //     var temp1 = JSON.parse(
                                  //       JSON.stringify(savedMargin)
                                  //     );
                                  //     console.log(temp, "TEMP");
                                  //     if (e.target.value == "") {
                                  //       temp[index].designs[ind].margin = 0;
                                  //       temp1.forEach((item1, index1) => {
                                  //         if (
                                  //           item1.enquiryID ===
                                  //           temp[index].designs[ind].enquiryID
                                  //         ) {
                                  //           item1.updatedMargin = 0;
                                  //         }
                                  //       });
                                  //     } else if (
                                  //       parseInt(e.target.value) > 100
                                  //     ) {
                                  //       temp[index].designs[ind].margin = 100;
                                  //       temp1.forEach((item1, index1) => {
                                  //         if (
                                  //           item1.enquiryID ===
                                  //           temp[index].designs[ind].enquiryID
                                  //         ) {
                                  //           item1.updatedMargin = 100;
                                  //         }
                                  //       });
                                  //     } else {
                                  //       temp[index].designs[ind].margin =
                                  //         parseInt(e.target.value);
                                  //       temp1.forEach((item1, index1) => {
                                  //         if (
                                  //           item1.enquiryID ===
                                  //           temp[index].designs[ind].enquiryID
                                  //         ) {
                                  //           item1.updatedMargin = parseInt(
                                  //             e.target.value
                                  //           );
                                  //         }
                                  //       });
                                  //     }
                                  //     setEnquiry(temp);
                                  //     setSavedMargin(temp1);
                                  //   } else {
                                  //     notify("error", "Please select factory.");
                                  //   }
                                  // }}
                                /> */}
                              </div>
                              <div
                                className={classes.tableCellx}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "Center",
                                  width: "5vw",
                                  position: "sticky",
                                  right: 0,
                                  paddingRight: 10,
                                  backgroundColor: "#DDDDDD",
                                  alignItems: "flex-end",
                                }}
                              >
                                {/* <MuiInput
                                  disableUnderline={true}
                                  value={String(val.margin)}
                                  size="small"
                                  inputProps={{
                                    style: {
                                      direction: "rtl",
                                      textAlign: "right",
                                      width: "5vw",
                                      color:
                                        val.margin > 15
                                          ? "#49b675"
                                          : val.margin > 13 && val.margin <= 15
                                          ? "#75B551"
                                          : val.margin > 10 && val.margin <= 13
                                          ? "#fc9303"
                                          : "#ba0c2f",
                                    },
                                  }}
                                  onChange={(e) => {
                                    if (val.selectedCostingID !== 0) {
                                      var temp = JSON.parse(
                                        JSON.stringify(enquiry)
                                      );
                                      var temp1 = JSON.parse(
                                        JSON.stringify(savedMargin)
                                      );
                                      console.log(temp, "TEMP");
                                      if (e.target.value == "") {
                                        temp[index].designs[ind].margin = 0;
                                        temp1.forEach((item1, index1) => {
                                          if (
                                            item1.enquiryID ===
                                            temp[index].designs[ind].enquiryID
                                          ) {
                                            item1.updatedMargin = 0;
                                          }
                                        });
                                      } else if (
                                        parseInt(e.target.value) > 100
                                      ) {
                                        temp[index].designs[ind].margin = 100;
                                        temp1.forEach((item1, index1) => {
                                          if (
                                            item1.enquiryID ===
                                            temp[index].designs[ind].enquiryID
                                          ) {
                                            item1.updatedMargin = 100;
                                          }
                                        });
                                      } else {
                                        temp[index].designs[ind].margin =
                                          parseInt(e.target.value);
                                        temp1.forEach((item1, index1) => {
                                          if (
                                            item1.enquiryID ===
                                            temp[index].designs[ind].enquiryID
                                          ) {
                                            item1.updatedMargin = parseInt(
                                              e.target.value
                                            );
                                          }
                                        });
                                      }
                                      setEnquiry(temp);
                                      setSavedMargin(temp1);
                                    } else {
                                      notify("error", "Please select factory.");
                                    }
                                  }}
                                /> */}
                                <TextField
                                  margin="dense"
                                  placeholder="Margin"
                                  inputProps={{
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      margin: 0,
                                      padding: 0,
                                      width: "5vw",
                                      color:
                                        val.margin > 15
                                          ? "#49b675"
                                          : val.margin > 13 && val.margin <= 15
                                          ? "#75B551"
                                          : val.margin > 10 && val.margin <= 13
                                          ? "#fc9303"
                                          : "#ba0c2f",
                                    },
                                  }}
                                  className={classes.input}
                                  InputProps={{ disableUnderline: true }}
                                  value={val.margin}
                                  onChange={(e) => {
                                    if (val.selectedCostingID !== 0) {
                                      var rgx = /^[0-9]*\.?[0-9]*$/;
                                      if (e.target.value.match(rgx)) {
                                        var temp = JSON.parse(
                                          JSON.stringify(enquiry)
                                        );
                                        var temp1 = JSON.parse(
                                          JSON.stringify(savedMargin)
                                        );
                                        //console.log(res, parseFloat(res));
                                        if (e.target.value == "") {
                                          temp[index].designs[ind].margin = "";
                                          temp[index].designs[
                                            ind
                                          ].sellingPrice = 0;
                                          temp1.forEach((item1, index1) => {
                                            if (
                                              item1.enquiryID ===
                                              temp[index].designs[ind].enquiryID
                                            ) {
                                              item1.updatedMargin = 0;
                                            }
                                          });

                                          setEnquiry(temp);
                                          setSavedMargin(temp1);
                                        } else if (
                                          parseInt(e.target.value) > 100
                                        ) {
                                          temp[index].designs[ind].margin = 100;
                                          temp[index].designs[
                                            ind
                                          ].sellingPrice = (
                                            val.costPrice +
                                            (val.costPrice * 100) / 100
                                          ).toFixed(2);
                                          temp1.forEach((item1, index1) => {
                                            if (
                                              item1.enquiryID ===
                                              temp[index].designs[ind].enquiryID
                                            ) {
                                              item1.updatedMargin = 100;
                                            }
                                          });
                                          setEnquiry(temp);
                                          setSavedMargin(temp1);
                                        } else {
                                          temp[index].designs[ind].margin =
                                            e.target.value;

                                          temp[index].designs[
                                            ind
                                          ].sellingPrice = (
                                            val.costPrice +
                                            (val.costPrice *
                                              Number(e.target.value)) /
                                              100
                                          ).toFixed(2);
                                          temp1.forEach((item1, index1) => {
                                            if (
                                              item1.enquiryID ===
                                              temp[index].designs[ind].enquiryID
                                            ) {
                                              item1.updatedMargin =
                                                e.target.value;
                                            }
                                          });
                                          setEnquiry(temp);
                                          setSavedMargin(temp1);
                                        }
                                      }
                                    } else {
                                      notify("error", "Please select factory.");
                                    }
                                  }}
                                />
                                {!val.marginApproved &&
                                val.margin != 0 &&
                                val.margin < 5 ? (
                                  <span
                                    style={{
                                      marginTop: 3,
                                      backgroundColor: "#da2525",
                                      padding: 4,
                                      fontSize: "0.6rem",
                                      color: "#FFFFFF",
                                      borderRadius: 5,
                                    }}
                                  >
                                    Approval Pending
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div align="center">No Designs Available</div>
                      )}

                      {/* )
                                  })}    */}
                      <div></div>
                    </Collapse>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
