import React, { useState, useEffect } from "react";
import { makeStyles, withStyles, TextField } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "../stylesheets/login.css";
import CancelIcon from '@mui/icons-material/Cancel';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    alignItems: "flex-start",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "4vh 0vw",
    height: "100vh",
    justifyContent: "flex-start",
  },
  horizontal: {
    display: "flex",
    flexDirection: "horizontal",
    marginLeft: "2vw",
    color: "1e4b30DD"
  },
  input: {
    fontSize: 25,
    margin: 2,
    "& .MuiOutlinedInput-input": {
      padding: 3,
      width: "4vw",
    }
  },
  table: {
    border: "solid 1px black",
    marginLeft: 45,
    marginTop: 20,
  }
}));

const CustomTextField = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#75B551",
    },

    "& .MuiInput-underline:before": {
      borderBottomColor: " #75B551",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderBottomColor: "#75B551",
      },
      "&:hover fieldset": {
        borderColor: "#75B551",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#75B551",
      },
    },
  },
})(TextField);

export default function Login(props) {
  const {
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [newSize, setNewSize] = useState("")
  const [newSizeQty, setNewSizeQty] = useState(0)
  const [machines, setMachines] = useState(8);
  const [capacity, setCapacity] = useState(100);
  const [days, setDays] = useState([]);
  const [sizes, setSizes] = useState([{ id: 1, size: "S-7", qty: 1400 }, { id: 2, size: "S-8", qty: 1000 }, { id: 3, size: "S-9", qty: 2000 }, { id: 4, size: "S-10", qty: 1000 }]);
  const [planning, setPlanning] = useState([]);

  const generatePlan = () => {

    let total = 0
    let days = 0
    let sizeTemp = JSON.parse(JSON.stringify(sizes))
    let tempPlan = []
    sizes.forEach((item) => {
      total = total + Number(item.qty)
    })
    days = Math.ceil(total / (machines * capacity))
    console.log(days, total / (machines * capacity))

    // sizeTemp.forEach((item, index) => {
    //   if (item.qty % (capacity * days) == 0) {
    //     item.divisibilityflag = true
    //   }
    //   else {
    //     item.divisibilityflag = false
    //   }
    // })

    // let divisibleVals = []
    // let nonDivisibleVals = []
    // sizeTemp.forEach((item) => {
    //   if (item.divisibilityflag) {
    //     divisibleVals.push(item)
    //   } else {
    //     nonDivisibleVals.push(item)
    //   }
    // })

    // divisibleVals.sort((a, b) => b.qty - a.qty);
    // nonDivisibleVals.sort((a, b) => b.qty - a.qty);

    // sizeTemp = divisibleVals.concat(nonDivisibleVals)
    // console.log(machines, tempPlan, sizeTemp)
    // for (let i = 1; i < Number(machines) + 1; i++) {
    //   let skipflag = false

    //   tempPlan.push({ machine: "M-" + i, details: [], id: i })

    //   sizeTemp.forEach((item) => {
    //     if (item.divisibilityflag && item.qty > 0) {
    //       for (let j = 0; j < days; j++) {
    //         if (tempPlan[i - 1].details.length < days) {
    //           tempPlan[i - 1].details.push({ day: j, size: item.size, qty: capacity })
    //           item.qty = item.qty - capacity
    //         }
    //       }
    //       skipflag = true
    //     } else {
    //       if (item.qty >= days * capacity && !skipflag) {
    //         for (let j = 0; j < days; j++) {
    //           if (tempPlan[i - 1].details.length < days) {
    //             tempPlan[i - 1].details.push({ day: j, size: item.size, qty: capacity })
    //             item.qty = item.qty - capacity
    //           }
    //         }
    //         skipflag = true
    //       }
    //       else if (item.qty <= days * capacity && item.qty > 0 && !skipflag) {
    //         for (let j = 0; j < days; j++) {
    //           // console.log(sizeTemp)
    //           // console.log(tempPlan[i - 1].details.length)
    //           if (tempPlan[i - 1].details.length < days && item.qty > 0) {
    //             tempPlan[i - 1].details.push({ day: j, size: item.size, qty: capacity })
    //             item.qty = item.qty - capacity
    //           }
    //         }
    //       }
    //     }
    //   })
    //   sizeTemp.sort((a, b) => b.qty - a.qty);
    // }
    for (let i = 1; i < Number(machines) + 1; i++) tempPlan.push({ machine: "M-" + i, details: [], id: i })

    let sizesLeft = []
    let machineindex = 0;
    sizeTemp.forEach((ele) => {

      console.log(ele)
      while (ele.qty >= days * capacity) {
        console.log(ele)
        let tmpDetails = []
        for (let i = 0; i < days; i++) tmpDetails.push({ day: i, size: ele.size, qty: capacity })
        tempPlan[machineindex].details = tmpDetails
        machineindex++
        ele.qty -= days * capacity
      }
      if (ele.qty > 0) sizesLeft.push({ ...ele, daysrequired: Math.ceil(Number(ele.qty) / capacity) })
    })
    sizesLeft.sort((a, b) => { return b.qty - a.qty })
    console.log(sizesLeft)
    for (let i = 0; i < sizesLeft.length; i++) {
      if (machineindex < machines) {
        console.log(tempPlan[machineindex].details.length, sizesLeft[i].daysrequired)
        if (days - tempPlan[machineindex].details.length >= sizesLeft[i].daysrequired) {
          while (sizesLeft[i].qty > 0) {
            console.log(sizesLeft[i].qty, capacity, capacity > sizesLeft.qty ? sizesLeft.qty : capacity)
            tempPlan[machineindex].details.push({ day: i, size: sizesLeft[i].size, qty: Number(capacity) > Number(sizesLeft[i].qty) ? sizesLeft[i].qty : capacity })
            sizesLeft[i].qty -= capacity
          }
          machineindex++;
        }
      }
      else {
        for (let j = 0; j < machines; j++) {
          if (days - tempPlan[j].details.length >= sizesLeft[i].daysrequired) {
            while (sizesLeft[i].qty > 0) {
              tempPlan[j].details.push({ day: i, size: sizesLeft[i].size, qty: Number(capacity) > Number(sizesLeft[i].qty) ? sizesLeft[i].qty : capacity })
              sizesLeft[i].qty -= capacity
            }
          }
        }
        if (sizesLeft[i].qty > 0) {
          for (let j = 0; j < machines; j++) {
            while (sizesLeft[i].qty > 0 && days - tempPlan[j].details.length > 0) {
              tempPlan[j].details.push({ day: i, size: sizesLeft[i].size, qty: Number(capacity) > Number(sizesLeft[i].qty) ? sizesLeft[i].qty : capacity })
              sizesLeft[i].qty -= capacity
            }

          }
        }
      }
    }

    let temp = []
    for (let j = 0; j < days; j++) {
      temp.push(j)
    }
    console.log(tempPlan)
    setPlanning(tempPlan)
    setDays(temp)
  }





  // console.log(planning)

  return (
    <div className="bodyLogin">
      <Toaster
        position="bottom-right"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            color: "#FFFFFF",
            background: "#49b675AA",
          },
        }}
      />
      <div className={classes.wrapper}>
        <div className={classes.horizontal} style={{ fontSize: 40 }}>
          <div>Production Planning</div>
        </div>
        <div className={classes.horizontal} style={{ justifyContent: "flex-end", width: "85vw" }}>
          <div style={{ display: "flex", flexDirection: "horizontal", alignItems: "center", marginRight: 25 }} >
            <div style={{ marginRight: 5, fontSize: 20 }}>
              Machines :
            </div>
            <div style={{ marginRight: 5, fontSize: 30 }}>
              <TextField
                variant="outlined"
                type="number"
                value={machines}
                onChange={(e) => setMachines(e.target.value)}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "horizontal", alignItems: "center" }}>
            <div style={{ marginRight: 5, fontSize: 20 }}>
              Production Capacity :
            </div>
            <div style={{ marginRight: 5, fontSize: 30 }}>

              <TextField
                variant="outlined"
                type="number"
                value={capacity}
                onChange={(e) => setCapacity(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* <div className={classes.horizontal} style={{ width: "85vw" }}>
          
        </div> */}
        <div className={classes.horizontal} style={{ width: "85vw" }}>
          <div style={{ display: "flex", flexDirection: "column", margin: 10, padding: 5, justifyContent: "center", alignItem: "center" }}>
            <div style={{ border: "solid 1px black", padding: 10, marginTop: 10, borderRadius: 10 }}>
              <div style={{ fontSize: 22 }}>{"Size"}</div>
              <div style={{ fontSize: 25 }}>{"Quantity"}</div>
            </div>
          </div>
          {sizes.map((item, index) => (
            <div style={{ display: "flex", flexDirection: "column", margin: 10, padding: 5, justifyContent: "center", alignItem: "center" }}>
              <div style={{ alignSelf: "end", zindex: 1, marginBottom: -15, marginRight: -10 }}><CancelIcon onClick={() => setSizes(sizes.filter((ele) => ele.id != item.id))}></CancelIcon></div>
              <div style={{ border: "solid 1px black", padding: 10, borderRadius: 10 }}>
                <div style={{ fontSize: 22 }}>{item.size}</div>
                <div style={{ fontSize: 25 }}>{item.qty}</div>
              </div>
            </div>
          ))}
          <div style={{ display: "flex", flexDirection: "column", margin: 10, padding: 5, justifyContent: "center", alignItem: "center" }}>
            <div style={{ alignSelf: "end", zindex: 1, marginBottom: -15, marginRight: -10 }}>
              <LocalHospitalIcon
                onClick={() => {
                  let index = 0;
                  sizes.forEach((ele) => index = ele.id >= index ? ele.id + 1 : index)
                  let temp = {
                    id: index,
                    size: newSize,
                    qty: newSizeQty,
                  }
                  setSizes([...sizes, temp])

                }

                }>
              </LocalHospitalIcon></div>
            <div style={{ border: "solid 1px black", padding: 10, borderRadius: 10 }}>
              <div style={{ fontSize: 22 }}> <TextField
                variant="outlined"
                type="text"
                className={classes.input}
                value={newSize}
                onChange={(e) => setNewSize(e.target.value)}
              /></div>
              <div style={{ fontSize: 25 }}> <TextField
                variant="outlined"
                type="text"
                className={classes.input}
                value={newSizeQty}
                onChange={(e) => setNewSizeQty(e.target.value)}
              /></div>
            </div>
          </div>
        </div>
        <div className={classes.horizontal} style={{ width: "85vw" }}>
          <div style={{ backgroundColor: "blue", color: "white", padding: "8px 20px", borderRadius: 10, fontSize: 20 }} onClick={() => { generatePlan() }}>
            Begin Planning
          </div>
        </div>
        {/* <div className={classes.table}>
          <div style={{ display: "flex", flexDirection: "row", paddingLeft: 25, backgroundColor: "#130055ee", color: "#ffffffcc", fontWeight: 800 }}>
            <div style={{ fontSize: 30, margin: 10, padding: 5, width: "10vw" }}>Day</div>
            {sizes.map((item, index) => (
              <div style={{ justifyContent: "center", alignItem: "center", margin: 10, padding: 5, width: "10vw", textAlign: "center" }}>
                <div style={{ fontSize: 30 }}>{item.size}</div>
              </div>
            ))}
          </div>
          {planning.map((item, index) => (
            <div style={{ display: "flex", flexDirection: "row", paddingLeft: 25, backgroundColor: "#ffffffcc", color: "#000000cc" }}>
              <div style={{ fontSize: 30, margin: 10, padding: 5, width: "10vw", fontWeight: 800, color: "#130055ee" }}>{"Day - " + item.day}</div>
              {item.sizes.map((item, index) => (
                <div style={{ justifyContent: "center", alignItem: "center", margin: 10, padding: 5, width: "10vw", textAlign: "center", }}>
                  <div style={{ fontSize: 30 }}>{item.qty}</div>
                </div>
              ))}
            </div>
          ))}
        </div> */}
        <div style={{ display: "flex", flexDirection: "row", margin: 10, padding: 10 }}>
          <div style={{ width: "10vw", display: "flex", flexDirection: "column", fontSize: 25 }}>
            <div style={{ fontSize: 30, fontWeight: "bold" }}>Day</div>
            {days.map((item, index) => (
              <div style={{ marginTop: 10 }}>{"Day-" + item}</div>
            ))}
          </div>
          {planning.map((item, index) => (
            <div style={{ width: "10vw", display: "flex", flexDirection: "column", fontSize: 25 }}>
              <div style={{ fontSize: 30, fontWeight: "bold" }}>{item.machine}</div>
              {item.details.map((item2, index) => (
                <div style={{ marginTop: 10 }}>{item2.size + "-" + item2.qty}</div>
              ))}
            </div>
          ))}

        </div>
      </div>
    </div >
  );
}
