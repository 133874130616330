import React, { useState, useEffect, useMemo, memo } from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Button,
  Checkbox,
  Slider,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Link, useHistory, Redirect, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "../stylesheets/FreelancerNT.css";
import Header from "../components/header";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import colors from "../config/colors";
import "../stylesheets/gridImages.css";

var w = window.innerWidth;
var h = window.innerHeight;

const AirbnbSlider = withStyles({
  root: {
    color: "#3e7cc2AA",
    height: 3,
    width: "80%",
    padding: "13px 0",
  },
  thumb: {
    height: 25,
    minWidth: 25,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },
})(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span style={{ fontSize: ".6rem" }}>{props["aria-valuenow"]}</span>
    </span>
  );
}

const RedCheckbox = withStyles({
  root: {
    color: "#75B551",
    width: 10,
    height: 10,
    "&$checked": {
      color: "#49b675",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  colorsStyle: {
    cursor: "pointer",
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .1s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 40px",
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "15px",
  },
  leftFilters: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
  },
  container: {
    display: "flex",
    flex: 1,
    marginLeft: "2rem !important",
    marginRight: "2rem !important",
    justifyContent: "flex-start!important",
  },
  rightCatalog: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 12.2vw);",
    rowGap: "10px",
    columnGap: "10px",
    // flexDirection: "row",
    // flexWrap: "auto",
    // flexWrap: "wrap",
    overflowX: "auto",
    margin: 10,
    overflowY: "hidden",
  },
  productDescriptor: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    webkitBackdropFilter: "blur(8px)" /* Safari 9+ */,
    backdropFilter: "blur(8px)" /* Chrome and Opera */,
    boxShadow: "inset 0 0 0 200px rgba(255,255,255,0.08)",
    zIndex: 100,
    display: "none",
    padding: 12,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },

  filterContainer: {
    width: "35vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    // borderRadius: "1rem",
    // boxShadow: "0.2rem 0.2rem .4rem #c8d0e7, -0.3rem -0.3rem .5rem #FFFFFF",
    // padding: "1rem",
    margin: "1rem",
  },
}));

const notify = (type, message) => {
  if (type === "submit") {
    toast("Design has been Sent for Review");
  } else if (type == "error") {
    toast.error(message);
  } else if (type == "success") {
    toast.success(message, {
      iconTheme: {
        primary: "#0047AB",
        secondary: "#FFFAEE",
      },
    });
  } else if (type == "custom") {
  }
};
export default function DesignDetails(props) {
  const {
    selectedDesign,
    setSelectedDesign,
    theme,
    setTheme,
    headerData,
    setHeaderData,
    isHeader,
    userID,
    setIsHeader,
    loginStatus,
    designID,
    setDesignID,
    setCartItems,
    cartItems,
  } = props;

  const classes = useStyles();
  const [productImages, setProductImages] = useState({});
  const [designDetails, setDesignDetails] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [selectedProdDetail, setSelectedProdDetail] = useState({});
  const history = useHistory();
  const location = useLocation();

  const product = location.state.product;
  const locationCartItems = location.state.cartItems;

  const bottomDescriptor = {
    open: { display: "block", opacity: 1, y: 0, height: "auto" },
    closed: { display: "none", opacity: 0, y: 0, height: "0" },
  };
  
  const getDesignInfo = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/get3DPartnerView", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        designID: designID,
        userID: userID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        const temp = JSON.parse(JSON.stringify(result.renderView[0]));
        temp.frontView = product.thumbNail3D;
        temp.backView = product.thumbNailBack3D;
        temp.sideView = product.thumbNailSide3D;
        temp.misc2 = product.thumbNailMisc3D;
        setProductImages(temp);
      }).catch((error) => {
        console.error("An error occurred:", error);
        // toast.error(error);
      });

    fetch("https://studioeapi.bluekaktus.com/api/design/getDesignInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: designID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setDesignDetails(result.designDetails[0]);
      }).catch((error) => {
        console.error("An error occurred:", error);
        // toast.error(error);
      });
  };

  useEffect(() => {
    // const prodDetails=location.state.products.filter((item)=>item.designID===designID);
    if (loginStatus == 1) {
      setSelectedProdDetail(product);
      setCartItems(cartItems);
      getDesignInfo();
    }
  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={theme == "light" ? "bodyNT" : "body"}>
        <Toaster
          position="bottom-center"
          toastOptions={{
            className: "",
            duration: 4000,
            style: {
              color: " #FFFFFF",
              background: "#75B551DD",
            },
          }}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div>
          <div className={classes.container}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className={classes.rightCatalog}
            >
              {productImages.frontView !== "NO IMAGE" ? (
                <motion.div className="item_image_1">
                  <img
                    src={productImages.frontView}
                    style={{ width: "100%" }}
                  />
                </motion.div>
              ) : (
                <motion.div className="item_image_1">
                  <div>NO IMAGE</div>
                </motion.div>
              )}

              {productImages.backView !== "NO IMAGE" && (
                <motion.div className="item_image_2">
                  <img src={productImages.backView} style={{ width: "100%" }} />
                </motion.div>
              )}

              {productImages.sideView !== "NO IMAGE" && (
                <motion.div className="item_image_3">
                  <img src={productImages.sideView} style={{ width: "100%" }} />{" "}
                </motion.div>
              )}

              {productImages.misc2 !== "NO IMAGE" && (
                <motion.div className="item_image_2">
                  <img src={productImages.misc2} style={{ width: "100%" }} />{" "}
                </motion.div>
              )}

              {productImages.misc2 !== "NO IMAGE" && (
                <motion.div className="item_image_3">
                  {" "}
                  <img src={productImages.misc2} style={{ width: "100%" }} />
                </motion.div>
              )}
            </motion.div>

            {Object.keys(selectedProdDetail).length !== 0 && (
              <div className={classes.filterContainer}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: "2.5rem",
                      fontWeight: 400,
                      lineHeight: 1.2,
                      letterSpacing: "0.007em",
                      color: "#000000",
                    }}
                  >
                    {designDetails.designName}
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 45,
                      borderRadius: 40,
                      backgroundColor: "#FFFFFFAA",
                      justifyContent: "center",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    onClick={(e) => {
                      let temp = JSON.parse(JSON.stringify(selectedProdDetail));
                      if (!temp.isSelected) {
                        temp.selectedInEnquiry = !temp.selectedInEnquiry;
                        console.log(temp, "temp1");
                        setSelectedProdDetail(temp);
                        let temp2 = JSON.parse(JSON.stringify(cartItems));
                        if (
                          temp2.filter((e) => e.designID === designID).length >
                          0
                        ) {
                          temp2.splice(
                            temp2.findIndex((e) => e.designID === designID),
                            1
                          );
                        } else {
                          temp2.push(selectedProdDetail);
                        }
                        setCartItems(temp2);
                      } else if (temp.isSelected && temp.selectedInEnquiry) {
                        temp.selectedInEnquiry = !temp.selectedInEnquiry;
                        temp.isSelected = !temp.isSelected;
                        console.log(temp, "temp2");
                        setSelectedProdDetail(temp);
                        let temp2 = JSON.parse(JSON.stringify(cartItems));
                        if (
                          temp2.filter((e) => e.designID === designID).length >
                          0
                        ) {
                          temp2.splice(
                            temp2.findIndex((e) => e.designID === designID),
                            1
                          );
                        } else {
                          temp2.push(selectedProdDetail);
                        }
                        setCartItems(temp2);
                      } else {
                        setSelectedProdDetail(temp);
                        notify(
                          "error",
                          "This product is already selected for other enquiry."
                        );
                      }
                    }}
                  >
                    <Checkbox
                      icon={<FavoriteBorder style={{ color: "#49b675" }} />}
                      checkedIcon={<Favorite style={{ color: "#49b675" }} />}
                      checked={selectedProdDetail.selectedInEnquiry}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  Colors:
                  {Object.keys(productImages).length > 0 &&
                    productImages.colorDetails.map((item, index) => {
                      return (
                        <span
                          className={classes.colorsStyle}
                          onClick={() => {
                            const temp = JSON.parse(
                              JSON.stringify(productImages)
                            );
                            temp.frontView = item.frontThumbnail3D;
                            temp.backView = item.backThumbnail3D;
                            temp.sideView = item.sideThumbnail3D;
                            temp.misc2 = item.misc2Thumbnail3D;
                            setProductImages(temp);
                          }}
                        >
                          {productImages.colorDetails[
                            productImages.colorDetails.length - 1
                          ].color === item.color
                            ? `${item.color}`
                            : ` ${item.color}, `}
                        </span>
                      );
                    })}
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  {designDetails.designCode}
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  {designDetails.designDesc}
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  {designDetails.age}
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  {designDetails.type}
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  {designDetails.gender}
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  {designDetails.category}
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  {designDetails.fit}
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  {designDetails.product}
                </div>
                <div
                  style={{
                    marginTop: ".2rem",
                    color: "#75B551",
                    marginBottom: ".2rem",
                    fontSize: "1.4rem",
                  }}
                >
                  {designDetails.fabric}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
