import React, { Component, useState, useRef } from "react";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import HomeNT from "./pages/HomeNewTheme";
import AddDesignFLNT from "./pages/AddDesignFLNT";
import AddColor from "./pages/addColor";
import MyDesignsNT from "./pages/MyDesignsFLNT";
import ViewDesignNT from "./pages/ViewDesignNT";
import { Helmet } from "react-helmet";
import MyDesignsSC from "./pages/MyDesignsSC";
import ViewDesignSC from "./pages/ViewDesignSC";
import SendForCosting from "./pages/SendForCosting";
import DCuration from "./pages/3DCurationSC";
import Add3D from "./pages/add3D";
import MyDesigns3D from "./pages/MyDesigns3D";
import View3D from "./pages/view3D";
import Login from "./pages/login";
import BuyerEnquiries from "./pages/buyerEnquiries";
import AddEnquiry from "./pages/addEnquiry";
import ViewEnquiry from "./pages/ViewEnquiry";
import Catalogue from "./pages/catalogue";
import DesignDetails from "./pages/designDetails";
import SignUp from "./pages/signup";
import NewUsers from "./pages/newUsers";
import ApproveUsers from "./pages/approveUsers";
import MasterTracker from "./pages/masterTracker";
import MarginApprovals from "./pages/marginApprovals";
import PassReq from "./pages/passReq";
import PassReset from "./pages/PassReset";
import { useCookies, withCookies, Cookies } from "react-cookie";

function App() {
  const [selectedDesign, setSelectedDesign] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [designID, setDesignID] = useState(0);
  const [userID, setUserID] = useState();
  const [userRole, setUserRole] = useState();
  const [loginID, setLoginID] = useState();
  const [userName, setUserName] = useState();
  const [loginStatus, setLoginStatus] = useState(-1);
  const [theme, setTheme] = useState("light");
  const [headerData, setHeaderData] = useState([]);
  const [isHeader, setIsHeader] = useState(false);
  const [preserveState, setPreserveState] = useState([]);
  const [preserveOrder, setPreserveOrder] = useState(null);
  const [preserveSearchText, setPreserveSearchText] = useState("");
  const [preserveValByOrder, setPreserveValByOrder] = useState("");
  const [preserveMyDesigns, setPreserveMyDesigns] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies([
    "username",
    "password",
  ]);

  if (typeof window !== "undefined") {
    window.React = React;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Dash Board</title>
      </Helmet>
      <div></div>
      <Route exact path="/">
        <Login
          setUserID={setUserID}
          setLoginStatus={setLoginStatus}
          setUserRole={setUserRole}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setUserNameX={setUserName}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setCookie={setCookie}
          cookies={cookies}
          setLoginID={setLoginID}
        />
      </Route>
      <Route exact path="/passReq">
        <PassReq
          setUserID={setUserID}
          setLoginStatus={setLoginStatus}
          setUserRole={setUserRole}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setUserNameX={setUserName}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
        />
      </Route>
      <Route exact path="/passReset">
        <PassReset
          setUserID={setUserID}
          setLoginStatus={setLoginStatus}
          setUserRole={setUserRole}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setUserNameX={setUserName}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
        />
      </Route>
      <Route exact path="/signup">
        <SignUp
          setUserID={setUserID}
          setLoginStatus={setLoginStatus}
          setUserRole={setUserRole}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setUserNameX={setUserName}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
        />
      </Route>
      <Route exact path="/home">
        <HomeNT
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setSelectedUser={setSelectedUser}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/masterTracker">
        <MasterTracker
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </Route>
      <Route exact path="/marginApprovals">
        <MarginApprovals
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </Route>
      <Route exact path="/addDesign">
        <AddDesignFLNT
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </Route>
      <Route exact path="/addColor">
        <AddColor
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/designsNT">
        <MyDesignsNT
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          theme={theme}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </Route>
      <Route exact path="/designsSC">
        <MyDesignsSC
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          theme={theme}
          setTheme={setTheme}
          preserveState={preserveState}
          setPreserveState={setPreserveState}
          preserveOrder={preserveOrder}
          setPreserveOrder={setPreserveOrder}
          preserveSearchText={preserveSearchText}
          setPreserveSearchText={setPreserveSearchText}
          preserveValByOrder={preserveValByOrder}
          setPreserveValByOrder={setPreserveValByOrder}
          preserveMyDesigns={preserveMyDesigns}
          setPreserveMyDesigns={setPreserveMyDesigns}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/viewDesignNT">
        <ViewDesignNT
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </Route>
      <Route exact path="/viewDesignSC">
        <ViewDesignSC
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          setLoginStatus={setLoginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          preserveState={preserveState}
          setPreserveState={setPreserveState}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/sendForCosting">
        <SendForCosting
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          loginID={loginID}
        />
      </Route>
      <Route exact path="/3DCuration">
        <DCuration
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/buyerEnquiries">
        <BuyerEnquiries
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/approveUsers">
        <ApproveUsers
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/newUsers">
        <NewUsers
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/add3D">
        <Add3D
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </Route>
      <Route exact path="/designs3D">
        <MyDesigns3D
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </Route>
      <Route exact path="/view3D">
        <View3D
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/addEnquiry">
        <AddEnquiry
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
        />
      </Route>
      <Route exact path="/viewEnquiry">
        <ViewEnquiry
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
      </Route>
      <Route exact path="/cat">
        <Catalogue
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          designID={designID}
          setDesignID={setDesignID}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          cartItems={cartItems}
        />
      </Route>
      <Route exact path="/designDetails">
        <DesignDetails
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          designID={designID}
          setDesignID={setDesignID}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          cartItems={cartItems}
        />
      </Route>
    </React.Fragment>
  );
}

export default withCookies(App);
