import React, { useState, useEffect, useMemo, useRef, createRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import "../stylesheets/FreelancerNT.css";
import toast, { Toaster } from "react-hot-toast";
import Notification from "../components/Notification";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { light, dark } from "../ui/Toggletheme";
import ChatIcon from "@mui/icons-material/Chat";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import Dropzone from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Document, Page } from "react-pdf";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Header from "../components/header";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import { Polling } from "../utils/polling";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import NotificationSound from "../sounds/notif.mp3";
import Compressor from "compressorjs";

var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 50px",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF88",
    padding: 8,
    borderRadius: 8,
  },
  inputForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  imagePicker: {
    display: "flex",
    flexDirection: "column",
    height: "50%",
  },
  pdfPicker: {
    marginTop: 10,
    zIndex: 10,
  },
  selection: {
    width: "100%",
  },
  select: {
    color: "#000000",
  },
  icon: {
    color: "#0000088",
  },
  submit: {
    padding: "10px 50px",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: "1.7rem",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  chatBtn: {
    padding: 20,
    borderRadius: 50,
    backgroundColor: "#74B451AA",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#74B451",
      transform: "scale(1.1)",
    },
  },
  filter: {
    position: "absolute",
    height: "65vh",
    width: "30%",
    backgroundColor: "#FFFFFF",
    padding: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    borderRadius: "8px",
    zIndex: 120,
  },
  addColor: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    alignItems: "center",
    transition: "all .2s ease-in-out",
    color: "#000000AA",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.005)",
      color: "#000000",
    },
  },
  colorAddModal: {
    position: "absolute",
    width: "72vw",
    height: "70vh",
    top: "15vh",
    right: "15vw",
    backgroundColor: "red",
    zIndex: 1000,
    borderRadius: 8,
    padding: 18,
    backgroundColor: "#75B55188",
    backdropFilter: "blur(5px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  colorRow: {
    margin: 4,
    display: "flex",
    padding: 4,
    flexDirection: "row",
    borderRadius: 4,
    transition: "all .2s ease-in-out",
    backgroundColor: "#FFFFFFAA",
    color: "#75B551",
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.04)",
    },
  },
  chats: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxHeight: "80%",
    overflow: "auto",
  },
  SendIcon: {
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.5)",
    },
  },
}));

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#00000088",
  borderStyle: "dashed",
  backgroundColor: "#FFFFFFAA",
  color: "#75B551",
  height: "100%",
  fontSize: "1.2rem",
  transition: "border .24s ease-in-out",
};

const baseStyle2 = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 2,
  color: "#FFFFFF",
  height: "100%",
  fontSize: "10rem",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#00000088",
};

const acceptStyle = {
  borderColor: "#00000088",
};
const OutlinedInputL = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#00000088 !important",
  },
}))(MuiOutlinedInput);
const OutlinedInputD = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#49b675AA !important",
  },
}))(MuiOutlinedInput);

const CustomTextFieldXD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#FFFFFF",
    },
    "& label": {
      color: "#FFFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FFFFFF",
      },
      "&:hover fieldset": {
        borderColor: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFFFFF",
      },
    },
  },
})(TextField);

const CustomTextFieldL = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000088",
      },
      "&:hover fieldset": {
        borderColor: "#00000088",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000088",
      },
    },
  },
})(TextField);

const CustomTextFieldD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#49b675AA",
    },
    "& label": {
      color: "#49b675AA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#49b675AA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#49b675AA",
      },
      "&:hover fieldset": {
        borderColor: "#49b675AA",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#49b675AA",
      },
    },
  },
})(TextField);

const Chatfeild = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#75B551",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#00000088",
    },
  },
})(TextField);

const WhiteCheckbox = withStyles({
  root: {
    color: "#FFFFFFAA",
    width: 10,
    height: 10,
    "&$checked": {
      color: "#FFFFFF",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function ViewDesignNT(props) {
  const classes = useStyles();
  const history = useHistory();
  const audioPlayer = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }

  const {
    loginStatus,
    setLoginStatus,
    userID,
    userRole,
    userName,
    selectedDesign,
    setSelectedDesign,
    theme,
    setTheme,
    headerData,
    setHeaderData,
    isHeader,
    setIsHeader,
    cookies,
    removeCookie,
  } = props;

  const [thumbnail, setThumbnail] = useState([]);
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [chatFiles4, setChatFiles4] = useState([]);
  const [image, setImage] = useState("");
  const [type, setType] = React.useState("");
  const [types, setTypes] = React.useState(["A", "B", "C", "D"]);
  const [age, setAge] = React.useState("");
  const [ages, setAges] = React.useState(["10", "20", "30", "40"]);
  const [productType, setProductType] = React.useState("");
  const [productTypes, setProductTypes] = React.useState([
    "InnerWear",
    "T-Shirt",
    "Jeans",
    "Shirt",
  ]);
  const [gender, setGender] = React.useState("");
  const [genders, setGenders] = React.useState([
    "Male",
    "Female",
    "Boys",
    "Girls",
  ]);
  const [category, setCategory] = React.useState("");
  const [categories, setCategories] = React.useState([
    "InnerWear",
    "T-Shirt",
    "Jeans",
    "Shirt",
  ]);
  const [fit, setFit] = React.useState("");
  const [fits, setFits] = React.useState(["Slim", "Medium", "Skinny"]);
  const [fabrics, setFabrics] = React.useState([]);
  const [fabric, setFabric] = React.useState("");

  const [colors, setColors] = React.useState([]);
  const [colorsFilt, setColorsFilt] = React.useState([]);
  const [selectedColors, setSelectedColors] = React.useState([]);
  const [scroll, setScroll] = React.useState(false);
  const [showColorModal, setShowColorModal] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [designName, setDesignName] = React.useState("");
  const [genericName, setGenericName] = React.useState("");
  const [designCode, setDesignCode] = React.useState("");
  const [designDescription, setDesignDescription] = React.useState("");
  const [showChat, setShowChat] = React.useState(false);
  const [chat, setChat] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [chatAlert, setChatAlert] = React.useState(false);
  const bottomRef = useRef(null);
  const bottomRefColors = useRef(null);


  const dropzoneRef = createRef();

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    accept: { "/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setChatFiles4(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isFocused, isDragAccept]
  );

  const style2 = useMemo(
    () => ({
      ...baseStyle2,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isFocused, isDragAccept]
  );

  const notify = (type, message) => {
    if (type === "submit") {
      toast("Design has been Sent for Review");
    } else if (type == "error") {
      toast.error(message);
    } else if (type == "success") {
      toast.success(message, {
        iconTheme: {
          primary: "#0047AB",
          secondary: "#FFFAEE",
        },
      });
    } else if (type == "custom") {
      toast((t) => (
        <span>
          <b>Thanks Shilpa Polavarapu for submitting your awesome designs.</b>
          <br />
          <br />
          Our team is on the job of reviewing your designs and will share
          feedback soon. Check back here for updates. You will also receive a
          notification once the review is completed.
        </span>
      ));
    }
  };

  const filterColors = (filter) => {
    filter = filter.toLowerCase();

    if (!filter || filter == "") {
      setColorsFilt(colors);
      setSearchTerm("");
    } else {
      const newData = colors.filter((item) => {
        let itemString = item["name"];
        return itemString.toLowerCase().includes(filter);
      });
      setColorsFilt(newData);
    }
  };

  const getDesignData = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getDesignInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: selectedDesign,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result)
        setDesignCode(result.designDetails[0].designCode);
        setType(result.designDetails[0].typeID);
        setGender(result.designDetails[0].genderID);
        setAge(result.designDetails[0].ageID);
        setCategory(result.designDetails[0].categoryID);
        setProductType(result.designDetails[0].productID);
        setFit(result.designDetails[0].fitID);
        setDesignName(result.designDetails[0].designName);
        setGenericName(result.designDetails[0].genericName);
        setDesignDescription(result.designDetails[0].designDesc);
        setFabric(result.designDetails[0].fabricID);
        result.designDetails[0].colorDetails.forEach((item, index) => {
          let temp = { preview: item.image };
          item.image = temp;
        });
        setSelectedColors(result.designDetails[0].colorDetails);
        setChatAlert(result.alert[0].freelancerAlert == 0 ? false : true);
        setFiles([{ preview: result.designDetails[0].designImagePath }]);
        setFiles2([
          { path: result.designDetails[0].techPackPDF, name: "Tech Pack" },
        ]);
        // setFiles3([{path:result.designDetails[0].techPackPDF,name:"Tech Pack"}])
        var temp = [];
        // console.log()
        result.designDetails[0].attachments.forEach((item, index) => {
          temp.push({ preview: item.attachment, name: "Attachment" + index });
        });
        setFiles3(temp);
        if (result.alert[0].freelancerAlert == 1) {
          playAudio();
        }
        setChat(result.designDetails[0].freelancerChat);
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const updateChatRead = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/updateAlert", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: selectedDesign,
        sentTo: "CURATOR",
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {}).catch((error) => {
        console.error("An error occurred:", error);
      });;
  };

  const getDropdownData = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getDesignDropdown", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setGenders(result.gender);
        setFits(result.fitType);
        setAges(result.age);
        setCategories(result.category);
        setProductTypes(result.productType);
        setTypes(result.materialType);
        setFabrics(result.fabricType);
        setColors(result.colorType);
        setColorsFilt(result.colorType);
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  const cancel = () => {
    setChatFiles4("");
  };

  const sendChat = () => {
    if (chatMessage == "" && chatFiles4.length == 0) {
      notify("error", "Please enter a message");
    } else {
      var status = 0;
      var formdata1 = new FormData();
      var submitObj = {
        userID: userID,
        designID: selectedDesign,
        message: chatMessage,
        attachmentType: chatFiles4.length > 0 ? "FILE" : "TXT",
      };
      formdata1.append("json", JSON.stringify(submitObj));
      formdata1.append("attachment", chatFiles4[0]);
      console.log(submitObj);
      fetch(
        "https://studioeapi.bluekaktus.com/api/design/insertFreelancerChat",
        {
          method: "POST",
          body: formdata1,
        }
      )
        .then((response) => {
          status = response.status;
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (status == 200) {
            fetch(
              "https://studioeapi.bluekaktus.com/api/design/getFreelancerChat",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userID: userID,
                  designID: selectedDesign,
                }),
              }
            )
              .then((response) => {
                if (response.status === 500) {
                  throw new Error(
                    "Oops, something went wrong on our end. Please try again later"
                  );
                }
                if (response.status === 400) {
                  throw new Error("Invalid request");
                }
                if (!response.ok) {
                  throw new Error(
                    "Oops, something went wrong. Please try again later."
                  );
                }
                return response.json();
              })
              .then((result) => {
                setChat(result.result);
                setChatMessage("");
                setChatFiles4("");
                setChatAlert(result.alert[0].curatorAlert == 0 ? false : true);
                setTimeout(() => {
                  bottomRef.current?.scrollIntoView({ behavior: "smooth" });
                }, 150);
              });
          } else {
            notify("error", result.result);
          }
        }).catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  };

  Polling(() => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getFreelancerChat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: selectedDesign,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        if (chat.length != result.result.length) {
          playAudio();
        }
        setChat(result.result);
        setChatAlert(result.alert[0].freelancerAlert == 0 ? false : true);
        setTimeout(() => {
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 200);
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
    if (showChat) {
      updateChatRead();
    }
  }, 4000);

  const submit = () => {
    if (designCode == "") {
      notify("error", "Please Enter Design Code");
      return;
    }
    if (type == "") {
      notify("error", "Please Enter Type");
      return;
    }
    if (gender == "") {
      notify("error", "Please Enter Gender");
      return;
    }
    if (age == "") {
      notify("error", "Please Enter Age");
      return;
    }
    if (category == "") {
      notify("error", "Please Enter Category");
      return;
    }
    if (productType == "") {
      notify("error", "Please Enter Product Type");
      return;
    }
    if (fit == "") {
      notify("error", "Please Enter Fit");
      return;
    }
    if (designName == "") {
      notify("error", "Please Enter Design Name");
      return;
    }
    if (genericName == "") {
      notify("error", "Please Enter Generic Name");
      return;
    }
    if (designDescription == "") {
      notify("error", "Please Enter Design Description");
      return;
    }
    if (fabric == "") {
      notify("error", "Please Enter Fabric");
      return;
    }
    if (files.length == 0) {
      notify("error", "Please Upload Image");
      return;
    }
    if (files2.length == 0) {
      notify("error", "Please Upload Tech Pack PDF");
      return;
    }
    if (selectedColors.length == 0) {
      notify("error", "Please Select Colors");
      return;
    }

    var status;
    var formdata1 = new FormData();
    var submitObj = {
      userID: userID,
      designForm: {
        designCode: designCode,
        designName: designName,
        genericName: genericName,
        designDescription: designDescription,
        typeID: type,
        genderID: gender,
        ageID: age,
        categoryID: category,
        fitID: fit,
        prodID: productType,
        fabricID: fabric,
        designID: 0,
      },
    };
    formdata1.append("json", JSON.stringify(submitObj));
    formdata1.append("designImage", files[0]);
    formdata1.append("refrenceImage", files[0]);
    formdata1.append("silhoutteImage", files[0]);
    formdata1.append("techPackPDF", files2[0]);
    formdata1.append("thumbnailDesignImage", thumbnail[0]);
    files3.forEach((item, index) => {
      formdata1.append("attachment" + index + 1, files3[index]);
    });
    selectedColors.forEach((item, index) => {
      let string = "colorID/" + item.id + "/";
      if (item.isPrimary) {
        string = string + 1;
      } else {
        string = string + 0;
      }

      if (selectedColors[index].image != null) {
        formdata1.append(string, selectedColors[index].image);
      } else {
        formdata1.append(string, "[object Object]");
      }
    });
    const fetchData = async () => {
      const response = await axios
        .post(
          "https://studioeapi.bluekaktus.com/api/design/insertDesignForm",
          formdata1
        )
        .then((response) => {
          status = response.status;

          if (status == 200) {
            // notify("success", "Design Updated");
            fetch(
              "https://studioeapi.bluekaktus.com/api/design/saveTransactionDetails",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userID: userID,
                  designID: selectedDesign,
                  statusType: "ALERT",
                }),
              }
            )
              .then((response) => {
                status = response.status;
                return response.json();
              })
              .then((result) => {});
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.Message
          ) {
            notify("error", err.response.data.Message);
          } else {
            notify("error", "Invalid Details");
          }
          return Promise.reject(err);
        });
    }; //to catch the errors if any
    const callFunction = fetchData();
    toast.promise(callFunction, {
      position: "top-center",
      loading: "Saving...",
      error: "Data not saved",
      success: "Design Updated",
    });
  };

  useEffect(()=>{
    if(scroll){
      const lastChildElement = bottomRefColors.current?.lastElementChild;
      lastChildElement?.scrollIntoView({ behavior: 'smooth' });
      setScroll(false);
    }
  },[scroll]);

  
  useEffect(() => {
    if (loginStatus == 1) {
      getDropdownData();
      getDesignData();
    }
  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={theme == "light" ? "bodyNT" : "body"}>
        <Toaster
          position="bottom-center"
          toastOptions={{
            // Define default options
            className: "",

            style: {
              color: " #FFFFFF",
              background: "#75B551DD",
            },
            success: {
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
            submit: {
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
            custom: {
              duration: 4000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
            error: {
              duration: 2000,
              style: {
                color: " #FFFFFF",
                background: "#75B551DD",
              },
            },
          }}
        />
        <motion.div
          // variants={container}
          initial={{ x: "-50vw", y: "30vh", opacity: 0, scale: 0 }}
          animate={
            showChat
              ? { x: "1vw", y: "30vh", opacity: 1, scale: 1 }
              : { x: "-50vw", y: "30vh", opacity: 0, scale: 0 }
          }
          transition={{ duration: 0.3, origin: 0 }}
          className={classes.filter}
          style={{
            boxShadow:
              theme == "light"
                ? "#75B551 0px 1px 4px"
                : "#FFFFFFAA 0px 1px 4px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div onClick={() => setShowChat(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className={classes.chats}>
            {chat.map((item, index) => {
              if (item.attachmentType == "TXT") {
                return (
                  <div
                    style={{
                      backgroundColor:
                        item.roleName == "Freelancer" ||
                        item.roleName == "In-house Designer"
                          ? "#75B55155"
                          : "#00000022",
                      padding: "5px 10px",
                      marginTop: 5,
                      borderRadius: 10,
                      maxWidth: "75%",
                      display: "flex",
                      flexDirection: "column",
                      alignSelf:
                        item.roleName == "Freelancer" ||
                        item.roleName == "In-house Designer"
                          ? "flex-end"
                          : "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontSize: ".7rem",
                        fontWeight: "bold",
                        color: "#000000AA",
                        textAlign:
                          item.roleName == "Freelancer" ||
                          item.roleName == "In-house Designer"
                            ? "right"
                            : "left",
                      }}
                    >
                      {item.roleName}
                    </div>
                    <div
                      style={{
                        textAlign:
                          item.roleName == "Freelancer" ||
                          item.roleName == "In-house Designer"
                            ? "right"
                            : "left",
                      }}
                    >
                      {item.message}
                    </div>
                    <div
                      style={{
                        fontSize: ".7rem",
                        textAlign:
                          item.roleName != "Freelancer" ? "right" : "left",
                        color: "#000000AA",
                      }}
                    >
                      {item.addedOn}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      backgroundColor:
                        item.roleName == "Freelancer" ||
                        item.roleName == "In-house Designer"
                          ? "#75B55155"
                          : "#00000022",
                      padding: "5px 10px",
                      marginTop: 5,
                      borderRadius: 10,
                      maxWidth: "75%",
                      display: "flex",
                      flexDirection: "column",
                      alignSelf:
                        item.roleName == "Freelancer" ||
                        item.roleName == "In-house Designer"
                          ? "flex-end"
                          : "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontSize: ".7rem",
                        fontWeight: "bold",
                        color: "#000000AA",
                        textAlign:
                          item.roleName == "Freelancer" ||
                          item.roleName == "In-house Designer"
                            ? "right"
                            : "left",
                      }}
                    >
                      {item.roleName}
                    </div>
                    <div
                      style={{
                        textAlign:
                          item.roleName == "Freelancer" ||
                          item.roleName == "In-house Designer"
                            ? "right"
                            : "left",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onClick={() => {
                        window.open(item.attachment);
                      }}
                    >
                      {item.attachment.slice(
                        item.attachment.length - 3,
                        item.attachment.length
                      ) == "png" ||
                      item.attachment.slice(
                        item.attachment.length - 3,
                        item.attachment.length
                      ) == "jpg" ||
                      item.attachment.slice(
                        item.attachment.length - 3,
                        item.attachment.length
                      ) == "peg" ? (
                        <img
                          src={item.attachment}
                          style={{
                            height: "100%",
                            maxWidth: "100%",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <div>FILE</div>
                      )}
                      <div>{item.message}</div>
                    </div>
                    <div
                      style={{
                        fontSize: ".7rem",
                        textAlign:
                          item.roleName != "Freelancer" ? "right" : "left",
                        color: "#000000AA",
                      }}
                    >
                      {item.addedOn}
                    </div>
                  </div>
                );
              }
            })}
            <div ref={bottomRef} />
          </div>
          {chatFiles4.length == 0 ? null : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "20%",
                maxWidth: "25%",
                margin: 3,
                backgroundColor: "#88888888",
                // borderRadius: 5,
                padding: 3,
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  padding: 5,
                }}
              >
                <CancelIcon
                  fontSize="small"
                  style={{ color: "#ff681fDD" }}
                  onClick={() => cancel()}
                />
              </div>

              {chatFiles4[0] &&
              chatFiles4[0]["name"] &&
              chatFiles4[0]["name"].slice(-3) === "pdf" ? (
                <PictureAsPdfIcon
                  fontSize="large"
                  style={{
                    color: "#e71837DD",
                    marginBottom: 5,
                  }}
                />
              ) : (chatFiles4[0] &&
                  chatFiles4[0]["name"] &&
                  chatFiles4[0]["name"].slice(-3) === "xls") ||
                (chatFiles4[0] &&
                  chatFiles4[0]["name"] &&
                  chatFiles4[0]["name"].slice(-4) === "xlsx") ? (
                <svg
                  width="32px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="#1D7044"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginBottom: 5,
                  }}
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zm-6.8 9L13 8h-2.4L9 10.286 7.4 8H5l2.8 4L5 16h2.4L9 13.714 10.6 16H13l-2.8-4z" />
                  </g>
                </svg>
              ) : chatFiles4[0] &&
                chatFiles4[0]["name"] &&
                chatFiles4[0]["name"].slice(-4) === "pptx" ? (
                <svg
                  width="32px"
                  height="30px"
                  fill="#C64120"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginBottom: 5,
                  }}
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zM2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM5 8v8h2v-2h6V8H5zm2 2h4v2H7v-2z" />
                  </g>
                </svg>
              ) : (
                <img
                  src={chatFiles4[0].preview}
                  style={{
                    height: "50%",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    URL.revokeObjectURL(chatFiles4[0].preview);
                  }}
                />
              )}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Chatfeild
                style={{ flex: 1 }}
                fullWidth
                // id="input-with-icon-textfield"
                label="Enter message"
                value={chatMessage}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    sendChat();
                  }
                }}
                onChange={(e) => {
                  setChatMessage(e.target.value);
                }}
              />
            </div>

            <input
              type="file"
              id="upload-button"
              style={{ display: "none" }}
              {...getInputProps()}
              accept="image/*,.pdf,.docx,.xls,.xlsx"
            />
            <label htmlFor={"upload-button"}>
              <div style={{ marginLeft: 10, padding: 3 }}>
                <AttachFileIcon style={{ color: "#75B551" }} />
              </div>
            </label>
            <div
              className={classes.SendIcon}
              style={{ marginLeft: 15, marginRight: 10 }}
              onClick={() => {
                sendChat();
              }}
            >
              <SendIcon style={{ color: "#75B551" }} />
            </div>
          </div>
        </motion.div>
        <div
          style={{
            position: "absolute",
            bottom: 30,
            right: 30,
            // width:"8vh",
            // height:"8vh",
            zIndex: 100,
          }}
        >
          <div
            style={{ postition: "relative", padding: 10 }}
            onClick={() => {
              setChatAlert(false);
              updateChatRead();
              setShowChat(!showChat);
              setTimeout(() => {
                bottomRef.current?.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            {chatAlert ? (
              <NewReleasesIcon
                fontSize="large"
                style={{
                  color: "#0F80D7",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              />
            ) : null}
            <div className={classes.chatBtn}>
              <ChatIcon fontSize="large" style={{ color: "#FFFFFF" }} />
            </div>
          </div>
        </div>
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div className={classes.wrapper}>
          <div
            className={classes.inputWrapper}
            style={{
              boxShadow:
                theme == "light"
                  ? "#75B551 0px 1px 4px"
                  : "#FFFFFFAA 0px 1px 4px",
              zIndex: 10,
            }}
          >
            <div className={classes.inputForm}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  padding: 8,
                  maxHeight: "75vh",
                }}
              >
                <div className={classes.imagePicker}>
                  <Dropzone
                    maxFiles={1}
                    ref={dropzoneRef}
                    onDrop={(file) => {
                      if (
                        (file[0].type != "image/jpeg" &&
                          file[0].type != "image/png") ||
                        file.length > 1
                      ) {
                        notify("error", "Please Upload only 1 Image");
                      } else {
                        console.log(file);
                        file.map((file) => {
                          Object.assign(file, {
                            preview: URL.createObjectURL(file),
                          });
                        });
                        console.log(
                          500000 / file[0].size > 1
                            ? 0.8
                            : 500000 / file[0].size
                        );
                        new Compressor(file[0], {
                          quality: 500000 / file[0].size,
                          convertSize: 1000,
                          success(result) {
                            let fileName = `${result.name}`;
                            let newfile = new File([result], fileName, {
                              type: "image/jpg",
                            });
                            Object.assign(newfile, {
                              preview: URL.createObjectURL(newfile),
                            });
                            console.log(URL.createObjectURL(newfile));
                            setThumbnail([newfile]);
                            setFiles(file);
                          },
                          error(err) {
                            console.log(err.message);
                          },
                        });
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps, acceptedFiles }) => {
                      if (files.length == 0) {
                        return (
                          <div>
                            <div {...getRootProps({ style })}>
                              <input {...getInputProps()} />
                              <p> Front Image* </p>
                              <p>Drag 'n' Drop or Upload </p>
                            </div>
                          </div>
                        );
                      } else {
                        if (thumbnail.length > 0) {
                          return (
                            <div
                              {...getRootProps({ style })}
                              className={classes.image}
                            >
                              <img
                                src={thumbnail[0].preview}
                                style={{
                                  height: "100%",
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                }}
                                // Revoke data uri after image is loaded
                                onLoad={() => {
                                  URL.revokeObjectURL(files[0].preview);
                                }}
                              />
                              <input {...getInputProps()} />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              {...getRootProps({ style })}
                              className={classes.image}
                            >
                              <img
                                src={files[0].preview}
                                style={{
                                  height: "100%",
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                }}
                                // Revoke data uri after image is loaded
                                onLoad={() => {
                                  URL.revokeObjectURL(files[0].preview);
                                }}
                              />
                              <input {...getInputProps()} />
                            </div>
                          );
                        }
                      }
                    }}
                  </Dropzone>
                </div>
                <div className={classes.pdfPicker}>
                  <Dropzone
                    maxFiles={1}
                    ref={dropzoneRef}
                    onDrop={(file) => {
                      console.log(file);
                      if (
                        file[0].type != "application/pdf" ||
                        file.length > 1
                      ) {
                        notify("error", "Please Upload only 1 PDF file");
                      } else {
                        setFiles2(file);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps, acceptedFiles }) => {
                      if (files2.length == 0) {
                        return (
                          <div>
                            <div {...getRootProps({ style })}>
                              <input {...getInputProps()} />
                              <p>Drag 'n' Drop Tech Pack</p>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          // <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:13,fontSize:".8rem",textAlign:"center"}}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                // textAlign: "center",
                                display: "flex",
                                fontSize: "1.1rem",
                                fontWeight: "bold",
                                color: "#000000AA",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                Tech Pack
                                <div
                                  // {...getRootProps({ style })}
                                  style={{
                                    padding: 10,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* <input {...getInputProps()} /> */}
                                  <PictureAsPdfIcon
                                    fontSize="medium"
                                    style={{
                                      color: "#e71837DD",
                                      marginBottom: 5,
                                    }}
                                  />
                                  <div
                                    style={{
                                      textOverflow: "ellipsis",
                                      fontSize: ".8rem",
                                      // textAlign: "center",
                                    }}
                                  >
                                    {files2[0].name}
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                Download{" "}
                                <div
                                  // {...getRootProps({ style })}
                                  style={{
                                    padding: 10,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => window.open(files2[0].path)}
                                >
                                  {/* <input {...getInputProps()} /> */}
                                  <FileDownloadIcon
                                    fontSize="medium"
                                    style={{
                                      color: "rgb(117, 181, 81)",
                                      marginBottom: 5,
                                    }}
                                  />
                                  <div
                                    style={{
                                      textOverflow: "ellipsis",
                                      fontSize: ".8rem",
                                      // textAlign: "center",
                                    }}
                                  >
                                    Click Here
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  textAlign: "center",
                                }}
                              >
                                Upload{" "}
                                <div
                                  {...getRootProps({ style })}
                                  style={{
                                    padding: 10,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <input {...getInputProps()} />
                                  <FileUploadIcon
                                    fontSize="medium"
                                    style={{
                                      color: "rgb(62, 124, 194",
                                      marginBottom: 5,
                                    }}
                                  />
                                  <div
                                    style={{
                                      textOverflow: "ellipsis",
                                      fontSize: ".8rem",
                                      // textAlign: "center",
                                    }}
                                  >
                                    Click Here
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <a href={files2[0].path} style={{textAlign:"center",fontSize:"1.1rem",fontWeight:"bold",color:"#000000AA"}}>Tech Pack</a> */}
                          </div>
                        );
                      }
                    }}
                  </Dropzone>
                </div>
                <div className={classes.pdfPicker}>
                  <Dropzone
                    ref={dropzoneRef}
                    style={{ display: "flex", height: "100%" }}
                    onDrop={(files) => {
                      // setFiles3(file)
                      var addedFiles = files3;
                      files.map((file) => {
                        Object.assign(file, {
                          preview: URL.createObjectURL(file),
                        });
                        addedFiles.push(file);
                      });
                      setFiles3(addedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps, acceptedFiles }) => {
                      if (files3.length == 0) {
                        return (
                          <div>
                            <div {...getRootProps({ style })}>
                              <input {...getInputProps()} />
                              <p>Attach Files</p>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          // <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:13,fontSize:".8rem",textAlign:"center"}}>
                          <div
                            {...getRootProps({ style })}
                            style={{
                              padding: 10,
                              paddingTop: 0,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "1.1rem",
                                fontWeight: "bold",
                                color: "#000000AA",
                                marginBottom: 5,
                              }}
                              onClick={() =>
                                files3.map((item, index) => {
                                  window.open(files3[index].preview);
                                })
                              }
                            >
                              Attachments
                            </div>
                            <input {...getInputProps()} />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              {files3.map((item, index) => {
                                var file = files3[index];

                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "18%",
                                      margin: 8,
                                      backgroundColor: "#88888888",
                                      borderRadius: 5,
                                      padding: 3,
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        padding: 5,
                                      }}
                                      onClick={(e) => {
                                        if (!e) var e = window.event;
                                        e.cancelBubble = true;
                                        if (e.stopPropagation)
                                          e.stopPropagation();
                                        var addedFiles = files3;
                                        addedFiles.splice(index, 1);
                                        var temp = [];
                                        addedFiles.forEach((item) => {
                                          console.log(item.preview.slice(0, 4));
                                          if (
                                            item.preview.slice(0, 4) == "blob"
                                          ) {
                                            var x = new File(
                                              [item],
                                              item.name,
                                              { type: item.type }
                                            );
                                            Object.assign(x, {
                                              preview: URL.createObjectURL(x),
                                            });
                                            temp.push(x);
                                          } else {
                                            temp.push(item);
                                          }
                                        });
                                        setFiles3(temp);
                                      }}
                                    >
                                      <CancelIcon
                                        fontSize="small"
                                        style={{ color: "#ff681fDD" }}
                                      />
                                    </div>
                                    {file &&
                                    file["preview"] &&
                                    file["preview"].slice(-3) === "pdf" ? (
                                      <PictureAsPdfIcon
                                        fontSize="large"
                                        style={{
                                          color: "#e71837DD",
                                          marginBottom: 5,
                                        }}
                                      />
                                    ) : (file &&
                                        file["preview"] &&
                                        file["preview"].slice(-3) === "xls") ||
                                      (file &&
                                        file["preview"] &&
                                        file["preview"].slice(-4) ===
                                          "xlsx") ? (
                                      <svg
                                        width="32px"
                                        height="30px"
                                        viewBox="0 0 24 24"
                                        fill="#1D7044"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                          marginBottom: 5,
                                        }}
                                      >
                                        <g>
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path d="M2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zm-6.8 9L13 8h-2.4L9 10.286 7.4 8H5l2.8 4L5 16h2.4L9 13.714 10.6 16H13l-2.8-4z" />
                                        </g>
                                      </svg>
                                    ) : file &&
                                      file["preview"] &&
                                      file["preview"].slice(-4) === "pptx" ? (
                                      <svg
                                        width="32px"
                                        height="30px"
                                        fill="#C64120"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                          marginBottom: 5,
                                        }}
                                      >
                                        <g>
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zM2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM5 8v8h2v-2h6V8H5zm2 2h4v2H7v-2z" />
                                        </g>
                                      </svg>
                                    ) : (
                                      <img
                                        src={item.preview}
                                        style={{
                                          height: "50%",
                                          maxWidth: "100%",
                                          objectFit: "contain",
                                        }}
                                        // Revoke data uri after image is loaded
                                        onLoad={() => {
                                          URL.revokeObjectURL(item.preview);
                                        }}
                                      />
                                    )}
                                    {/* <ImageIcon fontSize="large" style={{color:"#e71837DD",marginBottom:5}} />
                                                <div style={{textOverflow:"ellipsis",fontSize:".8rem",textAlign:"center",width:"100%",overflow:"hidden",whiteSpace:"nowrap"}}>{item.name}</div> */}

                                    <div
                                      style={{
                                        textOverflow: "ellipsis",
                                        fontSize: ".8rem",
                                        textAlign: "center",
                                        width: "100%",
                                      }}
                                    >
                                      {item.name}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      }
                    }}
                  </Dropzone>
                </div>
              </div>
              <div
                style={{
                  padding: 8,
                  display: "flex",
                  flexDirection: "column",
                  width: "66%",
                }}
              >
                <div style={{ width: "49%", marginRight: "1%" }}>
                  {theme == "light" ? (
                    <CustomTextFieldL
                      style={{ flex: 1 }}
                      fullWidth
                      label="Design Code"
                      variant="outlined"
                      value={designCode}
                      onChange={(e) => {
                        setDesignCode(e.target.value);
                      }}
                    />
                  ) : (
                    <CustomTextFieldD
                      style={{ flex: 1 }}
                      fullWidth
                      label="Design Code"
                      variant="outlined"
                      value={designCode}
                      onChange={(e) => {
                        setDesignCode(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{ width: "49%", marginRight: "1%", marginTop: 10 }}
                  >
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 10 }}
                    >
                      <InputLabel
                        id="label"
                        style={{
                          color: theme == "light" ? "#00000088" : "#49b675AA",
                        }}
                      >
                        Type
                      </InputLabel>
                      <Select
                        classes={{
                          select: classes.select,
                          icon: classes.icon,
                        }}
                        label="Type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        input={
                          theme == "light" ? (
                            <OutlinedInputL label="Type" />
                          ) : (
                            <OutlinedInputD label="Type" />
                          )
                        }
                      >
                        {types.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 10 }}
                    >
                      <InputLabel
                        id="label"
                        style={{
                          color: theme == "light" ? "#00000088" : "#49b675AA",
                        }}
                      >
                        Age
                      </InputLabel>
                      <Select
                        classes={{
                          select: classes.select,
                          icon: classes.icon,
                        }}
                        label="Age"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        input={
                          theme == "light" ? (
                            <OutlinedInputL label="Age" />
                          ) : (
                            <OutlinedInputD label="Age" />
                          )
                        }
                      >
                        {ages.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 10 }}
                    >
                      <InputLabel
                        id="label"
                        style={{
                          color: theme == "light" ? "#00000088" : "#49b675AA",
                        }}
                      >
                        Product Type
                      </InputLabel>
                      <Select
                        classes={{
                          select: classes.select,
                          icon: classes.icon,
                        }}
                        label="Product Type"
                        value={productType}
                        onChange={(e) => setProductType(e.target.value)}
                        input={
                          theme == "light" ? (
                            <OutlinedInputL label="Product Type" />
                          ) : (
                            <OutlinedInputD label="Product Type" />
                          )
                        }
                      >
                        {productTypes.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 10 }}
                    >
                      <InputLabel
                        id="label"
                        style={{
                          color: theme == "light" ? "#00000088" : "#49b675AA",
                        }}
                      >
                        Fabric
                      </InputLabel>
                      <Select
                        classes={{
                          select: classes.select,
                          icon: classes.icon,
                        }}
                        label="Fabric"
                        value={fabric}
                        onChange={(e) => setFabric(e.target.value)}
                        input={
                          theme == "light" ? (
                            <OutlinedInputL label="Fabric" />
                          ) : (
                            <OutlinedInputD label="Fabric" />
                          )
                        }
                      >
                        {fabrics.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{ width: "49%", marginRight: "1%", marginTop: 10 }}
                  >
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 10 }}
                    >
                      <InputLabel
                        id="label"
                        style={{
                          color: theme == "light" ? "#00000088" : "#49b675AA",
                        }}
                      >
                        Gender
                      </InputLabel>
                      <Select
                        classes={{
                          select: classes.select,
                          icon: classes.icon,
                        }}
                        label="Gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        input={
                          theme == "light" ? (
                            <OutlinedInputL label="Gender" />
                          ) : (
                            <OutlinedInputD label="Gender" />
                          )
                        }
                      >
                        {genders.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 10 }}
                    >
                      <InputLabel
                        id="label"
                        style={{
                          color: theme == "light" ? "#00000088" : "#49b675AA",
                        }}
                      >
                        Category
                      </InputLabel>
                      <Select
                        classes={{
                          select: classes.select,
                          icon: classes.icon,
                        }}
                        label="Category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        input={
                          theme == "light" ? (
                            <OutlinedInputL label="Category" />
                          ) : (
                            <OutlinedInputD label="Category" />
                          )
                        }
                      >
                        {categories.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 10 }}
                    >
                      <InputLabel
                        id="label"
                        style={{
                          color: theme == "light" ? "#00000088" : "#49b675AA",
                        }}
                      >
                        Fit
                      </InputLabel>
                      <Select
                        classes={{
                          select: classes.select,
                          icon: classes.icon,
                        }}
                        label="Fit"
                        value={fit}
                        onChange={(e) => setFit(e.target.value)}
                        input={
                          theme == "light" ? (
                            <OutlinedInputL label="Fit" />
                          ) : (
                            <OutlinedInputD label="Fit" />
                          )
                        }
                      >
                        {fits.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  className={classes.addColor}
                  onClick={() => {
                    setShowColorModal(true);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: 22,
                      width: 22,
                      borderRadius: 50,
                      backgroundColor: "#75B551",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      padding: 10,
                    }}
                  >
                    <div style={{ height: 20, width: 20 }}>+</div>
                  </div>
                  <div style={{ marginLeft: 10, marginRight: 15 }}>
                    Add Color
                  </div>
                  <div style={{display:"flex",flexWrap:"wrap"}}>
                  {selectedColors.map((item, index) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>{item.name}</div>
                        <div>
                          {index + 1 != selectedColors.length ? "," : ""}
                        </div>
                      </div>
                    );
                  })}
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      width: "49%",
                      marginRight: "1%",
                      marginBottom: 10,
                    }}
                  >
                    {theme == "light" ? (
                      <CustomTextFieldL
                        style={{ flex: 1 }}
                        fullWidth
                        label="Design Name"
                        variant="outlined"
                        value={designName}
                        onChange={(e) => {
                          setDesignName(e.target.value);
                        }}
                      />
                    ) : (
                      <CustomTextFieldD
                        style={{ flex: 1 }}
                        fullWidth
                        label="Design Name"
                        variant="outlined"
                        value={designName}
                        onChange={(e) => {
                          setDesignName(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      width: "49%",
                      marginRight: "1%",
                      marginBottom: 10,
                    }}
                  >
                    {theme == "light" ? (
                      <CustomTextFieldL
                        style={{ flex: 1 }}
                        fullWidth
                        label="Generic Name"
                        variant="outlined"
                        value={genericName}
                        onChange={(e) => {
                          setGenericName(e.target.value);
                        }}
                      />
                    ) : (
                      <CustomTextFieldD
                        style={{ flex: 1 }}
                        fullWidth
                        label="Generic Name"
                        variant="outlined"
                        value={genericName}
                        onChange={(e) => {
                          setGenericName(e.target.value);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{ width: "99%", marginRight: "1%", marginBottom: 25 }}
                >
                  {theme == "light" ? (
                    <CustomTextFieldL
                      style={{ flex: 1 }}
                      fullWidth
                      label="Design Description"
                      variant="outlined"
                      value={designDescription}
                      onChange={(e) => {
                        setDesignDescription(e.target.value);
                      }}
                    />
                  ) : (
                    <CustomTextFieldD
                      style={{ flex: 1 }}
                      fullWidth
                      label="Design Description"
                      variant="outlined"
                      value={designDescription}
                      onChange={(e) => {
                        setDesignDescription(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "99%",
                  }}
                >
                  <div
                    className={classes.submit}
                    style={{
                      backgroundColor:
                        theme == "light" ? "#75B551" : "#49b675DD",
                    }}
                    onClick={() => submit()}
                  >
                    Update
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showColorModal ? (
            <div className={classes.colorAddModal}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: "1.8rem",
                    color: "#FFFFFF",
                    marginLeft: 10,
                  }}
                >
                  Add Color
                </div>
                <div
                  style={{
                    fontSize: "2.2rem",
                    color: "#FFFFFF",
                    top: 10,
                    right: 20,
                  }}
                  onClick={() => {
                    setShowColorModal(false);
                  }}
                >
                  -
                </div>
              </div>
              <div style={{ marginTop: 8, width: "60%" }}>
                <CustomTextFieldXD
                  style={{ flex: 1 }}
                  fullWidth
                  size="small"
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => {
                    filterColors(e.target.value);
                    setSearchTerm(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  height: "100%",
                  overflowY: "auto",
                  width: "100%",
                }}
              >
                <div
                
                  style={{
                    position: "sticky",
                    top: 0,
                    overflow: "scroll",
                    marginRight: 10,
                    width: "20%",
                    padding: 8,
                  }}
                >
                  {colorsFilt.map((item, index) => {
                    return (
                      <div
                        className={classes.colorRow}
                        onClick={() => {
                          let temp = JSON.parse(JSON.stringify(selectedColors));
                          var flag = 0;
                          temp.forEach((itemx) => {
                            if (itemx.name == item.name) {
                              flag = 1;
                            }
                          });
                          if (flag == 0) {
                            temp.push({
                              name: item.name,
                              id: item.id,
                              isPrimary: temp.length == 0 ? true : false,
                              image: null,
                            });
                            setScroll(true);
                          }
                          setSelectedColors(temp);
                        }}
                      >
                        <div>{item.name}</div>
                      </div>
                    );
                  })}
                </div>
                <div
                ref={bottomRefColors}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                    flexWrap: "wrap",
                    width: "80%",
                  }}
                >
                  {selectedColors.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          textAlign: "center",
                          border: "2px solid #FFFFFF",
                          borderRadius: "7px",
                          height: "28vh",
                          padding: 10,
                          width: "15vw",
                          marginRight: 8,
                          marginBottom: 8,
                          alignItems: "",
                          color: "#FFFFFF",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>{item.name}</div>
                          <div
                            style={{ fontSize: 20 }}
                            onClick={() => {
                              let temp = JSON.parse(
                                JSON.stringify(selectedColors)
                              );
                              temp.splice(index, 1);
                              if (temp.length > 0) {
                                let flag = false;
                                temp.map((item2, index2) => {
                                  if (item2.isPrimary) {
                                    flag = true;
                                  }
                                });
                                if (!flag) {
                                  temp[0].isPrimary = true;
                                }
                              }
                              setSelectedColors(temp);
                            }}
                          >
                            x
                          </div>
                        </div>

                        <Dropzone
                          maxFiles={1}
                          ref={dropzoneRef}
                          onDrop={(file) => {
                            if (
                              file[0].type.slice(0, 5) != "image" ||
                              file.length > 1
                            ) {
                              notify("error", "Please Upload only 1 Image ");
                            } else {
                              console.log(file);
                              Object.assign(file[0], {
                                preview: URL.createObjectURL(file[0]),
                              });
                              selectedColors[index].image = file[0];
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps, acceptedFiles }) => {
                            if (item.image == null) {
                              return (
                                <div {...getRootProps({ style2 })}>
                                  <input {...getInputProps()} />
                                  <p
                                    style={{
                                      fontSize: 40,
                                      padding: "20px 80px 20px 80px",
                                    }}
                                  >
                                    +
                                  </p>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  {...getRootProps({ style2 })}
                                  className={classes.image}
                                >
                                  <img
                                    src={
                                      item.image.preview != undefined
                                        ? item.image.preview
                                        : item.image
                                    }
                                    style={{
                                      width: "100%",
                                      maxHeight: "20vh",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <input {...getInputProps()} />
                                </div>
                              );
                            }
                          }}
                        </Dropzone>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            if (selectedColors.length > 1) {
                              let temp = JSON.parse(
                                JSON.stringify(selectedColors)
                              );
                              temp.forEach((item) => {
                                item.isPrimary = false;
                              });
                              temp[index].isPrimary = true;
                              console.log(temp);
                              setSelectedColors(temp);
                            }
                          }}
                        >
                          <WhiteCheckbox checked={item.isPrimary} />
                          <div style={{ marginLeft: 10 }}> Primary Color</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
          <audio ref={audioPlayer} src={NotificationSound} />
        </div>
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          style={{ position: "absolute" }}
        />
      </div>
    );
  }
}
