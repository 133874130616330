import React, { useState, useEffect } from "react";
import { makeStyles ,withStyles,TextField} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "../stylesheets/login.css";

var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({

    wrapper: {
      alignItems:"center",
      width:"100%",
      display: "flex",
      flexDirection: "column",
      margin:"7vh 0vh",
      height:"100vh",
      justifyContent:"center"
    },
    inputWrapper: {
      width:"70%",
      height:"500px",
      display: "flex",
      flexDirection: "row",
      top:10,
      padding: 8,
      borderRadius: 8,
      background:"#FFFFFF",
      boxShadow: "#75B551 0px 1px 4px",
    },
    inputForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems:"center",
      width:"55%",
    //  paddingTop:50,
      // marginTop:50,
      // marginBottom:50,
      // marginRight:50,
      margin:20,
      backgroundColor: "rgba(158, 225, 226, .40)", 
      backdropFilter: "blur(5px)",
      borderRadius:10
    },
    logoForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems:"center",
      borderRadius:"5%",
      width:"50%",
      marginRight:"10px",
    },
    submit: {
      padding:10,
      background:"#75B551",
      color: "white",
      borderRadius: 8,
      fontSize: "1.5rem",
      transition: "all .2s ease-in-out",
      textAlign:"center",
      marginBottom:10,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "none",
        transform: "scale(1.03)",
      },
    
    },
    signIn: {
      fontSize: "2.5rem",
      transition: "all .2s ease-in-out",
      textAlign:"center",
      fontWeight:"bold",
      opacity:0.7,
    },
    signup:{
      fontSize: ".9rem",
      transition: "all .2s ease-in-out",
      textAlign:"center",
      fontWeight:"bold",
      opacity:0.6,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "none",
        transform: "scale(1.03)",
      },
    }
  
  }));

const CustomTextField = withStyles({
    root: {
      padding: 0,
      "& label.Mui-focused": {
        color: "#00000088",
      },
      "& label": {
        color: "#00000088",
      },
     
      "& .MuiInput-underline:after": {
        borderBottomColor: '#75B551'
      },
      
      "& .MuiInput-underline:before": {
        borderBottomColor: ' #75B551'
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderBottomColor: "#75B551",
        },
        "&:hover fieldset": {
          borderColor: "#75B551",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#75B551",
        },
      },
    },
    
  })(TextField);


export default function PassReq(props) {

  const {
    setUserID,
    setLoginStatus,
    setUserRole,
    setUserNameX,
    headerData,
    setHeaderData,
    isHeader,
    setIsHeader
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [username , setUsername] = useState("");


  const notify = (type,message) => {
    if (type === "submit") {
      toast("Password reset link has been mailed to your Email ID");
    }
    if (type === "error") {
        toast.error(message);
      }
  };


  useEffect(() => {
    setLoginStatus(-1)
  }, []);

  const passwordReset = () =>{
    
      fetch("https://studioeapi.bluekaktus.com/api/design/resetSEPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
            {
                userEmail:username,
            }
        ),
      })
        .then((response) => {
          if (response.status == 200) {
            console.log("Sucess");
            return response.json();
          } else {  
            return response.json();
          }
        })
        .then((responseJson) => {
          if (responseJson.Message == "Password Changed") {
            setUsername("");
            notify("submit")
            setTimeout(() => {
              history.push("/")
            }, 5000);
          }else{
            notify("error",responseJson.Message);
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
  }

  return (
    <div className="bodyLogin">
    <Toaster
        position="bottom-right"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            color: "#FFFFFF",
            background: "#49b675AA",
          },
        }}
      />
    <div className={classes.wrapper}>
      <div className={classes.inputWrapper}>
      <div className={classes.logoForm}>
      <img src="https://i.imgur.com/xPwZiRc.png"/>
     
      </div>
        <div className={classes.inputForm}>
          
        <div style={{marginTop:"5%",marginBottom:"8%",alignItems:"center"}}>
            <div className={classes.signIn}>Password Reset</div>
           
          </div>
                <div style={{width:"50%",marginBottom:"5%"}}>
        
        <CustomTextField  variant="standard" 
            style={{ flex: 1 }}
            fullWidth
            id="input-with-icon-textfield"
            label="Username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          </div>
          <div style={{width:"40%",alignItems:"center",fontSize:"1.3rem",marginBottom:10}} onClick={()=>passwordReset()} >
            <div className={classes.submit}>Reset Password</div>
          </div>
      </div>
    </div>
    </div>
    </div>
    
          
  );
}
