import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Link, useHistory } from "react-router-dom";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SendIcon from "@mui/icons-material/Send";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import "../stylesheets/FreelancerNT.css";
import AppsIcon from "@mui/icons-material/Apps";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  OutlinedInput as MuiOutlinedInput,
  TextField,
} from "@material-ui/core";
import Header from "../components/header";
import { Pagination } from "@material-ui/lab";
import usePagination from "../hooks/Pagination";
import Notification from "../components/Notification";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import colors from "../config/colors";
import Checkbox from "@mui/material/Checkbox";
import { TableSortLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 50px",
  },
  tableI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    border: "1.5px solid #00000033",
    maxHeight: "68vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    padding: 10,
    borderBottom: "1.5px solid #00000055",
    position: "sticky",
    top: 0,
  },
  headerRowItem: {
    color: "#75B551",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginRight: 15,
  },
  tableRowEven: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    height: "12vh",
    backgroundColor: "#BBBBBB66",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  tableRowOdd: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    height: "12vh",
    backgroundColor: "#EEEEEEAA",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF88",
    },
  },
  tableCell: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.8vmin",
    color: "#000000AA",
    marginRight: 15,
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    maxHeight: "80vh",
    flexWrap: "wrap",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "40vh",
    width: "25%",
    backgroundColor: "#FFFFFF",
    margin: 8,
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "3px solid #75B551AA",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#75B551AA",
    },
  },
  tableWrapperRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "2.5px 5px",
    width: "100%",
    justifyContent: "center",
    marginTop: 10,
    // border:"2px solid blue",
  },
  mainContainer: {
    position: "relative",
    // border: "2px solid black",
  },
  filter: {
    position: "absolute",
    maxHeight: "60vh",
    backgroundColor: "#FFFFFF",
    padding: "1rem",
    overflowY: "auto",
    borderRadius: "0px 8px 8px 0px",
    zIndex: 200,
    display: "flex",
    flexDirection: "column",
  },
  secondaryFiltersWrapperApply: {
    float: "left",
    color: "#FFFFFF",
    fontSize: "1.1rem",
    backgroundColor: "#75B551",
    backdropFilter: "blur(5px)",
    borderRadius: "1rem",
    boxShadow: "0.2rem 0.2rem .3rem #c8d0e7, -0.3rem -0.3rem .4rem #FFFFFF",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 3,
    marginBottom: 15,
    transition: "all .2s ease-in-out",
    "&:hover": {
      backgroundColor: "#75B551",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  secondaryFiltersWrapperReset: {
    float: "left",
    color: "#FFFFFF",
    fontSize: "1.1rem",
    backgroundColor: "rgba(231, 24, 55, 0.867)",
    backdropFilter: "blur(5px)",
    borderRadius: "1rem",
    boxShadow: "0.2rem 0.2rem .3rem #c8d0e7, -0.3rem -0.3rem .4rem #FFFFFF",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 3,
    transition: "all .2s ease-in-out",
    marginRight: 15,
    marginBottom: 15,
    "&:hover": {
      backgroundColor: "rgba(231, 24, 55, 0.867)",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  filterItemWrapper: {
    display: "flex",
    width: "auto",
    flexDirection: "column",
    justifyContent: "flex-start",
    transition: "all .2s ease-in-out",
    borderBottom: "1.5px inset #1C6EA433",
    padding: ".2rem",
  },
  filterItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "all .2s ease-in-out",
    borderRadius: 8,
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      color: colors.redHK,
      transform: "scale(1.03)",
    },
  },
  filterCheckrow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    transition: "all .2s ease-in-out",
    color: "#3e7cc2",
    alignItems: "center",
    fontSize: ".9rem",
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      fontWeight: "bold",
    },
  },
}));

const OutlinedInputD = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#75B551 !important",
  },
}))(MuiOutlinedInput);
const StyledTableSortLabel = withStyles((theme) =>
  createStyles({
    active: {},
    icon: {
      color: "#75B551 !important",
    },
  })
)(TableSortLabel);

const CustomTextFieldL = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000088",
      },
      "&:hover fieldset": {
        borderColor: "#00000088",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000088",
      },
    },
  },
})(TextField);

const CustomTextFieldD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#49b675AA",
    },
    "& label": {
      color: "#49b675AA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#49b675AA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#49b675AA",
      },
      "&:hover fieldset": {
        borderColor: "#49b675AA",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#49b675AA",
      },
    },
  },
})(TextField);

const RedCheckbox = withStyles({
  root: {
    color: "#75B551",
    width: 10,
    height: 10,
    "&$checked": {
      color: "#49b675",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function DCuration(props) {
  const {
    setSelectedDesign,
    setLoginStatus,
    loginStatus,
    userID,
    userRole,
    userName,
    headerData,
    setHeaderData,
    isHeader,
    setIsHeader,
    theme,
    setTheme,
    cookies,
    removeCookie,
    setCartItems,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [cards, setCards] = useState("List");
  const [myDesigns, setMyDesigns] = useState([]);
  const [partnerDetails, setPartnerDetails] = useState([]);
  const [renderPartner, setRenderPartner] = React.useState("");
  const [filters, setFilters] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isFilterToggle, setIsFilterToggle] = useState(false);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueByOrder, setValueByOrder] = useState("");
  let [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const PER_PAGE = 10;
  const _DATA = usePagination(filteredData, PER_PAGE);
  const handleRequestSort = (event, property) => {
    const isAscending = valueByOrder === property && orderDirection === "asc";
    setValueByOrder(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  console.log(filteredData, "filtered DATA");
  function descendingComparator(a, b, orderBy) {
    // console.log(a.designName, b, "w");
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };
  const handleSearchData = (text) => {
    // alert(text);
    if (text) {
      const filterData1 = myDesigns.filter(
        (item, index) =>
          item.productCode.toLowerCase().includes(text.toLowerCase()) ||
          item.renderPartnerName.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredData(filterData1);
      setCount(Math.ceil(filterData1.length / PER_PAGE));
      setPage(1);
      _DATA.jump(1);
    } else {
      getDesignData();
    }
  };

  const fetchData = () => {
    let newData = [];
    filters.map((item, index) => {
      item.filterValue.map((item2, index2) => {
        if (item2.checked) {
          myDesigns.filter((item3, index2) => {
            // if (item3[item.filterName.toLowerCase()] === item2.value) {
            //   if (newData.length > 0) {
            //     newData.some((value) => value.designID !== item3.designID) &&
            //       newData.push(item3);
            //   } else {
            //     newData.push(item3);
            //   }
            // }

            if (item.filterName === "PRODUCT CODE") {
              if (item3["productCode"] === item2.value) {
                if (newData.length > 0) {
                  newData.some((value) => value.designID !== item3.designID) &&
                    newData.push(item3);
                } else {
                  newData.push(item3);
                }
              }
            }

            if (item.filterName === "3D REP") {
              if (item3["renderPartnerName"] === item2.value) {
                if (newData.length > 0) {
                  newData.some((value) => value.designID !== item3.designID) &&
                    newData.push(item3);
                } else {
                  newData.push(item3);
                }
              }
            }

            if (item.filterName === "CURRENT STATUS") {
              if (item3["message"] === item2.value) {
                if (newData.length > 0) {
                  newData.some((value) => value.designID !== item3.designID) &&
                    newData.push(item3);
                } else {
                  newData.push(item3);
                }
              }
            }
          });
        }
      });
    });

    setFilteredData(newData);
    setCount(Math.ceil(newData.length / PER_PAGE));
    setIsFilterToggle(false);
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const getDesignData = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getRenderDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: 0,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        var productCodes = [];
        var productCodesx = [];
        var productCodesxx = [];
        var reps = [];
        var repsx = [];
        var repsxx = [];
        var messages = [];
        var messagesx = [];
        var messagesxx = [];

        result.renderDetails.forEach((item) => {
          if (productCodesx.indexOf(item.productCode) == -1) {
            productCodes.push({
              value: item.productCode,
              checked: false,
              pFilter: "PRODUCT CODE",
            });
            productCodesx.push(item.productCode);
          }

          if (repsx.indexOf(item.renderPartnerName) == -1) {
            reps.push({
              value: item.renderPartnerName,
              checked: false,
              pFilter: "3D REP",
            });
            repsx.push(item.renderPartnerName);
          }

          if (messagesx.indexOf(item.message) == -1) {
            messages.push({
              value: item.message,
              checked: false,
              pFilter: "CURRENT STATUS",
            });
            messagesx.push(item.message);
          }
        });

        productCodesxx.push({
          filterName: "PRODUCT CODE",
          expand: false,
          filterValue: productCodes,
        });

        repsxx.push({
          filterName: "3D REP",
          expand: false,
          filterValue: reps,
        });

        messagesxx.push({
          filterName: "CURRENT STATUS",
          expand: false,
          filterValue: messages,
        });

        const array = [productCodesxx, repsxx, messagesxx];
        setFilters(array.flat());

        setMyDesigns(result.renderDetails);
        setFilteredData(result.renderDetails);
        setCount(Math.ceil(result.renderDetails.length / PER_PAGE));
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const get3dPartners = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/get3DPartnerList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        setPartnerDetails(result.partnerDetails);
        // setCount(Math.ceil(result.renderDetails.length / PER_PAGE));
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleChangeSelect = (event, designID) => {
    var status;
    setRenderPartner(event.target.value);
    fetch(
      "https://studioeapi.bluekaktus.com/api/design/saveTransactionDetails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userID: userID,
          designID: designID,
          renderPartner: Number(event.target.value),
          statusType: "SEND FOR 3D",
        }),
      }
    )
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((result) => {
        console.log(result);
        getDesignData();
      });
  };

  useEffect(() => {
    if (loginStatus == 1) {
      getDesignData();
      get3dPartners();
      setCartItems([]);
    }
  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="bodyNT">
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          style={{ position: "absolute" }}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}

        <div className={classes.mainContainer}>
          <motion.div
            variants={container}
            initial={{ x: "-100vw" }}
            animate={
              isFilterToggle
                ? { x: 0, opacity: 1 }
                : { x: "-100vw", opacity: 0 }
            }
            transition={{ duration: 0.3, origin: 0 }}
            className={classes.filter}
          >
            <div style={{ marginBottom: 0 }}>
              <span
                className={classes.secondaryFiltersWrapperReset}
                onClick={() => {
                  getDesignData();
                  setIsFilterToggle(false);
                }}
              >
                Reset
              </span>

              <span
                className={classes.secondaryFiltersWrapperApply}
                style={{ marginRight: 0 }}
                onClick={() => {
                  //setRepsFilter([]);
                  fetchData();
                }}
              >
                Apply
              </span>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  padding: 5,
                  zIndex: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsFilterToggle(!isFilterToggle);
                }}
              >
                <CloseIcon style={{ color: "#3e7cc2" }} />
              </div>
            </div>
            {filters.map((item, index) => (
              <div className={classes.filterItemWrapper}>
                <div
                  className={classes.filterItem}
                  onClick={() => {
                    let temp = filters;
                    temp[index].expand = !temp[index].expand;
                    // console.log("temp", temp);
                    setFilters(JSON.parse(JSON.stringify(temp)));
                  }}
                >
                  <div
                    style={{
                      textAlign: "right",
                      marginRight: "1rem",
                      marginTop: ".5rem",
                      color: "#75B551",
                      fontSize: "1.1rem",
                    }}
                  >
                    {item.filterName}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      marginTop: ".5rem",
                      color: "#75B551",
                      fontSize: "1.1rem",
                      float: "right",
                    }}
                  >
                    {item.expand ? "-" : "+"}
                  </div>
                </div>
                {item.expand && (
                  <div style={{ maxHeight: "20vh", overflowY:"auto" }}>
                    {item.filterValue.map((item, index2) => (
                      <div
                        className={classes.filterCheckrow}
                        onClick={() => {
                          var tempx = JSON.parse(JSON.stringify(filters));
                          // console.log(tempx, filters, "check");
                          tempx[index].filterValue[index2].checked =
                            !tempx[index].filterValue[index2].checked;
                          // console.log(tempx, "check2");
                          setFilters(JSON.parse(JSON.stringify(tempx)));
                          // updateData(JSON.parse(JSON.stringify(tempx)));
                        }}
                      >
                        <RedCheckbox checked={item.checked} />
                        <div
                          style={{
                            marginLeft: 10,
                            marginTop: 5,
                            color: "#75B551",
                          }}
                        >
                          {item.value}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </motion.div>
        </div>
        <div className={classes.wrapper} style={{ zIndex: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "2px 0px",
            }}
          >
            <div style={{ marginRight:0 }}>
              {theme == "light" ? (
                <CustomTextFieldL
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              ) : (
                <CustomTextFieldD
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              )}
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsFilterToggle(!isFilterToggle);
              }}
            >
              <FilterAltIcon sx={{ fontSize: "2.5rem", color: "#75B551" }} />
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              margin: "2px 0px",
            }}
          >
            <div
              style={{
                backgroundColor: cards == "List" ? "#FFFFFF" : "#00000022",
                color: cards == "List" ? "#75B551" : "#00000088",
                padding: 3,
                borderRadius: 3,
                display: "flex",
                alignItems: "center",
                marginRight: 10,
              }}
              onClick={() => setCards("List")}
            >
              <ViewHeadlineIcon fontSize="large" />
            </div>
            <div
              style={{
                backgroundColor: cards == "Card" ? "#FFFFFF" : "#00000022",
                color: cards == "Card" ? "#75B551" : "#00000088",
                padding: 3,
                borderRadius: 3,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => setCards("Card")}
            >
              <AppsIcon fontSize="large" />
            </div>
          </div> */}
          {cards == "List" ? (
            <div className={classes.tableI}>
              <div className={classes.headerRow} key={"sort"}>
                <div
                  className={classes.headerRowItem}
                  style={{ width: "20%" }}
                ></div>
                <div
                  className={classes.headerRowItem}
                  style={{ width: "20%", marginLeft: 15 }}
                >
                  Product Code
                </div>
                <div
                  className={classes.headerRowItem}
                  style={{ width: "20%", marginLeft: 15 }}
                >
                  3D Rep
                </div>
                <div
                  className={classes.headerRowItem}
                  style={{ width: "20%", textAlign: "right" }}
                >
                  Last Update
                </div>
                <StyledTableSortLabel
                  active={valueByOrder === "message"}
                  direction={
                    valueByOrder === "message" ? orderDirection : "asc"
                  }
                  onClick={createSortHandler("message")}
                  style={{ width: "20%", justifyContent: "flex-end" }}
                >
                  <div className={classes.headerRowItem} key={"Message"}>
                    Current Status
                  </div>
                </StyledTableSortLabel>
              </div>
              {sortedRowInformation(
                filteredData,
                getComparator(orderDirection, valueByOrder)
              )
                .slice((page - 1) * PER_PAGE, (page - 1) * PER_PAGE + PER_PAGE)
                .map((item, index) => {
                  return (
                    <div
                      className={
                        index % 2 == 0
                          ? classes.tableRowEven
                          : classes.tableRowOdd
                      }
                      onClick={() => {
                        if (item.message !== "Added to 3D Designs") {
                          setSelectedDesign(item.designID);
                          history.push("/view3D");
                        }
                      }}
                      key={"sort"}
                    >
                      <div
                        style={{
                          width: "20%",
                          maxHeight: "15vh",
                          marginRight: 15,
                        }}
                      >
                        <img
                          src={item.designImagePath}
                          style={{
                            height: "100%",
                            maxWidth: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div
                        className={classes.tableCell}
                        style={{ width: "20%", marginLeft: 15 }}
                      >
                        {item.productCode}
                      </div>
                      <div
                        className={classes.tableCell}
                        style={{ width: "20%", marginLeft: 15 }}
                      >
                        {item.renderPartnerName}
                      </div>
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "20%",
                          justifyContent: "flex-end",
                          textAlign: "right",
                        }}
                      >
                        {item.updatedOn}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          // fontSize: "1.2rem",
                          // color:"#000000AA",
                          marginRight: 15,
                          width: "20%",
                          textAlign: "right",
                          justifyContent: "flex-end",
                          color:
                            item.message == "3D Render Approved" ||
                            item.message == "Design Approved" ||
                            item.message == "Sent for Costing"
                              ? "#49b675"
                              : item.message == "Sent for 3D Render"
                              ? "#3e7cc2AA"
                              : item.message == "Rejected"
                              ? "#ffaa00AA"
                              : item.message == "Added to 3D Designs"
                              ? "#FFFFFF"
                              : "#F4BB4A",
                        }}
                      >
                        {item.message == "Added to 3D Designs" ? (
                          <>
                            <FormControl
                              sx={{ m: 1, minWidth: 120 }}
                              size="small"
                            >
                              <InputLabel
                                id={`label_${index}`}
                                style={{
                                  color: "#75B551",
                                }}
                              >
                                Select 3D Partner
                              </InputLabel>
                              <Select
                                labelId={`label_${index}`}
                                id={`select_${index}`}
                                // value={renderPartner}
                                label="Select 3D Partner"
                                onChange={(e) => {
                                  if (!e) var e = window.e;
                                  e.cancelBubble = true;
                                  if (e.stopPropagation) e.stopPropagation();
                                  handleChangeSelect(e, item.designID);
                                }}
                              >
                                {partnerDetails.map((item1, index2) => {
                                  return (
                                    <MenuItem id={`menu_${index}_${index2}`} value={item1.userID}>
                                      {item1.partnerName}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              fontSize: "1.8vmin",
                            }}
                          >
                            <div>
                              {item.message == "3D Render Approved" ? (
                                <DoneAllIcon
                                  style={{ color: "#49b675AA", marginRight: 5 }}
                                />
                              ) : item.message == "Design Approved" ? (
                                <DoneAllIcon
                                  style={{
                                    color: "#49b675AA",
                                    marginRight: 20,
                                  }}
                                />
                              ) : item.message == "Sent for 3D Render" ? (
                                <SendIcon
                                  style={{
                                    color: "#3e7cc2AA",
                                    marginRight: 10,
                                  }}
                                />
                              ) : item.message == "Rejected" ||
                                item.message == "Sent a message" ? (
                                <SmsFailedIcon
                                  style={{
                                    color: "#ffaa00AA",
                                    marginRight: 10,
                                  }}
                                />
                              ) : item.message == "3D Received for Approval" ? (
                                <SmsFailedIcon
                                  fontSize="medium"
                                  style={{ color: "#ffaa00AA", marginRight: 5 }}
                                />
                              ) : item.message == "Sent for Costing" ? (
                                <MonetizationOnIcon
                                  fontSize="medium"
                                  style={{ color: "#49b675AA", marginRight: 5 }}
                                />
                              ) : null}
                            </div>
                            <div>{item.message}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className={classes.cardWrapper}>
              {_DATA.currentData().map((item, index) => {
                return (
                  <motion.div
                    className={classes.card}
                    onClick={() => {
                      // setSelectedDesign(item)
                      // history.push("/viewDesignNT")
                    }}
                  >
                    <img src={item.designImagePath} style={{ width: "100%" }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        padding: 5,
                        paddingBottom: 15,
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 15,
                          color: "#000000",
                          paddingTop: 5,
                          fontSize: "1.5rem",
                        }}
                      >
                        {item.designName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 15,
                          marginTop: 5,
                          textAlign: "right",
                          fontSize: "1.2rem",
                          justifyContent: "flex-end",
                          color:
                            item.message == "Approved"
                              ? "#49b675AA"
                              : item.message == "Received For Approval"
                              ? "#3e7cc2AA"
                              : item.message == "Sent for Costing"
                              ? "#49b675AA"
                              : "#ffaa00AA",
                        }}
                      >
                        {item.message == "Approved" ||
                        item.message == "Sent for Costing" ? (
                          <DoneAllIcon
                            style={{ color: "#49b675AA", marginRight: 5 }}
                          />
                        ) : item.message == "Sent" ? (
                          <SendIcon
                            style={{ color: "#3e7cc2AA", marginRight: 5 }}
                          />
                        ) : item.message == "Alert" ? (
                          <SmsFailedIcon
                            style={{ color: "#ffaa00AA", marginRight: 5 }}
                          />
                        ) : null}
                        {item.currentStatus}
                      </div>
                      <div
                        style={{
                          alignSelf: "flex-end",
                          marginRight: 10,
                          color: "#00000088",
                        }}
                      >
                        {item.updatedOn}
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          )}
          <div className={classes.tableWrapperRow}>
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
