import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Link, useHistory } from "react-router-dom";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SendIcon from "@mui/icons-material/Send";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import "../stylesheets/FreelancerNT.css";
import AppsIcon from "@mui/icons-material/Apps";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Header from "../components/header";
import Notification from "../components/Notification";
import { Pagination } from "@material-ui/lab";
import usePagination from "../hooks/Pagination";
import Checkbox from "@mui/material/Checkbox";
import colors from "../config/colors";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { TextField } from "@material-ui/core";
import { TableSortLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 50px",
  },
  tableI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    border: "1.5px solid #00000033",
    maxHeight: "68vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    padding: 10,
    borderBottom: "1.5px solid #00000055",
    position: "sticky",
    top: 0,
  },
  headerRowItem: {
    color: "#75B551",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginRight: 15,
  },
  tableRowEven: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    height: "12vh",
    backgroundColor: "#BBBBBB66",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  tableRowOdd: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    height: "12vh",
    backgroundColor: "#EEEEEEAA",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF88",
    },
  },
  tableCell: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.8vmin",
    color: "#000000AA",
    marginRight: 15,
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    maxHeight: "80vh",
    flexWrap: "wrap",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "40vh",
    width: "25%",
    backgroundColor: "#FFFFFF",
    margin: 8,
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "3px solid #75B551AA",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#75B551AA",
    },
  },
  tableWrapperRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "2.5px 5px",
    width: "100%",
    justifyContent: "center",
    marginTop: 10,
    // border:"2px solid blue",
  },
  mainContainer: {
    position: "relative",
    // border: "2px solid black",
  },
  filter: {
    position: "absolute",
    maxHeight: "60vh",
    backgroundColor: "#FFFFFF",
    padding: "1rem",
    overflowY: "auto",
    borderRadius: "0px 8px 8px 0px",
    zIndex: 200,
    display: "flex",
    flexDirection: "column",
  },
  secondaryFiltersWrapperApply: {
    float: "left",
    color: "#FFFFFF",
    fontSize: "1.1rem",
    backgroundColor: "#75B551",
    backdropFilter: "blur(5px)",
    borderRadius: "1rem",
    boxShadow: "0.2rem 0.2rem .3rem #c8d0e7, -0.3rem -0.3rem .4rem #FFFFFF",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 3,
    marginBottom: 15,
    transition: "all .2s ease-in-out",
    "&:hover": {
      backgroundColor: "#75B551",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  secondaryFiltersWrapperReset: {
    float: "left",
    color: "#FFFFFF",
    fontSize: "1.1rem",
    backgroundColor: "rgba(231, 24, 55, 0.867)",
    backdropFilter: "blur(5px)",
    borderRadius: "1rem",
    boxShadow: "0.2rem 0.2rem .3rem #c8d0e7, -0.3rem -0.3rem .4rem #FFFFFF",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 3,
    transition: "all .2s ease-in-out",
    marginRight: 15,
    marginBottom: 15,
    "&:hover": {
      backgroundColor: "rgba(231, 24, 55, 0.867)",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  filterItemWrapper: {
    display: "flex",
    width: "auto",
    flexDirection: "column",
    justifyContent: "flex-start",
    transition: "all .2s ease-in-out",
    borderBottom: "1.5px inset #1C6EA433",
    padding: ".2rem",
  },
  filterItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "all .2s ease-in-out",
    borderRadius: 8,
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      color: colors.redHK,
      transform: "scale(1.03)",
    },
  },
  filterCheckrow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    transition: "all .2s ease-in-out",
    color: "#3e7cc2",
    alignItems: "center",
    fontSize: ".9rem",
    marginTop: 5,
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      fontWeight: "bold",
    },
  },
}));

const RedCheckbox = withStyles({
  root: {
    color: "#75B551",
    width: 10,
    height: 10,
    "&$checked": {
      color: "#49b675",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyledTableSortLabel = withStyles((theme) =>
  createStyles({
    active: {},
    icon: {
      color: "#75B551 !important",
    },
  })
)(TableSortLabel);

const CustomTextFieldL = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000088",
      },
      "&:hover fieldset": {
        borderColor: "#00000088",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000088",
      },
    },
  },
})(TextField);

const CustomTextFieldD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#49b675AA",
    },
    "& label": {
      color: "#49b675AA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#49b675AA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#49b675AA",
      },
      "&:hover fieldset": {
        borderColor: "#49b675AA",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#49b675AA",
      },
    },
  },
})(TextField);

export default function MyDesignsNT(props) {
  const {
    loginStatus,
    userID,
    userRole,
    userName,
    setSelectedDesign,
    headerData,
    setHeaderData,
    isHeader,
    setIsHeader,
    setLoginStatus,
    theme,
    setTheme,
    cookies,
    removeCookie,
  } = props;
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueByOrder, setValueByOrder] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const [cards, setCards] = useState("List");
  const [myDesigns, setMyDesigns] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isFilterToggle, setIsFilterToggle] = useState(false);
  const [filters, setFilters] = useState([]);
  const [dNameDir, setDNameDir] = useState(null);
  const [cStatusDir, setCStatusDir] = useState(null);

  // const [filters, setFilters] = useState([]);
  // const [filters, setFilters] = useState([]);

  let [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const PER_PAGE = 10;
  const _DATA = usePagination(filteredData, PER_PAGE);
  const handleRequestSort = (event, property) => {
    const isAscending = valueByOrder === property && orderDirection === "asc";
    setValueByOrder(property);
    setOrderDirection(isAscending ? "desc" : "asc");
    if (property === "designName") {
      setDNameDir("asc");
      setCStatusDir(null);
    }
    if (property === "message") {
      setDNameDir(null);
      setCStatusDir("asc");
    }
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  function descendingComparator(a, b, orderBy) {
    console.log(a.designName, b, "w");
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };
  const getDesignData = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getBasicDesignInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: 0,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        var types = [];
        var typesx = [];
        var typesxx = [];
        var genders = [];
        var gendersx = [];
        var gendersxx = [];
        var ages = [];
        var agesx = [];
        var agesxx = [];
        var categorys = [];
        var categorysx = [];
        var categorysxx = [];
        var products = [];
        var productsx = [];
        var productsxx = [];
        var fits = [];
        var fitsx = [];
        var fitsxx = [];
        var fabrics = [];
        var fabricsx = [];
        var fabricsxx = [];
        var messages = [];
        var messagesx = [];
        var messagesxx = [];

        result.designDetails.forEach((item) => {
          if (typesx.indexOf(item.type) == -1) {
            types.push({
              filterID: item.typeID,
              value: item.type,
              checked: false,
              pFilter: "TYPE",
            });
            typesx.push(item.type);
          }

          if (gendersx.indexOf(item.gender) == -1) {
            genders.push({
              filterID: item.genderID,
              value: item.gender,
              checked: false,
              pFilter: "GENDER",
            });
            gendersx.push(item.gender);
          }

          if (agesx.indexOf(item.age) == -1) {
            ages.push({
              filterID: item.ageID,
              value: item.age,
              checked: false,
              pFilter: "AGE",
            });
            agesx.push(item.age);
          }

          if (categorysx.indexOf(item.category) == -1) {
            categorys.push({
              filterID: item.categoryID,
              value: item.category,
              checked: false,
              pFilter: "CATEGORY",
            });
            categorysx.push(item.category);
          }

          if (productsx.indexOf(item.product) == -1) {
            products.push({
              filterID: item.productID,
              value: item.product,
              checked: false,
              pFilter: "PRODUCT",
            });
            productsx.push(item.product);
          }

          if (fitsx.indexOf(item.fit) == -1) {
            fits.push({
              filterID: item.fitID,
              value: item.fit,
              checked: false,
              pFilter: "FIT",
            });
            fitsx.push(item.fit);
          }

          if (fabricsx.indexOf(item.fabric) == -1) {
            fabrics.push({
              filterID: item.fabricID,
              value: item.fabric,
              checked: false,
              pFilter: "FABRIC",
            });
            fabricsx.push(item.fabric);
          }

          if (messagesx.indexOf(item.message) == -1) {
            messages.push({
              value: item.message,
              checked: false,
              pFilter: "CURRENT STATUS",
            });
            messagesx.push(item.message);
          }
        });

        typesxx.push({
          filterName: "TYPE",
          expand: false,
          filterValue: types,
        });

        gendersxx.push({
          filterName: "GENDER",
          expand: false,
          filterValue: genders,
        });

        agesxx.push({
          filterName: "AGE",
          expand: false,
          filterValue: ages,
        });

        categorysxx.push({
          filterName: "CATEGORY",
          expand: false,
          filterValue: categorys,
        });

        productsxx.push({
          filterName: "PRODUCT",
          expand: false,
          filterValue: products,
        });

        fitsxx.push({
          filterName: "FIT",
          expand: false,
          filterValue: fits,
        });

        fabricsxx.push({
          filterName: "FABRIC",
          expand: false,
          filterValue: fabrics,
        });

        messagesxx.push({
          filterName: "CURRENT STATUS",
          expand: false,
          filterValue: messages,
        });
        // console.log(result);
        const array = [
          typesxx,
          gendersxx,
          agesxx,
          categorysxx,
          productsxx,
          fitsxx,
          fabricsxx,
          messagesxx,
        ];
        setFilters(array.flat());
        // setType(typesxx);
        // setGender(gendersxx);
        // setAge(agesxx);
        // setCategory(categorysxx);
        // setproduct(productsxx);
        // setFit(fitsxx);
        // setFabric(fabricsxx);
        setMyDesigns(result.designDetails);
        setFilteredData(result.designDetails);
        setCount(Math.ceil(result.designDetails.length / PER_PAGE));
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const handleSearchData = (text) => {
    // alert(text);
    if (text) {
      const filterData1 = myDesigns.filter((item, index) =>
        item.designName.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredData(filterData1);
      setCount(Math.ceil(filterData1.length / PER_PAGE));
    } else {
      getDesignData();
    }
  };

  const fetchData = () => {
    let newData = [];
    filters.map((item, index) => {
      item.filterValue.map((item2, index2) => {
        if (item2.checked) {
          myDesigns.filter((item3, index2) => {
            if (item3[item.filterName.toLowerCase()] === item2.value) {
              if (newData.length > 0) {
                newData.some((value) => value.designID !== item3.designID) &&
                  newData.push(item3);
              } else {
                newData.push(item3);
              }
            }

            if (item.filterName === "CURRENT STATUS") {
              if (item3["message"] === item2.value) {
                if (newData.length > 0) {
                  newData.some((value) => value.designID !== item3.designID) &&
                    newData.push(item3);
                } else {
                  newData.push(item3);
                }
              }
            }
          });
        }
      });
    });

    setFilteredData(newData);
    setCount(Math.ceil(newData.length / PER_PAGE));
    setIsFilterToggle(false);
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    if (loginStatus == 1) {
      getDesignData();
    }
  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="bodyNT">
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          style={{ position: "absolute" }}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}

        <div className={classes.mainContainer}>
          <motion.div
            variants={container}
            initial={{ x: "-100vw" }}
            animate={
              isFilterToggle
                ? { x: 0, opacity: 1 }
                : { x: "-100vw", opacity: 0 }
            }
            transition={{ duration: 0.3, origin: 0 }}
            className={classes.filter}
          >
            <div style={{ marginBottom: 0 }}>
              <span
                className={classes.secondaryFiltersWrapperReset}
                onClick={() => {
                  getDesignData();
                  setIsFilterToggle(false);
                }}
              >
                Reset
              </span>

              <span
                className={classes.secondaryFiltersWrapperApply}
                style={{ marginRight: 0 }}
                onClick={() => {
                  //setRepsFilter([]);
                  fetchData();
                }}
              >
                Apply
              </span>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  padding: 5,
                  zIndex: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsFilterToggle(!isFilterToggle);
                }}
              >
                <CloseIcon style={{ color: "#3e7cc2" }} />
              </div>
            </div>
            {filters.map((item, index) => (
              <div className={classes.filterItemWrapper}>
                <div
                  className={classes.filterItem}
                  onClick={() => {
                    let temp = filters;
                    temp[index].expand = !temp[index].expand;
                    // console.log("temp", temp);
                    setFilters(JSON.parse(JSON.stringify(temp)));
                  }}
                >
                  <div
                    style={{
                      textAlign: "right",
                      marginRight: "1rem",
                      marginTop: ".5rem",
                      color: "#75B551",
                      fontSize: "1.1rem",
                    }}
                  >
                    {item.filterName}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      marginTop: ".5rem",
                      color: "#75B551",
                      fontSize: "1.1rem",
                      float: "right",
                    }}
                  >
                    {item.expand ? "-" : "+"}
                  </div>
                </div>
                {item.expand && (
                  <div style={{ maxHeight: "20vh", overflowY:"auto" }}>
                    {item.filterValue.map((item, index2) => (
                      <div
                        className={classes.filterCheckrow}
                        onClick={() => {
                          var tempx = JSON.parse(JSON.stringify(filters));
                          // console.log(tempx, filters, "check");
                          tempx[index].filterValue[index2].checked =
                            !tempx[index].filterValue[index2].checked;
                          // console.log(tempx, "check2");
                          setFilters(JSON.parse(JSON.stringify(tempx)));
                          // updateData(JSON.parse(JSON.stringify(tempx)));
                        }}
                      >
                        <RedCheckbox checked={item.checked} />
                        <div
                          style={{
                            marginLeft: 10,
                            // marginTop: 5,
                            color: "#75B551",
                          }}
                        >
                          {item.value}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </motion.div>
        </div>
        <div className={classes.wrapper} style={{ zIndex: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "2px 0px",
            }}
          >
            {/* <div style={{ marginRight: 80 }}>
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 35,
                    color: "#75B551",
                    "&$checked": {
                      color: "#49b675",
                    },
                  },
                }}
              />{" "}
              View All Designs
            </div> */}
            <div style={{ marginRight: 0 }}>
              {theme == "light" ? (
                <CustomTextFieldL
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              ) : (
                <CustomTextFieldD
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              )}
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsFilterToggle(!isFilterToggle);
              }}
            >
              <FilterAltIcon sx={{ fontSize: "2.5rem", color: "#75B551" }} />
            </div>
          </div>
          {cards == "List" ? (
            <div className={classes.tableI}>
              <div className={classes.headerRow} key={"sort"}>
                <div
                  className={classes.headerRowItem}
                  style={{ width: "25%" }}
                ></div>
                <StyledTableSortLabel
                  active={valueByOrder === "designName"}
                  direction={
                    valueByOrder === "designName" ? orderDirection : "asc"
                  }
                  onClick={createSortHandler("designName")}
                  style={{ width: "25%", marginLeft: 15 }}
                >
                  <div key={"designName"} className={classes.headerRowItem}>
                    Design Name
                  </div>
                  {dNameDir === null && "-"}
                </StyledTableSortLabel>
                <div
                  className={classes.headerRowItem}
                  style={{ width: "15%", textAlign: "right" }}
                >
                  Created On
                </div>
                <div
                  className={classes.headerRowItem}
                  style={{ width: "15%", textAlign: "right" }}
                >
                  Last Update
                </div>
                <StyledTableSortLabel
                  active={valueByOrder === "message"}
                  direction={
                    valueByOrder === "message" ? orderDirection : "asc"
                  }
                  onClick={createSortHandler("message")}
                  style={{ width: "20%", justifyContent: "flex-end" }}
                >
                  <div className={classes.headerRowItem}>Current Status</div>
                  {cStatusDir === null && "-"}
                </StyledTableSortLabel>
              </div>
              {sortedRowInformation(
                filteredData,
                getComparator(orderDirection, valueByOrder)
              )
                .slice((page - 1) * PER_PAGE, (page - 1) * PER_PAGE + PER_PAGE)
                .map((item, index) => {
                  return (
                    <div
                      className={
                        index % 2 == 0
                          ? classes.tableRowEven
                          : classes.tableRowOdd
                      }
                      onClick={() => {
                        setSelectedDesign(item.designID);
                        history.push("/viewDesignNT");
                      }}
                      key={"sort"}
                    >
                      <div
                        style={{
                          width: "25%",
                          maxHeight: "15vh",
                          marginRight: 15,
                        }}
                      >
                        {item.thumbnailDesignImagePath === "NO IMAGE" ? (
                          <img
                            src={item.designImagePath}
                            style={{
                              height: "100%",
                              maxWidth: "100%",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <img
                            src={item.thumbnailDesignImagePath}
                            style={{
                              height: "100%",
                              maxWidth: "100%",
                              objectFit: "contain",
                            }}
                          />
                        )}
                      </div>
                      <div
                        className={classes.tableCell}
                        style={{ width: "25%", marginLeft: 15 }}
                      >
                        {item.designName}
                      </div>
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "15%",
                          justifyContent: "flex-end",
                          textAlign: "right",
                        }}
                      >
                        {item.createdOn}
                      </div>
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "15%",
                          justifyContent: "flex-end",
                          textAlign: "right",
                        }}
                      >
                        {item.updatedOn}
                      </div>
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "20%",
                          textAlign: "right",
                          justifyContent: "flex-end",
                          color:
                            item.message == "Design Approved" ||
                            item.message == "Sent for Costing"
                              ? "#49b675"
                              : item.message == "Sent for Approval"
                              ? "#3e7cc2"
                              : item.message == "Design Rejected"
                              ? "#e71837DD"
                              : "#ffaa00",
                        }}
                      >
                        {item.message == "Design Approved" ? (
                          <DoneAllIcon
                            fontSize="large"
                            style={{ color: "#49b675DD", marginRight: 5 }}
                          />
                        ) : item.message == "Sent for Approval" ? (
                          <SendIcon
                            fontSize="large"
                            style={{ color: "#3e7cc2DD", marginRight: 5 }}
                          />
                        ) : item.message == "Sent for Costing" ? (
                          <MonetizationOnIcon
                            fontSize="large"
                            style={{ color: "#49b675DD", marginRight: 5 }}
                          />
                        ) : item.message == "Design Rejected" ? (
                          <NewReleasesIcon
                            fontSize="large"
                            style={{ color: "#e71837DD", marginRight: 5 }}
                          />
                        ) : item.message == "Feedback Received" ? (
                          <SmsFailedIcon
                            style={{ color: "#ffaa00AA", marginRight: 5 }}
                          />
                        ) : null}
                        {item.message}
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className={classes.cardWrapper}>
              {_DATA.currentData().map((item, index) => {
                return (
                  <motion.div
                    className={classes.card}
                    onClick={() => {
                      setSelectedDesign(item);
                      history.push("/viewDesignNT");
                    }}
                    key={"sort"}
                  >
                    <img src={item.designImagePath} style={{ width: "100%" }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        padding: 5,
                        paddingBottom: 15,
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 15,
                          color: "#000000",
                          paddingTop: 5,
                          fontSize: "1.5rem",
                        }}
                      >
                        {item.designName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 15,
                          marginTop: 5,
                          textAlign: "right",
                          fontSize: "1.2rem",
                          justifyContent: "flex-end",
                          color:
                            item.message == "Approved"
                              ? "#49b675AA"
                              : item.message == "Sent"
                              ? "#3e7cc2AA"
                              : "#ffaa00AA",
                        }}
                      >
                        {item.message == "Approved" ? (
                          <DoneAllIcon
                            style={{ color: "#49b675AA", marginRight: 5 }}
                          />
                        ) : item.message == "Sent" ? (
                          <SendIcon
                            style={{ color: "#3e7cc2AA", marginRight: 5 }}
                          />
                        ) : item.message == "Alert" ? (
                          <SmsFailedIcon
                            style={{ color: "#ffaa00AA", marginRight: 5 }}
                          />
                        ) : null}
                        {item.currentStatus}
                      </div>
                      <div
                        style={{
                          alignSelf: "flex-end",
                          marginRight: 10,
                          color: "#00000088",
                        }}
                      >
                        {item.updatedOn}
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          )}
          <div className={classes.tableWrapperRow}>
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
