import React, { useState, useEffect, useMemo } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import "../stylesheets/FreelancerNT.css";
import toast, { Toaster } from "react-hot-toast";
import Avatar from "@mui/material/Avatar";
import { Password } from "@mui/icons-material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import "../stylesheets/login.css";
import NativeSelect from "@mui/material/NativeSelect";

var w = window.innerWidth;
var h = window.innerHeight;

const OutlinedInputL = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#00000088 !important",
  },
}))(MuiOutlinedInput);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "5vh 0vh",
  },
  inputWrapper: {
    width: "60%",
    height: "500px",
    display: "flex",
    flexDirection: "column",
    top: 10,
    // padding: 20,
    borderRadius: 8,
    background: "#FFFFFF",
    // boxShadow: "#75B551 0px 1px 4px",
    //boxShadow: "0.2rem 0.2rem .3rem #88ddd8, -0.3rem -0.3rem .4rem #88ddd8",
  },

  inputForm: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(158, 225, 226, .40)",
    backdropFilter: "blur(5px)",
    borderRadius: 10,
    padding: 16,
  },
  imagePicker: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  selection: {
    width: "100%",
  },
  select: {
    color: "#000000",
  },
  icon: {
    color: "#0000088",
  },
  submit: {
    padding: "10px",
    background: "linear-gradient(-45deg,#75B551,#86c662,#96d374,#a2d982)",
    color: "#FFFFFF",
    borderRadius: 5,

    fontSize: "1.2rem",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  signUp: {
    fontSize: "1.9rem",
    transition: "all .2s ease-in-out",
    textAlign: "center",
    fontWeight: "bold",
    opacity: 0.7,
    padding: 8,
  },
}));

const OutlinedInput = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#00000088 !important",
  },
}))(MuiOutlinedInput);

const CustomTextField = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#00000088",
    },
  },
})(TextField);

const baseStyle = {
  borderWidth: 2,
  borderRadius: "50%",
  // borderColor: "#00000088",
  borderStyle: "solid",
  backgroundColor: "#FFFFFFAA",
  color: "#75B551",
  height: "220px",
  width: "220px",
  transition: "border .24s ease-in-out",
};

const baseStyleResume = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#00000088",
  borderStyle: "dashed",
  backgroundColor: "#FFFFFFAA",
  color: "#75B551",
  height: "90%",
  fontSize: "1.2rem",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#00000088",
};

const acceptStyle = {
  borderColor: "#00000088",
};

export default function SignUp(props) {
  const classes = useStyles();
  const history = useHistory();

  const {
    notifications,
    setNotifications,
    myDesigns,
    setMyDesigns,
    loginStatus,
  } = props;

  const [files, setFiles] = useState([]);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [portfolio, setPortfolio] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [roleId, setRoleId] = React.useState(null);
  const [userRoles, setUserRoles] = useState([]);

  const getUserDropdown = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getRolesDropdown", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({
      // }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result.loginDetails, "Roles");
        setUserRoles(result.loginDetails);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    getUserDropdown();
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isFocused, isDragAccept]
  );

  const styleResume = useMemo(
    () => ({
      ...baseStyleResume,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isFocused, isDragAccept]
  );

  const notify = (type, message) => {
    if (type === "success") {
      toast.success(message, {
        iconTheme: {
          primary: "#0047AB",
          secondary: "#FFFAEE",
        },
      });
      //toast(message);
    }
    if (type === "submit") {
      toast("Design has been Sent for Review");
    }
    if (type === "error") {
      toast.error(message);
    }
  };

  const submit = () => {
    const validRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const urlPattern =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    if (name == "") {
      notify("error", "Please Enter First Name");
      return;
    } else if (lastname == "") {
      notify("error", "Please Enter Last Name");
      return;
    } else if (email == "") {
      notify("error", "Please Enter Email");
      return;
    } else if (!email.match(validRegex)) {
      notify("error", "Please Enter Valid Email");
      return;
    } else if (password == "") {
      notify("error", "Please Enter Password");
      return;
    } else if (confirmPassword == "") {
      notify("error", "Please Enter Confirm Password");
      return;
    }
    //  else if (portfolio == "") {
    //   notify("error", "Please Enter Portfolio Link");
    //   return;
    // } else if (!portfolio.match(urlPattern)) {
    //   notify("error", "Please Enter Valid Portfolio Link");
    //   return;
    // }
    else if (roleId == null) {
      notify("error", "Please Select Role");
      return;
    } else if (password !== confirmPassword) {
      notify("error", "Password do not matches.");
    } else if (files.length === 0) {
      notify("error", "Please upload profile picture.");
    } else {
      var status;
      var formdata1 = new FormData();
      var submitObj = {
        firstName: name,
        lastName: lastname,
        email: email,
        password: password,
        portfolioURL: portfolio,
        roleID: roleId,
      };
      formdata1.append("json", JSON.stringify(submitObj));

      formdata1.append("attachment", files[0]);

      fetch("https://studioeapi.bluekaktus.com/api/design/saveSEUser", {
        method: "POST",
        body: formdata1,
      })
        .then((response) => {
          status = response.status;
          console.log(response, "status");
          if (status === 400) {
          }
          return response.json();
        })
        .then((result) => {
          if (status == 200) {
            setFiles([]);
            setName("");
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            setPortfolio("");
            setLastname("");
            setRoleId(null);
            notify("success", "Creation request submitted.");
            notify(
              "success",
              "Your account is pending review. You will recieve a conformation mail on your registered email. In case of issues please contact support."
            );
          } else {
            notify("error", result.Message);
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.Message
          ) {
            notify("error", err.response.data.Message);
          } else {
            notify("error", "Invalid Details");
          }
          return Promise.reject(err);
        });
    }
  };

  return (
    <div className="bodyLogin">
      <Toaster
        position="bottom-right"
        toastOptions={{
          // Define default options
          className: "",
          duration: 7000,
          style: {
            color: " #FFFFFF",
            background: "#75B551DD",
          },
        }}
      />

      <div className={classes.wrapper}>
        <div className={classes.inputWrapper}>
          {/* <div className={classes.logoForm}>
      <img src="https://www.linkpicture.com/q/uDHZCl8-1.png" style={{height:"50%", width:"100%"}}/>
     
      </div> */}

          <div className={classes.inputForm}>
            <div style={{ height: "10%" }}>
              <div className={classes.signUp}>Sign Up</div>
            </div>
            <div className={classes.imagePicker}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                  alignItems: "center",
                  marginBottom: "70px",
                }}
              >
                {files.length == 0 ? (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <Avatar
                      style={{ height: "220px", width: "220px" }}
                      alt="S"
                      src=""
                    />
                  </div>
                ) : (
                  <div {...getRootProps({ style })} className={classes.image}>
                    <img
                      src={files[0].preview}
                      style={{
                        height: "220px",
                        width: "220px",
                        objectFit: "contain",
                        borderRadius: "50%",
                        borderColor: "#00000088",
                      }}
                      // Revoke data uri after image is loaded
                      onLoad={() => {
                        URL.revokeObjectURL(files[0].preview);
                      }}
                    />
                    <input {...getInputProps()} />
                  </div>
                )}
                <div
                  style={{
                    marginTop: "10px",
                    transition: "all .2s ease-in-out",
                  }}
                >
                  Upload Profile Picture
                </div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "center",
                    marginTop: "40px",
                  }}
                  onClick={() => submit()}
                >
                  <div className={classes.submit}>SIGN UP</div>
                </div>
              </div>
              <div
                style={{
                  padding: 8,
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                  alignItems: "flex-start",
                  marginLeft: "50px",
                  top: 0,
                }}
              >
                <div style={{ width: "85%", marginBottom: 10 }}>
                  <CustomTextField
                    style={{ flex: 1 }}
                    fullWidth
                    label="First Name*"
                    variant="standard"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div style={{ width: "85%", marginBottom: 10 }}>
                  <CustomTextField
                    style={{ flex: 1 }}
                    fullWidth
                    label="Last Name*"
                    variant="standard"
                    value={lastname}
                    onChange={(e) => {
                      setLastname(e.target.value);
                    }}
                  />
                </div>
                <div style={{ width: "85%", marginBottom: 10 }}>
                  <CustomTextField
                    style={{ flex: 1 }}
                    fullWidth
                    label="Email*"
                    variant="standard"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <div style={{ width: "85%", marginBottom: 10 }}>
                  <CustomTextField
                    type="password"
                    style={{ flex: 1 }}
                    fullWidth
                    label="Password*"
                    variant="standard"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                <div style={{ width: "85%", marginBottom: 10 }}>
                  <CustomTextField
                    type="password"
                    style={{ flex: 1 }}
                    fullWidth
                    label="Confirm Password*"
                    variant="standard"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </div>
                <div style={{ width: "85%", marginBottom: 10 }}>
                  <CustomTextField
                    style={{ flex: 1 }}
                    fullWidth
                    label="Portfolio Link*"
                    variant="standard"
                    value={portfolio}
                    onChange={(e) => {
                      setPortfolio(e.target.value);
                    }}
                  />
                </div>
                <div style={{ width: "85%", marginBottom: 10 }}>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%", marginBottom: 10 }}
                  >
                    <InputLabel
                      style={{
                        color: "#00000088",
                      }}
                    >
                      User Role*
                    </InputLabel>
                    <Select
                      classes={{
                        select: classes.select,
                        icon: classes.icon,
                        root: classes.root,
                      }}
                      className={classes.border}
                      label="User Role*"
                      value={roleId}
                      onChange={(e) => setRoleId(e.target.value)}
                    >
                      {userRoles.map((item, index) => {
                        return (
                          <MenuItem value={item.roleID}>{item.role}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>

                {/* <div style={{ width: "85%", marginBottom: 10 ,color:"#75B551"}}>
                Upload Resume
                <br />
                <FileUpload value={resumeFile} onChange={setResumeFile} accept=".pdf,.doc" style={classes.fileUpload} />
                <br />
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
