import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Link, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import InfoIcon from "@mui/icons-material/Info";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import "../stylesheets/FreelancerNT.css";
import { light, dark } from "../ui/Toggletheme";
import AppsIcon from "@mui/icons-material/Apps";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Header from "../components/header";
import { Pagination } from "@material-ui/lab";
import usePagination from "../hooks/Pagination";
import Notification from "../components/Notification";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Checkbox from "@mui/material/Checkbox";
import colors from "../config/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 50px",
  },
  tableI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    border: "1.5px solid #00000033",
    maxHeight: "68vh",
    marginTop: 10,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    padding: 10,
    borderBottom: "1.5px solid #00000055",
    position: "sticky",
    top: 0,
  },
  headerRowItem: {
    color: "#75B551",
    fontSize: "1.1rem",
    fontWeight: "bold",
    marginRight: 15,
  },
  tableRowEven: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    height: "12vh",
    backgroundColor: "#BBBBBB66",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  tableRowOdd: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    height: "12vh",
    backgroundColor: "#EEEEEEAA",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF88",
    },
  },
  tableCell: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.8vmin",
    color: "#000000AA",
    marginRight: 15,
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    maxHeight: "80vh",
    flexWrap: "wrap",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "40vh",
    width: "25%",
    backgroundColor: "#FFFFFF",
    margin: 8,
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "3px solid #75B551AA",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#75B551AA",
    },
  },
  submit: {
    padding: "6px",
    color: "#FFFFFF",
    borderRadius: 5,
    marginLeft: 10,
    textAlign: "center",
    transition: "all .2s ease-in-out",
    backgroundColor: "#75B55188",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#75B551",
      transform: "scale(1.03)",
    },
  },
  submitdisabled: {
    padding: "10px 20px",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: "1.1rem",
    marginLeft: 10,
    textAlign: "center",
    transition: "all .2s ease-in-out",
    backgroundColor: "#99999988",
    "&:hover": {
      cursor: "not-allowed",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  submitx: {
    padding: "5px 10px",
    color: "#00000088",
    borderRadius: 5,
    // fontSize: ".9rem",
    marginTop: 5,
    transition: "all .2s ease-in-out",
    // backgroundColor:"#75B55188",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#75B551",
      transform: "scale(1.03)",
    },
  },
  tableWrapperRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "2.5px 5px",
    width: "100%",
    justifyContent: "center",
    marginTop: 10,
    // border:"2px solid blue",
  },
  filterss: {
    position: "absolute",
    maxHeight: "60vh",
    backgroundColor: "#FFFFFF",
    padding: "1rem",
    overflowY: "auto",
    width: 180,
    borderRadius: "0px 8px 8px 0px",
    zIndex: 200,
    display: "flex",
    flexDirection: "column",
  },
  secondaryFiltersWrapperApply: {
    float: "left",
    color: "#FFFFFF",
    fontSize: "1.1rem",
    backgroundColor: "#75B551",
    backdropFilter: "blur(5px)",
    borderRadius: "1rem",
    boxShadow: "0.2rem 0.2rem .3rem #c8d0e7, -0.3rem -0.3rem .4rem #FFFFFF",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 3,
    marginBottom: 15,
    transition: "all .2s ease-in-out",
    "&:hover": {
      backgroundColor: "#75B551",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  secondaryFiltersWrapperReset: {
    float: "left",
    color: "#FFFFFF",
    fontSize: "1.1rem",
    backgroundColor: "rgba(231, 24, 55, 0.867)",
    backdropFilter: "blur(5px)",
    borderRadius: "1rem",
    boxShadow: "0.2rem 0.2rem .3rem #c8d0e7, -0.3rem -0.3rem .4rem #FFFFFF",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 3,
    transition: "all .2s ease-in-out",
    marginRight: 15,
    marginBottom: 15,
    "&:hover": {
      backgroundColor: "rgba(231, 24, 55, 0.867)",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  filterItemWrapper: {
    display: "flex",
    width: "auto",
    flexDirection: "column",
    justifyContent: "flex-start",
    transition: "all .2s ease-in-out",
    borderBottom: "1.5px inset #1C6EA433",
    padding: ".2rem",
  },
  filterItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "all .2s ease-in-out",
    borderRadius: 8,
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      color: colors.redHK,
      transform: "scale(1.03)",
    },
  },
  filterCheckrow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    transition: "all .2s ease-in-out",
    color: "#3e7cc2",
    alignItems: "center",
    fontSize: ".9rem",
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      fontWeight: "bold",
    },
  },
  mainContainer: {
    position: "relative",
    // border: "2px solid black",
  },
  filter: {
    position: "absolute",
    maxHeight: "70vh",
    width: "50%",
    backgroundColor: "#FFFFFF",
    padding: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    borderRadius: "8px",
    zIndex: 120,
    boxShadow: "#75B551 0px 1px 4px",
  },
  historyWrap: {
    position: "absolute",
    height: "40vh",
    width: "60%",
    backgroundColor: "#FFFFFF",
    padding: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    borderRadius: "8px",
    zIndex: 120,
    boxShadow: "#75B551 0px 1px 4px",
  },
  factoryList: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    alignContent: "center",
    marginTop: 10,
    maxHeight: "55vh",
    overflow: "auto",
    paddingBottom: 10,
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  factoryCellU: {
    display: "flex",
    width: "92%",
    margin: 6,
    padding: 6,
    color: "#75B551",
    boxShadow: "#75B551 0px 1.3px 4px",
    borderRadius: 4,
    fontSize: "1.2rem",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  factoryCellS: {
    display: "flex",
    margin: 6,
    width: "92%",
    padding: 6,
    color: "#ffffff",
    backgroundColor: "#75B551",
    boxShadow: "#75B551 0px 1.3px 4px",
    borderRadius: 4,
    fontSize: "1.2rem",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
}));

const RedCheckbox = withStyles({
  root: {
    color: "#75B551",
    width: 10,
    height: 10,
    "&$checked": {
      color: "#49b675",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomTextFieldL = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000088",
      },
      "&:hover fieldset": {
        borderColor: "#00000088",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000088",
      },
    },
  },
})(TextField);

const CustomTextFieldD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#49b675AA",
    },
    "& label": {
      color: "#49b675AA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#49b675AA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#49b675AA",
      },
      "&:hover fieldset": {
        borderColor: "#49b675AA",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#49b675AA",
      },
    },
  },
})(TextField);

export default function SendForCosting(props) {
  const {
    theme,
    setTheme,
    loginStatus,
    setLoginStatus,
    userID,
    userRole,
    userName,
    headerData,
    setHeaderData,
    isHeader,
    setIsHeader,
    cookies,
    removeCookie,
    loginID,
  } = props;

  const headvariantslight = {
    initials: {
      color: "black",
    },
    whileHover: {
      color: light.textColor,
      backgroundColor: "#75B551",
    },
    animate: {
      color: "black",
      duration: 0.01,
    },
  };
  const headvariantsdark = {
    initals: {
      color: dark.textColor,
    },
    whileHover: {
      color: dark.textColor,
      backgroundColor: "#FFFFFF88",
    },
    animate: {
      color: dark.textColor,
      duration: 0.01,
    },
  };

  const classes = useStyles();
  const Wrap = useHistory();
  const [cards, setCards] = useState("List");
  const [myDesigns, setMyDesigns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [factories, setFactories] = useState([]);
  const [filtFactories, setFiltFactories] = useState([]);
  const [costingSourcingMerchantList, setCostingSourcingMerchantList] =
    useState([]);
  const [allCostingSourcingMerchantList, setAllCostingSourcingMerchantList] =
    useState([]);
  const [costingHistory, setCostingHistory] = useState([]);
  const [selectedFacory, setSelectedFactory] = useState(null);
  const [costingSourcingMerchant, setCostingSourcingMerchant] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [showFactSelector, setShowFactSelector] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermMerchant, setSearchTermMerchant] = useState("");
  const [isFilterToggle, setIsFilterToggle] = useState(false);
  const [filters, setFilters] = useState([]);
  let [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const PER_PAGE = 10;
  const _DATA = usePagination(filteredData, PER_PAGE);

  const handleSearchData = (text) => {
    // alert(text);
    if (text) {
      const filterData1 = myDesigns.filter(
        (item, index) =>
          item.productCode.toLowerCase().includes(text.toLowerCase()) ||
          item.category.toLowerCase().includes(text.toLowerCase()) ||
          item.fabric.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredData(filterData1);
      setCount(Math.ceil(filterData1.length / PER_PAGE));
      setPage(1);
      _DATA.jump(1);
    } else {
      getDesignData();
    }
  };

  const fetchData = () => {
    let newData = [],
      categoryItem = [],
      fabricItem = [];
    filters.map((item, index) => {
      item.filterValue.map((item2, index2) => {
        if (item2.checked) {
          if (item2.pFilter === "CATEGORY") {
            categoryItem.push(item2);
          } else if (item2.pFilter === "FABRIC") {
            fabricItem.push(item2);
          }
        }
      });
    });

    if (categoryItem.length > 0 && fabricItem.length > 0) {
      categoryItem.map((item1, index1) => {
        fabricItem.map((item2, index2) => {
          const filterData = myDesigns.filter(
            (item3, index3) =>
              item3.category == item1.value && item3.fabric == item2.value
          );
          newData.push(filterData);
        });
      });
    } else if (categoryItem.length > 0 && fabricItem.length === 0) {
      categoryItem.map((item1, index1) => {
        const filterData = myDesigns.filter(
          (item3, index3) => item3.category == item1.value
        );
        newData.push(filterData);
      });
    } else if (categoryItem.length === 0 && fabricItem.length > 0) {
      fabricItem.map((item2, index2) => {
        const filterData = myDesigns.filter(
          (item3, index3) => item3.fabric == item2.value
        );
        newData.push(filterData);
      });
    }

    setFilteredData(newData.flat());
    setCount(Math.ceil(newData.flat().length / PER_PAGE));
    setPage(1);
    _DATA.jump(1);
    setIsFilterToggle(false);
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const notify = (type, message) => {
    if (type === "submit") {
      toast("Design has been Sent for Review");
    } else if (type == "error") {
      toast.error(message);
    } else if (type == "success") {
      toast.success(message, {
        iconTheme: {
          primary: "#0047AB",
          secondary: "#FFFAEE",
        },
      });
    } else if (type == "custom") {
      toast((t) => (
        <span>
          <b>Thanks Shilpa Polavarapu for submitting your awesome designs.</b>
          <br />
          <br />
          Our team is on the job of reviewing your designs and will share
          feedback soon. Check back here for updates. You will also receive a
          notification once the review is completed.
        </span>
      ));
    }
  };

  const getDesignData = () => {
    fetch(
      "https://studioeapi.bluekaktus.com/api/design/getDesignCostingDetails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userID: userID,
          designID: 0,
        }),
      }
    )
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        let categorys = [];
        let categorysx = [];
        let categorysxx = [];
        let fabrics = [];
        let fabricsx = [];
        let fabricsxx = [];

        result.designDetails.forEach((item) => {
          if (categorysx.indexOf(item.category) == -1) {
            categorys.push({
              filterID: item.categoryID,
              value: item.category,
              checked: false,
              pFilter: "CATEGORY",
            });
            categorysx.push(item.category);
          }

          if (fabricsx.indexOf(item.fabric) == -1) {
            fabrics.push({
              filterID: item.fabricID,
              value: item.fabric,
              checked: false,
              pFilter: "FABRIC",
            });
            fabricsx.push(item.fabric);
          }
        });

        categorysxx.push({
          filterName: "CATEGORY",
          expand: false,
          filterValue: categorys,
        });

        fabricsxx.push({
          filterName: "FABRIC",
          expand: false,
          filterValue: fabrics,
        });

        const array = [categorysxx, fabricsxx];

        setFilters(array.flat());
        setMyDesigns(result.designDetails);
        setFilteredData(result.designDetails);
        setCount(Math.ceil(result.designDetails.length / PER_PAGE));
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const getCostingHistory = (designID, factoryID) => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getCostingHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: designID,
        factoryID: parseInt(factoryID),
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        setCostingHistory(result.result);
        setShowHistory(true);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const sendForCosting = () => {
    if(costingSourcingMerchant == null){
      const filterData = allCostingSourcingMerchantList.filter(
        (item, index) => item.userEmail == loginID
      );
      if (filterData.length === 0) {
        notify("error", "Please select sourcing merchant");
        return;
      }
    }
   
    var status = 0;
    fetch("https://studioeapi.bluekaktus.com/api/design/sendCostingBK2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: selectedDesign,
        factoryID: selectedFacory.VENDOR_ID,
        factoryName: selectedFacory.VENDOR_NAME,
        sourcingMerchant:
          costingSourcingMerchant != null
            ? costingSourcingMerchant.userEmail
            : loginID,
      }),
    })
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((result) => {
        if (status == 200) {
          notify("success", `Costing Sent to ${selectedFacory.VENDOR_NAME}`);
          setShowFactSelector(false);
          setSelectedDesign(null);
          setSearchTerm("");
          setSearchTermMerchant("");
          setSelectedFactory(null);
          setCostingSourcingMerchant(null);
          var temp = JSON.parse(JSON.stringify(factories));
          temp.forEach((item) => {
            item.selected = false;
          });

          var temp1 = JSON.parse(
            JSON.stringify(allCostingSourcingMerchantList)
          );
          temp1.forEach((item) => {
            item.selected = false;
          });
          setFactories(temp);
          setFiltFactories(temp);
          setCostingSourcingMerchantList(temp1);
          setAllCostingSourcingMerchantList(temp1);
        } else {
          notify("error", `An error Occured`);
          notify("error", result.Message);
        }
        getDesignData();
      });
  };

  const getFactories = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getCostingVendor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setFactories(result.BK2.result.Table);
        setFiltFactories(result.BK2.result.Table);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const getCostingSourcingMerchant = () => {
    fetch(
      "https://studioeapi.bluekaktus.com/api/design/getCostingSourcingMerchant",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userID: userID,
        }),
      }
    )
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setAllCostingSourcingMerchantList(result.result);
        setCostingSourcingMerchantList(result.result);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const approveCosting = (designID, costingID) => {
    fetch("https://studioeapi.bluekaktus.com/api/design/approveCosting", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        designID: designID,
        costingID: costingID,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        notify("success", "Costing Approved");
        getDesignData();
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const filterDataList = (filter) => {
    filter = filter.toLowerCase();

    if (!filter || filter == "") {
      setFiltFactories(factories);
      setSearchTerm("");
    } else {
      const newData = factories.filter((item) => {
        let itemString = item["VENDOR_NAME"];
        return itemString.toLowerCase().includes(filter);
      });
      setFiltFactories(newData);
    }
  };

  const filterDataListMerchant = (filter) => {
    filter = filter.toLowerCase();

    if (!filter || filter == "") {
      setCostingSourcingMerchantList(allCostingSourcingMerchantList);
      setSearchTermMerchant("");
    } else {
      const newData = allCostingSourcingMerchantList.filter((item) => {
        let itemString = item["userName"];
        return itemString.toLowerCase().includes(filter);
      });
      setCostingSourcingMerchantList(newData);
    }
  };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    if (loginStatus == 1) {
      getDesignData();
      getFactories();
      getCostingSourcingMerchant();
    }
  }, []);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="bodyNT">
        <Toaster
          position="bottom-center"
          toastOptions={{
            // Define default options
            className: "",
            duration: 4000,
            style: {
              color: " #FFFFFF",
              background: "#75B551DD",
            },
          }}
        />
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          style={{ position: "absolute" }}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div className={classes.mainContainer}>
          <motion.div
            variants={container}
            initial={{ x: "-100vw" }}
            animate={
              isFilterToggle
                ? { x: 0, opacity: 1 }
                : { x: "-100vw", opacity: 0 }
            }
            transition={{ duration: 0.3, origin: 0 }}
            className={classes.filterss}
          >
            <div style={{ marginBottom: 0 }}>
              <span
                className={classes.secondaryFiltersWrapperReset}
                onClick={() => {
                  getDesignData();
                  setIsFilterToggle(false);
                }}
              >
                Reset
              </span>

              <span
                className={classes.secondaryFiltersWrapperApply}
                style={{ marginRight: 0 }}
                onClick={() => {
                  //setRepsFilter([]);
                  fetchData();
                }}
              >
                Apply
              </span>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  padding: 5,
                  zIndex: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsFilterToggle(!isFilterToggle);
                }}
              >
                <CloseIcon style={{ color: "#3e7cc2" }} />
              </div>
            </div>
            {filters.map((item, index) => (
              <div className={classes.filterItemWrapper}>
                <div
                  className={classes.filterItem}
                  onClick={() => {
                    let temp = filters;
                    temp[index].expand = !temp[index].expand;
                    // console.log("temp", temp);
                    setFilters(JSON.parse(JSON.stringify(temp)));
                  }}
                >
                  <div
                    style={{
                      textAlign: "right",
                      marginRight: "1rem",
                      marginTop: ".5rem",
                      color: "#75B551",
                      fontSize: "1.1rem",
                    }}
                  >
                    {item.filterName}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      marginTop: ".5rem",
                      color: "#75B551",
                      fontSize: "1.1rem",
                      float: "right",
                    }}
                  >
                    {item.expand ? "-" : "+"}
                  </div>
                </div>
                {item.expand && (
                  <div style={{ maxHeight: "20vh", overflowY: "auto" }}>
                    {item.filterValue.map((item, index2) => (
                      <div
                        className={classes.filterCheckrow}
                        onClick={() => {
                          var tempx = JSON.parse(JSON.stringify(filters));
                          // console.log(tempx, filters, "check");
                          tempx[index].filterValue[index2].checked =
                            !tempx[index].filterValue[index2].checked;
                          // console.log(tempx, "check2");
                          setFilters(JSON.parse(JSON.stringify(tempx)));
                          // updateData(JSON.parse(JSON.stringify(tempx)));
                        }}
                      >
                        <RedCheckbox checked={item.checked} />
                        <div
                          style={{
                            marginLeft: 10,
                            marginTop: 5,
                            color: "#75B551",
                          }}
                        >
                          {item.value}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </motion.div>
        </div>
        <motion.div
          variants={container}
          initial={{ x: "25vw", y: "-100vh" }}
          animate={
            showFactSelector
              ? { x: "25vw", y: "14vh", opacity: 1 }
              : { x: "25vw", y: "-100vh", opacity: 0 }
          }
          transition={{ duration: 0.3, origin: 0 }}
          className={classes.filter}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <div style={{ fontSize: "1.4rem", color: "#75B551" }}>
                Select Factory and Costing Sourcing Merchant
              </div>
              <div
                style={{ fontSize: "1.4rem", color: "#75B551" }}
                onClick={() => {
                  var temp = JSON.parse(JSON.stringify(factories));
                  var temp1 = JSON.parse(
                    JSON.stringify(allCostingSourcingMerchantList)
                  );
                  temp.forEach((item) => {
                    item.selected = false;
                  });
                  temp1.forEach((item) => {
                    item.selected = false;
                  });
                  setFactories(temp);
                  setFiltFactories(temp);
                  setCostingSourcingMerchantList(temp1);
                  setAllCostingSourcingMerchantList(temp1);
                  setSearchTerm("");
                  setSearchTermMerchant("");
                  setSelectedFactory(null);
                  setCostingSourcingMerchant(null);
                  setShowFactSelector(false);
                }}
              >
                x
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "95%" }}>
                  <CustomTextFieldL
                    style={{ flex: 1 }}
                    fullWidth
                    size="small"
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => {
                      filterDataList(e.target.value);
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
                <div className={classes.factoryList}>
                  {searchTerm != "" && filtFactories.length == 0 ? (
                    <div style={{ textAlign: "center", marginTop: 10 }}>
                      {" "}
                      No Factories Found
                    </div>
                  ) : (
                    <>
                      {filtFactories.map((item, index) => {
                        return (
                          <div
                            id={item.VENDOR_ID}
                            className={
                              item.selected
                                ? classes.factoryCellS
                                : classes.factoryCellU
                            }
                            onClick={() => {
                              var temp = JSON.parse(
                                JSON.stringify(filtFactories)
                              );
                              var temp2 = JSON.parse(JSON.stringify(factories));
                              temp.forEach((item1) => {
                                if (item1.VENDOR_ID == item.VENDOR_ID) {
                                  if (item1.selected) {
                                    item1.selected = false;
                                    setSelectedFactory(null);
                                  } else {
                                    item1.selected = true;
                                    setSelectedFactory(item);
                                  }
                                } else {
                                  item1.selected = false;
                                }
                              });

                              temp2.forEach((itemx) => {
                                if (itemx.VENDOR_ID == item.VENDOR_ID) {
                                  if (itemx.selected) {
                                    itemx.selected = false;
                                  } else {
                                    itemx.selected = true;
                                  }
                                } else {
                                  itemx.selected = false;
                                }
                              });
                              setFiltFactories(temp);
                              setFactories(temp2);
                            }}
                          >
                            {item.VENDOR_NAME}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "95%" }}>
                  <CustomTextFieldL
                    style={{ flex: 1 }}
                    fullWidth
                    size="small"
                    label="Search"
                    variant="outlined"
                    value={searchTermMerchant}
                    onChange={(e) => {
                      filterDataListMerchant(e.target.value);
                      setSearchTermMerchant(e.target.value);
                    }}
                  />
                </div>
                <div className={classes.factoryList}>
                  {searchTerm != "" &&
                  costingSourcingMerchantList.length == 0 ? (
                    <div style={{ textAlign: "center", marginTop: 10 }}>
                      {" "}
                      No Merchants Found
                    </div>
                  ) : (
                    <>
                      {costingSourcingMerchantList.map((item, index) => {
                        return (
                          <div
                            id={item.bk2UserID}
                            className={
                              item.selected
                                ? classes.factoryCellS
                                : classes.factoryCellU
                            }
                            onClick={() => {
                              var temp = JSON.parse(
                                JSON.stringify(costingSourcingMerchantList)
                              );
                              temp.forEach((item1) => {
                                if (item1.bk2UserID == item.bk2UserID) {
                                  if (item1.selected) {
                                    item1.selected = false;
                                    setCostingSourcingMerchant(null);
                                  } else {
                                    item1.selected = true;
                                    setCostingSourcingMerchant(item);
                                  }
                                } else {
                                  item1.selected = false;
                                }
                              });

                              var temp2 = JSON.parse(
                                JSON.stringify(allCostingSourcingMerchantList)
                              );
                              temp2.forEach((itemx) => {
                                if (itemx.bk2UserID == item.bk2UserID) {
                                  if (itemx.selected) {
                                    itemx.selected = false;
                                  } else {
                                    itemx.selected = true;
                                  }
                                } else {
                                  itemx.selected = false;
                                }
                              });
                              setCostingSourcingMerchantList(temp);
                              setAllCostingSourcingMerchantList(temp2);
                            }}
                          >
                            {item.userName}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {selectedFacory != null ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <span
                className={classes.submit}
                onClick={() => {
                  sendForCosting();
                }}
                style={{
                  marginRight: 15,
                  marginTop: 10,
                }}
              >
                Send For Costing
              </span>
            </div>
          ) : null}
        </motion.div>
        <motion.div
          variants={container}
          initial={{ x: "16vw", y: "-100vh" }}
          animate={
            showHistory
              ? { x: "16vw", y: "20vh", opacity: 1 }
              : { x: "16vw", y: "-100vh", opacity: 0 }
          }
          transition={{ duration: 0.3, origin: 0 }}
          className={classes.historyWrap}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <div style={{ fontSize: "1.4rem", color: "#75B551" }}>
                Costing History
              </div>
              <div
                style={{ fontSize: "1.4rem", color: "#75B551" }}
                onClick={() => {
                  setCostingHistory([]);
                  setShowHistory(false);
                }}
              >
                x
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontWeight: "bold",
                color: "#75B551",
              }}
            >
              <div style={{ width: "25%" }}>Date</div>
              <div style={{ width: "25%", textAlign: "center" }}>Cost</div>
              <div style={{ width: "25%", textAlign: "center" }}>MOQ</div>
              <div style={{ width: "25%", textAlign: "center" }}>Lead Time</div>
            </div>
            <div className={classes.factoryList}>
              {costingHistory.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: "#75B551",
                    }}
                  >
                    <div style={{ width: "25%" }}>{item.addedOn}</div>
                    <div style={{ width: "25%", textAlign: "center" }}>
                      {item.cost}
                    </div>
                    <div style={{ width: "25%", textAlign: "center" }}>
                      {item.moq}
                    </div>
                    <div style={{ width: "25%", textAlign: "center" }}>
                      {item.leadTime}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </motion.div>
        <div className={classes.wrapper} style={{ zIndex: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "2px 0px",
            }}
          >
            <div style={{ marginRight: 0 }}>
              {theme == "light" ? (
                <CustomTextFieldL
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              ) : (
                <CustomTextFieldD
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              )}
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsFilterToggle(!isFilterToggle);
              }}
            >
              <FilterAltIcon sx={{ fontSize: "2.5rem", color: "#75B551" }} />
            </div>
          </div>
          <div className={classes.tableI}>
            <div className={classes.headerRow}>
              <div
                className={classes.headerRowItem}
                style={{ width: "5%" }}
              ></div>
              <div
                className={classes.headerRowItem}
                style={{ width: "10%", marginLeft: 15 }}
              >
                Product Code
              </div>
              <div className={classes.headerRowItem} style={{ width: "10%" }}>
                Category
              </div>
              <div className={classes.headerRowItem} style={{ width: "7%" }}>
                Fabric
              </div>
              <div
                className={classes.headerRowItem}
                style={{
                  width: "22%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderLeft: "1px solid #75B551AA",
                }}
              >
                <div>Factory 1</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "auto",
                    fontSize: "1rem",
                    fontWeight: "normal",
                  }}
                >
                  <div style={{ width: "33%" }}>Cost</div>
                  <div style={{ width: "33%" }}>MOQ</div>
                  <div style={{ width: "33%" }}>Lead T.</div>
                </div>
              </div>
              <div
                className={classes.headerRowItem}
                style={{
                  width: "22%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderLeft: "1px solid #75B551AA",
                }}
              >
                <div>Factory 2</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "auto",
                    fontSize: "1rem",
                    fontWeight: "normal",
                  }}
                >
                  <div style={{ width: "33%" }}>Cost</div>
                  <div style={{ width: "33%" }}>MOQ</div>
                  <div style={{ width: "33%" }}>Lead T.</div>
                </div>
              </div>
              <div
                className={classes.headerRowItem}
                style={{
                  width: "22%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderLeft: "1px solid #75B551AA",
                }}
              >
                <div>Factory 3</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "auto",
                    fontSize: "1rem",
                    fontWeight: "normal",
                  }}
                >
                  <div style={{ width: "33%" }}>Cost</div>
                  <div style={{ width: "33%" }}>MOQ</div>
                  <div style={{ width: "33%" }}>Lead T.</div>
                </div>
              </div>
              {/* <div className={classes.headerRowItem} style={{width:"8%",textAlign:"center"}}>
                    Actions
                </div> */}
            </div>
            {_DATA.currentData().map((item, index) => {
              return (
                <div
                  className={
                    index % 2 == 0 ? classes.tableRowEven : classes.tableRowOdd
                  }
                  onClick={() => {}}
                >
                  <div
                    style={{ width: "5%", maxHeight: "15vh", marginRight: 15 }}
                    onClick={() => {
                      console.log(item.techpack);
                      window.open(item.techpack);
                    }}
                  >
                    <img
                      src={item.designImagePath}
                      style={{
                        height: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div
                    className={classes.tableCell}
                    style={{ width: "10%", marginLeft: 15 }}
                  >
                    {item.productCode}
                  </div>
                  <div className={classes.tableCell} style={{ width: "10%" }}>
                    {item.category}
                  </div>
                  <div className={classes.tableCell} style={{ width: "7%" }}>
                    {item.fabric}
                  </div>
                  {item.costDetails[0] == undefined ? (
                    item.costingRequests[0] == undefined ? (
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "22%",
                          display: "flex",
                          flexDirection: "column",
                          borderLeft: "1px solid #75B551AA",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={classes.submit}
                          onClick={() => {
                            setSelectedDesign(item.designID);
                            setShowFactSelector(!showFactSelector);
                          }}
                        >
                          Send For Costing
                        </div>
                      </div>
                    ) : (
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "22%",
                          display: "flex",
                          flexDirection: "column",
                          borderLeft: "1px solid #75B551AA",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={classes.submit}
                          style={{ backgroundColor: "#fd842f" }}
                          onClick={() => {}}
                        >
                          Costing Pending...
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      className={classes.tableCell}
                      style={{
                        width: "22%",
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "1px solid #75B551AA",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                        {item.costDetails[0].factoryName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "33%", textAlign: "center" }}>
                          {item.costDetails[0].cost}
                        </div>
                        <div style={{ width: "33%", textAlign: "center" }}>
                          {item.costDetails[0].moq}
                        </div>
                        <div style={{ width: "33%", textAlign: "center" }}>
                          {item.costDetails[0].leadTime + " days"}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-around",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        <div
                          onClick={() => {
                            getCostingHistory(
                              item.designID,
                              item.costDetails[0].factoryID
                            );
                          }}
                        >
                          <InfoIcon />
                        </div>
                        <div className={classes.submitx}>{"Messages"}</div>
                        {item.costDetails[0].isApproved ? (
                          <TaskAltIcon style={{ color: "#75B551" }} />
                        ) : (
                          <TaskAltIcon
                            style={{ color: "#AAAAAA" }}
                            onClick={() => {
                              approveCosting(
                                item.designID,
                                item.costDetails[0].costingID
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {item.costDetails[1] == undefined ? (
                    item.costingRequests[1] == undefined ? (
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "22%",
                          display: "flex",
                          flexDirection: "column",
                          borderLeft: "1px solid #75B551AA",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={classes.submit}
                          onClick={() => {
                            setSelectedDesign(item.designID);
                            setShowFactSelector(!showFactSelector);
                          }}
                        >
                          Send For Costing
                        </div>
                      </div>
                    ) : (
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "22%",
                          display: "flex",
                          flexDirection: "column",
                          borderLeft: "1px solid #75B551AA",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={classes.submit}
                          style={{ backgroundColor: "#fd842f" }}
                          onClick={() => {}}
                        >
                          Costing Pending...
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      className={classes.tableCell}
                      style={{
                        width: "22%",
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "1px solid #75B551AA",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                        {item.costDetails[1].factoryName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "33%", textAlign: "center" }}>
                          {item.costDetails[1].cost}
                        </div>
                        <div style={{ width: "33%", textAlign: "center" }}>
                          {item.costDetails[1].moq}
                        </div>
                        <div style={{ width: "33%", textAlign: "center" }}>
                          {item.costDetails[1].leadTime + " days"}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-around",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        <div
                          onClick={() => {
                            getCostingHistory(
                              item.designID,
                              item.costDetails[1].factoryID
                            );
                          }}
                        >
                          <InfoIcon />
                        </div>
                        <div className={classes.submitx}>{"Messages"}</div>
                        {item.costDetails[1].isApproved ? (
                          <TaskAltIcon style={{ color: "#75B551" }} />
                        ) : (
                          <TaskAltIcon
                            style={{ color: "#AAAAAA" }}
                            onClick={() => {
                              approveCosting(
                                item.designID,
                                item.costDetails[1].costingID
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {item.costDetails[2] == undefined ? (
                    item.costingRequests[2] == undefined ? (
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "22%",
                          display: "flex",
                          flexDirection: "column",
                          borderLeft: "1px solid #75B551AA",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={classes.submit}
                          onClick={() => {
                            setSelectedDesign(item.designID);
                            setShowFactSelector(!showFactSelector);
                          }}
                        >
                          Send For Costing
                        </div>
                      </div>
                    ) : (
                      <div
                        className={classes.tableCell}
                        style={{
                          width: "22%",
                          display: "flex",
                          flexDirection: "column",
                          borderLeft: "1px solid #75B551AA",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={classes.submit}
                          style={{ backgroundColor: "#fd842f" }}
                          onClick={() => {}}
                        >
                          Costing Pending...
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      className={classes.tableCell}
                      style={{
                        width: "22%",
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "1px solid #75B551AA",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                        {item.costDetails[2].factoryName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "33%", textAlign: "center" }}>
                          {item.costDetails[2].cost}
                        </div>
                        <div style={{ width: "33%", textAlign: "center" }}>
                          {item.costDetails[2].moq}
                        </div>
                        <div style={{ width: "33%", textAlign: "center" }}>
                          {item.costDetails[2].leadTime + " days"}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-around",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        <div
                          onClick={() => {
                            getCostingHistory(
                              item.designID,
                              item.costDetails[2].factoryID
                            );
                          }}
                        >
                          <InfoIcon />
                        </div>
                        <div className={classes.submitx}>{"Messages"}</div>
                        {item.costDetails[2].isApproved ? (
                          <TaskAltIcon style={{ color: "#75B551" }} />
                        ) : (
                          <TaskAltIcon
                            style={{ color: "#AAAAAA" }}
                            onClick={() => {
                              approveCosting(
                                item.designID,
                                item.costDetails[2].costingID
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {/* { item.costDetails[0]==undefined? 
                          <div className={classes.tableCell} style={{width:"8%"}}>
                              <div className={classes.submitdisabled} style={{fontSize:".9rem",padding:"5px 5px",}}>
                                Send for 3D
                              </div>
                          </div>:
                           <div className={classes.tableCell} style={{width:"8%"}}>
                              <div className={classes.submit} style={{fontSize:".9rem",padding:"5px 5px",}}>
                              Send for 3D
                            </div>
                           </div>
                          } */}
                </div>
              );
            })}
          </div>
          <div className={classes.tableWrapperRow}>
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
