import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Header from "../components/header";
import { Pagination } from "@material-ui/lab";
import usePagination from "../hooks/Pagination";
import Notification from "../components/Notification";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import toast, { Toaster } from "react-hot-toast";

var w = window.innerWidth;
var h = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  headerItem: {
    color: "#000000AA",
    borderRadius: 8,
    fontSize: "2rem",
    padding: "10px 15px",
    margin: "5px 20px",
    // fontFamily:"Poppins",
    letterSpacing: "-1px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#FFFFFF",
      backgroundColor: "#75B551",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 50px",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF88",
    padding: 8,
    borderRadius: 8,
  },
  tableI: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    border: "1.5px solid #00000033",
    maxHeight: "72vh",
    marginTop: 10,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    padding: 10,
    borderBottom: "1.5px solid #00000055",
    position: "sticky",
    top: 0,
  },
  headerRowItem: {
    color: "#75B551",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginRight: 15,
  },
  submit: {
    padding: "10px 50px",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: "1.7rem",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  tableRowEven: {
    display: "flex",
    flexDirection: "row",
    padding: 8,
    backgroundColor: "#BBBBBB66",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  tableRowOdd: {
    display: "flex",
    flexDirection: "row",
    padding: 8,
    backgroundColor: "#EEEEEEAA",
    borderBottom: "1.5px solid #75B55166",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#FFFFFF88",
    },
  },
  tableCell: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.8vmin",
    color: "#000000AA",
    marginRight: 15,
  },
  approveBtn: {
    padding: "6px 30px",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: "1rem",
    marginLeft: 10,
    background: "linear-gradient(-45deg,#75B551,#86c662,#96d374,#a2d982)",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transform: "scale(1.03)",
    },
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    maxHeight: "80vh",
    flexWrap: "wrap",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "11px",
      height: "11px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(45deg, #75B551AA, #00853eAA)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "40vh",
    width: "25%",
    backgroundColor: "#FFFFFF",
    margin: 8,
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "3px solid #75B551AA",
    transition: "all .3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#75B551AA",
    },
  },
  tableWrapperRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "2.5px 5px",
    width: "100%",
    justifyContent: "center",
    marginTop: 10,
  },
}));

const OutlinedInputL = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#00000088 !important",
  },
}))(MuiOutlinedInput);
const OutlinedInputD = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#49b675AA !important",
  },
}))(MuiOutlinedInput);

const CustomTextFieldL = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#00000088",
    },
    "& label": {
      color: "#00000088",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000088",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000088",
      },
      "&:hover fieldset": {
        borderColor: "#00000088",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000088",
      },
    },
  },
})(TextField);

const CustomTextFieldD = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#49b675AA",
    },
    "& label": {
      color: "#49b675AA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#49b675AA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#49b675AA",
      },
      "&:hover fieldset": {
        borderColor: "#49b675AA",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#49b675AA",
      },
    },
  },
})(TextField);

export default function AddColor(props) {
  const {
    setSelectedDesign,
    loginStatus,
    setLoginStatus,
    userID,
    headerData,
    isHeader,
    theme,
    setTheme,
    cookies,
    removeCookie,
    setCartItems,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [colorGroup, setColorGroup] = useState("");
  const [colorID, setColorID] = useState(0);
  const [allColorGroup, setAllColorGroup] = useState([]);
  const [allColors, setAllColors] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [color, setColor] = useState("");
  const [searchText, setSearchText] = useState("");
  let [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const PER_PAGE = 10;
  const _DATA = usePagination(allColors, PER_PAGE);

  const handleSearchData = (text) => {
    // alert(text);
    if (text) {
      const filterData1 = allColors.filter(
        (item, index) =>
          item.colorName.toLowerCase().includes(text.toLowerCase()) ||
          item.colorGroup.toLowerCase().includes(text.toLowerCase())
      );
      setAllColors(filterData1);
      setCount(Math.ceil(filterData1.length / PER_PAGE));
    } else {
      setIsEdit(false);
      setColor("");
      setColorGroup("");
      setColorID(0);
      getAllColorGroup("VIEW", 0);
    }
  };

  const getColorGroup = () => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getColorMaster", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        action: "COLOR GROUP",
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        setAllColorGroup(result.result);
      }).catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const getAllColorGroup = (action, colorID) => {
    fetch("https://studioeapi.bluekaktus.com/api/design/getColorMaster", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        action: action,
        colorID: colorID,
        color: color,
        colorGroup: colorGroup,
        isActive: 1,
      }),
    })
      .then((response) => {
        if (response.status === 500) {
          throw new Error(
            "Oops, something went wrong on our end. Please try again later"
          );
        }
        if (response.status === 400) {
          throw new Error("Invalid request");
        }
        if (!response.ok) {
          throw new Error(
            "Oops, something went wrong. Please try again later."
          );
        }
        return response.json();
      })
      .then((result) => {
        if (action == "ADD") {
          if (result.Message == "Inserted") {
            toast.success("Added Successfully");
            setIsEdit(false);
            setColor("");
            setColorGroup("");
            setColorID(0);
            getAllColorGroup("VIEW", 0);
          } else {
            toast.error(result.Message);
          }
        } else if (action == "VIEW") {
          setAllColors(result.result);
          setCount(Math.ceil(result.result.length / PER_PAGE));
        } else if (action == "EDIT") {
          if (result.Message == "Data Updated") {
            toast.success("Data Updated Successfully");
            setIsEdit(false);
            setColor("");
            setColorGroup("");
            setColorID(0);
            getAllColorGroup("VIEW", 0);
          } else {
            toast.error(result.Message);
          }
        } else if (action == "DELETE") {
          if (result.Message == "Color deleted") {
            toast.success("Color Deleted");
            setIsEdit(false);
            setColor("");
            setColorGroup("");
            setColorID(0);
            getAllColorGroup("VIEW", 0);
          } else {
            toast.error(result.Message);
          }
        }
      })
      .catch((err) => {
        console.log(err, "errerr");
      });
  };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    if (loginStatus == 1) {
      getColorGroup();
      getAllColorGroup("VIEW", 0);
      setCartItems([]);
    }
  }, []);

  useEffect(() => {
    setPage(1);
    _DATA.jump(1);
  }, [count]);

  if (loginStatus != 1) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="bodyNT">
        <Toaster
          position="bottom-center"
          toastOptions={{
            // Define default options
            className: "",
            duration: 4000,
            style: {
              color: " #FFFFFF",
              background: "#75B551DD",
            },
          }}
        />
        <Notification
          theme={theme}
          setLoginStatus={setLoginStatus}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          style={{ position: "absolute" }}
        />
        {isHeader && (
          <Header headerData={headerData} theme={theme} setTheme={setTheme} />
        )}
        <div className={classes.wrapper} style={{ zIndex: 10 }}>
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "2px 0px",
            }}
          >
            <div style={{ marginRight: 0 }}>
              {theme == "light" ? (
                <CustomTextFieldL
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              ) : (
                <CustomTextFieldD
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchData(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <div
            className={classes.inputWrapper}
            style={{
              boxShadow:
                theme == "light"
                  ? "#75B551 0px 1px 4px"
                  : "#FFFFFFAA 0px 1px 4px",
              zIndex: 10,
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flex: 1,
                marginRight: 10,
                marginLeft: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "88%",
                }}
              >
                {theme == "light" ? (
                  <CustomTextFieldL
                    style={{ flex: 1, width: "50%", marginRight: "10px" }}
                    label="Enter Color"
                    variant="outlined"
                    value={color}
                    onChange={(e) => {
                      setColor(e.target.value);
                    }}
                  />
                ) : (
                  <CustomTextFieldD
                    style={{ flex: 1, width: "50%", marginRight: "10px" }}
                    label="Enter Color"
                    variant="outlined"
                    value={color}
                    onChange={(e) => {
                      setColor(e.target.value);
                    }}
                  />
                )}
                <FormControl variant="outlined" style={{ width: "50%" }}>
                  <InputLabel
                    id="label"
                    style={{
                      color: theme == "light" ? "#00000088" : "#49b675AA",
                    }}
                  >
                    Color Group
                  </InputLabel>
                  <Select
                    classes={{
                      select: classes.select,
                      icon: classes.icon,
                    }}
                    label="Color Group"
                    value={colorGroup}
                    input={
                      theme == "light" ? (
                        <OutlinedInputL label="Color Group" />
                      ) : (
                        <OutlinedInputD label="Color Group" />
                      )
                    }
                    onChange={(e) => {
                      setColorGroup(e.target.value);
                    }}
                  >
                    {allColorGroup.length > 0 ? (
                      allColorGroup.map((item, index) => {
                        return (
                          <MenuItem value={item.colorGroup}>
                            {item.colorGroup}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem disabled="true">No Color Group</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "12%",
                }}
              >
                {isEdit ? (
                  <div
                    className={classes.submit}
                    style={{
                      backgroundColor:
                        theme == "light" ? "#75B551AA" : "#49b675DD",
                    }}
                    onClick={() => getAllColorGroup("EDIT", colorID)}
                  >
                    Update
                  </div>
                ) : (
                  <div
                    className={classes.submit}
                    style={{
                      backgroundColor:
                        theme == "light" ? "#75B551AA" : "#49b675DD",
                    }}
                    onClick={() => getAllColorGroup("ADD", 0)}
                  >
                    Add
                  </div>
                )}
              </div>
            </div>
          </div>

       

          <div className={classes.tableI}>
            <div className={classes.headerRow}>
              <div
                className={classes.headerRowItem}
                style={{ width: "42.5%", marginLeft: 15 }}
              >
                Color
              </div>
              <div
                className={classes.headerRowItem}
                style={{ width: "42.5%", marginLeft: 15 }}
              >
                Color Group
              </div>
              <div
                className={classes.headerRowItem}
                style={{
                  width: "15%",
                  marginLeft: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Action
              </div>
            </div>
            {allColors.length > 0 ? (
              _DATA.currentData().map((item, index) => {
                return (
                  <div
                    className={
                      index % 2 == 0
                        ? classes.tableRowEven
                        : classes.tableRowOdd
                    }
                  >
                    <div
                      className={classes.tableCell}
                      style={{ width: "42.5%", marginLeft: 15 }}
                    >
                      {item.colorName}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{ width: "42.5%", marginLeft: 15 }}
                    >
                      {item.colorGroup}
                    </div>
                    <div
                      className={classes.tableCell}
                      style={{
                        width: "15%",
                        justifyContent: "center",
                        textAlign: "right",
                        paddingRight: 20,
                      }}
                    >
                      <div
                        className={classes.approveBtn}
                        style={{
                          background: "linear-gradient(-45deg,#2193b0,#6dd5ed)",
                        }}
                        onClick={() => {
                          setIsEdit(true);
                          setColor(item.colorName);
                          setColorGroup(item.colorGroup);
                          setColorID(item.colorID);
                        }}
                      >
                        <EditIcon />
                      </div>
                      <div
                        className={classes.approveBtn}
                        style={{
                          background: "linear-gradient(-45deg,#ED213A,#d9002d)",
                        }}
                        onClick={() => {
                          let text = `Are you sure to delete this color ${item.colorName}?`;
                          if (window.confirm(text) == true) {
                            getAllColorGroup("DELETE", item.colorID);
                          } else {
                          }
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className={
                  0 % 2 == 0 ? classes.tableRowEven : classes.tableRowOdd
                }
              >
                <div
                  className={classes.tableCell}
                  style={{ width: "100%", marginLeft: 15,justifyContent:"center" }}
                >
                  No Color Found
                </div>
              </div>
            )}
          </div>
          <div className={classes.tableWrapperRow}>
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
