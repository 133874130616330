import colors from "./color";
export const light = {
  textColor: colors.primarytextL,
  headerColor: colors.headerL,
  primaryColor: colors.primaryL,
  secondaryColor: colors.secondaryL,
  background: colors.backgroundl,
  highliter: colors.highliterL,
  headerBackground: colors.headerbackgroundl,
};
export const dark = {
  textColor: colors.primarytextD,
  headerColor: colors.headerD,
  background: colors.backgroundD,
  secondaryColor: colors.secondaryD,
  primaryColor: colors.primaryD,
  highliter: colors.highliterD,
  headerBackground: colors.headerbackgroundD,
};