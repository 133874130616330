import * as React from "react";
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
import { makeStyles } from "@material-ui/core";
import { light, dark } from "../ui/Toggletheme";
const useStyles = makeStyles((theme) => ({
  button : {
    outline: "none",
    border: "none",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    webkitUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    cursor: "pointer",
    position: "absolute",
    top: "30px",
    right:"25px",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    background: "transparent",
    zIndex:20
    
  }
}));
const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    // strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }) => {
    const classes = useStyles();
  return(
  <div className={classes.button} onClick={toggle} >
    <svg width="23" height="30" >
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </svg>
  </div>)
  
};